/*인사말*/
.greetings {background:url(../../resources/images/cont/greetings-img1.jpg) left top no-repeat; padding-top:30px;
    img {padding-bottom:25px; padding-left:28%; }
    p {padding-left:338px; padding-bottom:30px; font-size:16px;}
    @media screen and (max-width: 600px) and (min-width: 1px) {
        text-align: center; background:none; padding-top:0; background-size:200px;
        img {padding-left:0;}
        p {padding-left:0;text-align:left;}

    }
}

/*역대교육장*/
.history-people {background:url(../../resources/images/cont/history-people-bg.png) left top no-repeat; display:inline-block;padding-left:20px;
    li {float:left; width:33.33%; padding-bottom:70px;
        dl {background-color:#fff; border:1px solid #d6d6d6; max-width:214px; width:100%; text-align: center; box-sizing: border-box;
            dd {color:#333333;font-size:14px; background-color:#f5f5f5; padding:8px 0;
                p {font-weight:600; font-size:17px; }
            }
        }
        img { height:128px; padding:20px 0; }
    }
    @media screen and (max-width: 730px) and (min-width: 1px) {
        padding-left:0px;
        li {width:50%;  padding-bottom:10px;
            dl {margin:0 auto; max-width:95%;}
        }
    }
    @media screen and (max-width: 390px) and (min-width: 1px) {
        li {width:100%;}
    }
}
/*교육중점*/
.focus-education { text-align:center;
    li { width:25%;float:left;}
    img {padding:0; margin:0;}
    @media screen and (max-width: 940px) and (min-width: 1px) {
        li {width:50%; margin-bottom:30px;}
    }
    @media screen and (max-width: 480px) and (min-width: 1px) {
        li {width:100%; margin-bottom:10px; }
    }
}

/*교육중점-시흥교육*/
.focus-education2 {
    li {width:25%; float:left; text-align:center; background-position:top center; background-repeat: no-repeat; padding-top:200px;
        dt {font-size:25px; color:#222222;}
        dd {font-size:15px; color:#3a3a3a; padding-top:15px;}
    }
    &__in1 {background-image:url(../../resources/images/cont/focus-education2-img1.jpg)}
    &__in2 {background-image:url(../../resources/images/cont/focus-education2-img2.jpg)}
    &__in3 {background-image:url(../../resources/images/cont/focus-education2-img3.jpg)}
    &__in4 {background-image:url(../../resources/images/cont/focus-education2-img4.jpg)}
    @media screen and (max-width: 680px) and (min-width: 1px) {
        li {width:50%; margin-bottom:30px; padding:200px 20px 0 20px; box-sizing: border-box; height:340px}
    }
    @media screen and (max-width: 440px) and (min-width: 1px) {
        li {width:100%; }
    }
}

/*특색교육*/
.feature-edu {text-align:center;
    &__tit {color:#414346; font-size:23px; font-weight: 500; display:inline-block; padding:0 25px;
        background-image: url(../../resources/images/cont/feature-edu-img1.png),url(../../resources/images/cont/feature-edu-img2.png); background-repeat:no-repeat;
        background-position: left center, right center;background-size:contain;
    }
    &__in { margin-top:30px; display:block;
        li {float:left; width:33.33%;}
    }
    @media screen and (max-width: 800px) and (min-width: 1px) {
        &__in {
            li {padding:0 5px; box-sizing: border-box;}
        }
    }

    @media screen and (max-width: 600px) and (min-width: 1px) {
        &__tit {font-size:19px; }
        &__in {
            li {width:50%;}
        }
    }

    @media screen and (max-width: 400px) and (min-width: 1px) {

        &__in {
            li {width:100%; padding:0;}
        }
    }
}
/*내부시설 안내*/
.office-layout {
    li { display:block;border:1px solid #919191;background-color:#f6f7f8; padding-top:24px; padding-bottom:102px; margin-bottom:50px;
        dt {color:#5b5b5b; font-size:40px; font-weight:bold; padding-left:27px;}
        dd {text-align:center; margin-top:25px; padding:0 20px; box-sizing: border-box;}
    }
    @media screen and (max-width: 600px) and (min-width: 1px) {
       li { margin-bottom:30px; padding-bottom:50px;
           dt {font-size:30px; padding-left:15px;}
           dd {padding:0 15px;}
       }
    }

}
.sub-slide {
	position: relative;
	margin-bottom: 10px;

	.slick-prev {position:absolute;top:0;left:0;z-index: 1; width:50px; height:100%;background-color:rgba($color: #fff, $alpha: 0.3); font-size:0; text-indent: -9999px;}
	.slick-prev::before {content: ""; display:block; height: 72px; background-image: url(../../resources/images/cont/sub-slide-prev.png);}
	.slick-next {position:absolute;top:0; right:0;z-index: 1; width:50px; height:100%; background-color:rgba($color: #fff, $alpha: 0.3);font-size:0; text-indent: -9999px;}
	.slick-next::before {content: ""; display:block; height: 72px; background-image: url(../../resources/images/cont/sub-slide-next.png);}
}

  .sub-slide__box {
	max-width: 100%;
	margin: 0 auto;
  }

  .sub-slide__box img {
	width: 100%;
	max-height: 600px;
  }

  .sub-slide__prev {
	left: 0;
  }

  .sub-slide__prev:after {
	content: '<';
  }

  .sub-slide__next {
	right: 0;
  }

  .sub-slide__next:after {
	content: '>';
  }

/*찾아오시는 길*/
.way-to-come {
    > ul {margin-top:35px; background-color:#fbfbfb; border-top:2px solid #abb6c2; border-bottom:2px solid #abb6c2; display:flex;
        li {flex:1;
            dl {color:#353535; padding:20px 10px 20px 55px; background-repeat: no-repeat;background-position: 18px 18px; margin-bottom:30px;
                dt {font-size:20px; font-weight:500; margin-bottom:10px;}
                dd {font-size:16px;}
            }
            ul li {}
        }
        > li:first-child { border-right:1px solid #e7e8e9;}
        > li:last-child {
            dl {margin-bottom:0;}
        }
    } 
    &__dl1 {background-image:url(../../resources/images/cont/way-to-come-img1.png)}
    &__dl2 {background-image:url(../../resources/images/cont/way-to-come-img2.png)}
    &__dl3 {background-image:url(../../resources/images/cont/way-to-come-img3.png)}
    &__dl4 {background-image:url(../../resources/images/cont/way-to-come-img4.png)}
    &-list li{margin-left:-10px; background:url(../../resources/images/cont/way-to-come-list.png) left center no-repeat; padding-left:10px;}
    @media screen and (max-width: 500px) and (min-width: 1px) {
        > ul {display:block;
            li {
                dl {margin-bottom:10px;}
            }
            > li:first-child { border-right:0}
            > li:last-child {
                dl {margin-bottom:10px;}
            }
        }
    }
}

.innovation-edu {
    &__img {text-align:center; margin-top:50px; margin-bottom:50px;
        li:first-child img {margin-bottom:50px;}
        li:nth-child(2) img {margin-bottom:50px;}
    }
}


.jojik-tb {background-color:#fff;
	@include basicTable1;
	.left-td {text-align:left; padding-left:20px;}
}

.new-js-box {display:none;}
.new-js-box.on{display:block;}

.noafter:after {display:none !important;}
.jojikdo { min-height:1080px; background: url(../../resources/images/cont/jojikdo-bg.jpg) center 120px no-repeat;
	&__top {
		position:relative;
		// &:before {
		// 	content: ' ';
		// 	width: 448px;
		// 	height: 144px;
		// 	position: absolute;
		// 	top: 142px;
		// 	border-right:1px dashed #cccccc;
		// 	right:73px;
		// 	@media screen and (min-width:1px) and (max-width:1200px) {display: none;}
		// }

		&__jang { //교육장
			box-sizing: border-box;
			display: block;
			width:120px;
			height:120px;
			color:#2961c9;
			background-image: url('../../resources/images/cont/jojik-item1-bg.png');
			background-repeat: no-repeat;
			background-position: center center;
			margin:0 auto;
			line-height: 120px;
			font-size:24px;
			font-weight: bold;
			text-align: center;
			position: relative;
			margin-bottom:87px;
			@media screen and (min-width:1px) and (max-width:1200px) {
				margin-bottom:0px;
				margin:0 auto;
			}
			// &:before {
			// 	@include sprite($jojik-item-bul);
			// 	position: absolute;
			// 	bottom:-25px;
			// 	z-index:1;
			// 	left:50%;
			// 	transform: translateX(-50%);
			// 	@media screen and (min-width:1px) and (max-width:1200px) {display: none;}
			// }
			// &:after {
			// 	content:' ';
			// 	position: absolute;
			// 	top:120px;
			// 	left:50%;
			// 	transform: translateX(-50%);
			// 	display:block;
			// 	width:1px;
			// 	height:45px;
			// 	background-color:#ccc;
			// 	@media screen and (min-width:1px) and (max-width:1200px) {display: none;}
			// }
		}
	}

	&__nav {
		position: relative;
		@media screen and (min-width:1px) and (max-width:1200px) {margin-top:40px}
		//첫번째가로선
		// &:before {	
		// 	content:' ';
		// 	display:block;
		// 	width:470px;
		// 	margin:0 275px 0 auto;
		// 	border-top:1px solid #ccc;
		// 	@media screen and (min-width:1px) and (max-width:1200px) {display: none;}
		// }

		@media screen and (min-width:1px) and (max-width:1200px) {
			//.hidden_word{position:relative;left:0;text-indent: 0;font-size:20px;@include h3Type2}

		}
		&__ctg {
			&.open {
				padding-bottom:0;
			}
			
			width:900px;
			min-height:800px;
			//padding-bottom:150px;
			margin:0 auto;
			position: relative;
			&:after {position:absolute;content:''; border-left:1px solid #ccc;height:658px; left:4px;top:0;display:block !important;}
			&:before {position:absolute;content:''; border-left:1px dashed #ccc;height:242px; left:130px;top:390px;display:block !important;}
			@media screen and (min-width:1px) and (max-width:1200px) {
				width:100%;
				min-height: 50px;

				@include floatWrap;
			}
			&.on:after { //두번째 가로선
				// position: relative;
				// top:180px;
				// content:' ';
				// display:block;
				// width:747px;
				// margin:0 auto;
				// border-bottom:1px solid #e2e2e2;
				// @media screen and (min-width:1px) and (max-width:1200px) {display: none;}
			}
			&__link {
				position: absolute;
				top:0;
				display:block;
				width:162px;
				text-align: center;
				background-color:#3361ae;
				background-size: cover;
				height:48px;
				line-height:48px;
				color:#fff;
				text-align: center;
				font-size:19px;
				font-weight: 400;
				&.num--1 {//교육과
					left:50px;

				}
				&.num--2 {//행정과
					left:50px; top:342px;
					&:after {}
				}
				&.num--3 {
					left:50px; top:632px;
					&:before {}
					// &:after {top: -140px;height: 1px;width: 425px;right: -95%;left: auto;}
				}
				&:before {    content: '';
					@include sprite($jojik-item-bul);
					position: absolute;
					top:20px;
					z-index: 1;
					left:-46px;
					-webkit-transform: translateX(-50%);
					transform: translateX(-50%);}
				&:after {
					content:' ';
					position: absolute;
					top:24px;
					left:-23px;
					transform: translateX(-50%);
					display:block;
					width:46px;
					height:1px;
					background-color:#ccc;
					@media screen and (min-width:1px) and (max-width:1200px) {display: none;}
				}
				@media screen and (min-width:1px) and (max-width:1200px) {
					position: relative;
					width:100%;
					top:auto !important;
					right:auto !important;
					left:auto !important;
					margin-left:auto !important; margin-bottom:5px;
				}
			}
		}
		&__box {
			//min-height:500px;

			&.js-box  {
				display:block;position: static;min-height:0;
				@media screen and (min-width:1px) and (max-width:1200px) {
					
				}
			}
			.cost_list {
				width:162px;
				position:absolute;
				top:0;
				left:222px;
				// &:before {margin-left:75px;
				// 	content:'';display:block;background-position:top center;
				// 	background-image:url(../../resources/images/cont/jojik-item1-img3.jpg);
				// 	background-repeat: no-repeat; background-position:center;  width:164px; height:126px;

				// }
				@include floatWrap;

				li {margin-bottom:5px;
					 position:relative;

					@media screen and (min-width:1px) and (max-width:1200px) {
						& {
						}
					}
					> a {
						&:hover {opacity: 0.8;
							.new-js-list__in {background-image:url('../../resources/images/cont/jojik-item1-img6.jpg')}

						}
						display: block;
						width:162px;
						margin:0 auto;
						height:48px;
						text-align:center;
						line-height:48px;
						background-color:#5d656f;
						background-repeat: no-repeat;
						position: relative;
						box-sizing: border-box;
						word-break: normal;
						color:#fff;
						font-size:15px;
						.new-js-list__in {
							&:before {content:''; background-color:#ccc; width:12px; height:1px;position:absolute; left:-12px; top:20px;}
							position:absolute; left: 159px; text-align:center; font-size:12px;top:6px; width:116px;line-height:40px; height:40px;background-image:url('../../resources/images/cont/jojik-item1-img4.jpg')}
						&:before {//동그라미
							// @include sprite($jojik-item-bul);
							// position: absolute;
							// top:-4px;
							// left:50%;
							// transform: translateX(-50%);
							// @media screen and (min-width:1px) and (max-width:1200px) {display: none;}
						}
						&:after {//선
							// content:' ';
							// position: absolute;
							// top:-53px;
							// left:50%;
							// transform: translateX(-50%);
							// display:block;
							// width:1px;
							// height:50px;
							// background-color:#e2e2e2;
							// @media screen and (min-width:1px) and (max-width:1200px) {display: none;}
						}
					}

				}
				li.on .new-js-list__in {background-image:url('../../resources/images/cont/jojik-item1-img6.jpg')}
				li.on a {opacity: 0.9;}
				li:nth-child(1){}
			}

			.cost_box {
				min-height:500px;
				padding-top:0;

				&__tit {
					@include h3Type2
				}
			}
		}
		div {

				.cost_list.type2 { top:343px;
					li {}

					// &:before {margin-left:90px;
					// 	content:'';display:block;background-position:top center;
					// 	background-image:url(../../resources/images/cont/jojik-item1-img3.jpg);
					// 	background-repeat: no-repeat; background-position:center;  width:164px; height:126px;

					// }
				}


		}
		div {

				.cost_list.type3 { top:633px;
					li {}

					// &:before {margin-left:90px;
					// 	content:'';display:block;background-position:top center;
					// 	background-image:url(../../resources/images/cont/jojik-item1-img3.jpg);
					// 	background-repeat: no-repeat; background-position:center;  width:164px; height:126px;

					// }
				}


		}
	}

}

@media screen and (min-width:0\0){
	.jojikdo__nav__box .cost_list li a {letter-spacing: 0; writing-mode:lr-tb; text-align: center;}
}

//사이즈 조정 등
.jojikdo__nav__box {

	// &.on {

	// 	.cost-wrap1 .cost_list {
	// 		width:820px;
	// 		li {//교육학습지원과
	// 			width:68px; //갯수에 따른 비율
	// 			&:first-child a:after {//왼쪽 선
	// 				top:-142px;
	// 				height:139px;
	// 			}
	// 		}
	// 		@media screen and (min-width:1px) and (max-width:1200px) {
	// 			width:100%;
	// 		}
	// 		@media screen and (min-width:1px) and (max-width:1200px) {
	// 			li {width:100%;
	// 				a {width:100%;writing-mode: unset;height:auto;padding-bottom:10px;margin-bottom:5px; min-height:auto;}
	// 				br {display:none;}
	// 			}
	// 		}
	// 	}
	// 	.cost-wrap2 .cost_list {
	// 		width:820px;
	// 		li {//경영지원과
	// 			width:74.5px; //갯수에 따른 비율
	// 			&:nth-child(7) a:after {//오른쪽 선
	// 				top:-142px;
	// 				height:139px;left: 51%;
	// 			}
	// 		}
	// 		@media screen and (min-width:1px) and (max-width:1200px) {
	// 			width:100%;
	// 		}
	// 		@media screen and (min-width:1px) and (max-width:1200px) {
	// 			li {width:100%;
	// 				a {width:100%;height:auto;writing-mode: unset;padding-bottom:10px;margin-bottom:5px;min-height:auto;}
	// 				br {display:none;}
	// 			}
	// 		}
	// 	}
	// 	.cost-wrap3 {display:none;}
	// 	.cost-wrap3 .cost_list {
	// 		width:830px;
	// 		li {//감사담당관
	// 			width:76px; //갯수에 따른 비율
	// 			float:right;
	// 			&:last-child a:after {//오른쪽 선
	// 				top:-142px;
	// 				height:139px;
	// 			}
	// 		}
	// 		@media screen and (min-width:1px) and (max-width:1200px) {
	// 			width:100%;
	// 		}
	// 		@media screen and (min-width:1px) and (max-width:1200px) {
	// 			li {width:100%;
	// 				a {width:100%;height:auto;writing-mode: unset; padding-bottom:10px;margin-bottom:5px;min-height:auto;}
	// 				br {display:none;}
	// 			}
	// 		}
	// 	}
	// }
}
.jojikdo__nav__ctg.special:after {display: none;}
.jojikdo__nav__jang {
	display:none;
	position:absolute;
	top:777px;
	left:0;
	width:100%;
	@media screen and (min-width:1px) and (max-width:1200px) {
		position: relative;
		top:0;
	}
}
.jojikdo__nav__jang.on {
	display:block;
}

//
.cost-big {display:none;
	&__tit {@include h3Type2;background-color:#fff}
}
.cost-big.on {display:block; top:200px;left:0;width:100%; margin-top:50px;}


/*조직도 모바일*/

@media screen and (min-width:1px) and (max-width:1200px) {
    .jojikdo{min-height:auto;}
	.jojikdo__nav__box .cost_list {position:relative; width:100%; top:auto; right:auto;left:auto !important;}
	.jojikdo__nav__box .cost_list:before {display:none;}
	.jojikdo__nav div:last-child .cost_list:before {display:none;}
	.jojikdo__nav div:last-child .cost_list {right:auto;}
	.jojikdo__nav div .cost_list li {
		width:100%;
		margin-left:0;
		a {width:100%;background-image:none;background-color: #888;box-sizing: border-box; text-align: left;
			&:focus {background-image: none;}
		}
	}
	.new-js-list__in {text-align: center; left:auto !important; right:6px;background-size: 101%;
		&:before {display:none;}
	}
	.jojikdo__nav__box .cost_list li.on a  {background-size:100%;}
	.jojikdo__nav div .cost_list li a:hover {background-size:100%;}

	.jojikdo__nav div:last-child .cost_list li {margin-left:0;}
	.cost-big {display:none;}
	.jojikdo__nav__ctg__link.num--3 {width:100%;}
	.jojikdo__nav__box.js-box.on {padding-top:10px;}
	.cost-big.on {margin-top:0px;}

	.jojikdo__nav__ctg__link.on {color:gold}
	.jojikdo__nav__ctg__link:before {display:none !important;}
	.new-js-list.ver2 a:before {margin-left:5px;}
}


/*조직도 211231 추가*/
// .jojikdo__nav__box .cost_list li:first-child a,.jojikdo__nav__box .cost_list li:nth-child(2) a, .jojikdo__nav__box .cost_list li:nth-child(3) a,.jojikdo__nav__box .cost_list li:nth-child(4) a{background-color:#3e9f8b}
.jojikdo__nav__box .cost_list li:first-child a {background-color:#3e9f8b;position:relative;
	&:before {height:1px; width:10px; position:absolute; content:''; left:-10px; top:50%; background-color:#ccc}


}


.jojikdo__nav__box .cost-wrap1.cost-wrap1_1{
	.cost_list {left:222px;}
}
.jojikdo__nav__box .cost-wrap1.cost-wrap1_2{
	.cost_list {left:394px;}
}
.jojikdo__nav__box .cost-wrap1.cost-wrap1_3{
	.cost_list {left:566px;}
}
.jojikdo__nav__box .cost-wrap1.cost-wrap1_4{
	.cost_list {left:740px;}
}
.jojikdo__nav__box .cost_list li{
	&:before {content:''; width:1px; height:5px; background-color:#ccc;position:absolute; top:-5px;left:50%;margin-left:5px;}


}
.new-js-list.ver2 a{background-color:#3e9f8b !important; margin-top:30px !important; position:relative; 
	&:before {content:''; width:1px; height:30px; background-color:#ccc;position:absolute; top:-30px;left:50%;}

}
.jojikdo__nav__box .cost_list li:first-child {
	&:before {display:none;}
}

/* 연혁 */
.years--info {
	font-size:30px;
	font-weight: bold;
	margin-bottom: 90px;
	strong {
		color:#275bb6;
	}

}

.years {
	margin-left:180px;
	margin-bottom:30px;
	&__box {

		border-left: 2px solid #d8d8d8;
		padding-left:38px;
		position: relative;
		padding-bottom:60px;
		&:last-child {padding-bottom:0px;border-left: 0px;padding-left:40px;}
		&__num {
			position: absolute;
			top:-21px;
			left:-180px;
			background-color:#275bb6;
			color:#fff;
			font-size:24px;
			width:130px;
			height:43px;
			line-height:43px;
			text-align: center;
			border-radius: 30px;
			&:before {
				content:' ';
				display: block;
				width:46px;
				height:2px;
				background-color:#d8d8d8 ;
				position: absolute;
				right:-46px;
				top:21px;
			}
			&:after {
				position: absolute;
				right:-56px;
				top:15px;
				@include sprite($years-bul)

			}
		}
		&__ul {
			position: relative;
			top: -14px;
			&__li {
				font-size:17px;
				position: relative;
				padding-left:130px;
				margin-bottom:15px;
				em {
					position: absolute;
					top:0;
					left:20px;
					color:#275bb6;
				}
			}
		}
	}
}
@media screen and (min-width:1px) and (max-width:550px) {
	.years {margin-left:0px;}
	.years__box__num {
		position: relative;
		top:0;
		left:0;
		&:before {display:none;}
		&:after {display:none;}
		margin-bottom:20px;
	}
	.years__box {border-left:0px;
		padding-left:0;
		&:last-child {padding-left:0;}
	}
	.years__box__ul {top:0px;}
	.years__box__ul__li {font-size:14px;padding-left:100px;}
}

/*혁신교육지구 콜센터*/
.call-center {
    p {font-size:15px;color:#484848; }
    &-call { text-align:left;padding-left:30px;
		li {padding-left:30px;
		}
        li.call {background:url(../../resources/images/icons/call-center-img1.png) left center no-repeat}
        li.mail {background:url(../../resources/images/icons/call-center-img2.png) left center no-repeat}

    }
	&-operate {margin-top:30px;padding-left:50%; background:url(../../resources/images/cont/call-center-img1.png) left center no-repeat;
		.basicList > li {margin-bottom:10px;}

		@media screen and (min-width:1px) and (max-width:620px) {
			background:none; margin-top:0; padding-left:0;
		}
	}
}

/*민원 신청*/

.minwon {
	&__step { text-align:center;
		li {display:inline-block;padding-left:97px; padding-top:25px; padding-bottom:25px;color:#666666; font-size:16px; text-align: left;
			background-position: left center; background-repeat:no-repeat;
			p {color:#484848; font-weight:500; font-size:18px;}}
		li:first-child {background-image:url(../../resources/images/cont/minwon-img1.png); }
		li:last-child {margin-left:70px;position:relative; background-image:url(../../resources/images/cont/minwon-img2.png); }
		li:last-child::before {content:''; position:absolute; background:url(../../resources/images/cont/minwon-img3.png); height:20px; width:12px; display:inline-block;
			left:-40px; top:50%; margin-top:-12px;}
	}
	.agr_pt {height:240px; padding:20px 30px;overflow-y: scroll; border:1px solid #e7e7e7;
		dt {color:#484848; font-weight:500; font-size:18px;}
		dd {color:#666666; font-size:16px; padding:20px 0;}
		li {padding:10px 0;}
	}
	.agree_sel {color:#484848; font-size:16px; margin-top:10px;
		input {margin-right:5px;}
	}

	&__login { display:inline-block; width:100%;
		li {float:left; width:25%; text-align: center;
			a {padding-top:140px;display:inline-block;background-position: top center; background-repeat:no-repeat;}
			p {color:#484848; border:1px solid #e7e7e7; padding:10px 32px; display:inline-block; }
		}
		&__in1 {background-image:url(../../resources/images/cont/minwon-img4.png);}
		&__in2 {background-image:url(../../resources/images/cont/minwon-img5.png);}
		&__in3 {background-image:url(../../resources/images/cont/minwon-img6.png);}
		&__in4 {background-image:url(../../resources/images/cont/minwon-img7.png);}
	}

	@media screen and (min-width:1px) and (max-width:780px) {
		&__login {display:flex;
			li {margin-bottom:20px; padding:0 5px; box-sizing: border-box;
			}
		}
	}
	@media screen and (min-width:1px) and (max-width:560px) {
		&__login {display:table;
			li {width:50%; font-size:14px;
				p {height:40px; display:table-cell; vertical-align: middle;}
			}

		}
		&__step { text-align:center;
			li {padding-left:60px;background-size:50px; font-size:14px;
				p {font-size:16px;}
			}
			li:last-child {margin-left:40px;}
			li:last-child::before {left:-25px}
		}
		&__agr_box {
			p {font-size:16px; padding:0 10px;}
		}
	}
}

/*각종증명서 신청_이용안내*/
.certificate-box1 {border:1px solid #e7e7e7;
	li {float:left; position:relative; width:33.33%; text-align: center; height:65px; padding-top:140px;
		 padding-bottom:25px; font-size:14px; color:#484848; background-position: center 30px; background-repeat: no-repeat;
		 p {font-size:16px; font-weight:500; color:#275bb6;}
		 &::before {content:'';width:42px; position:absolute; display:inline-block; height:36px; right:-20px;top:50%; margin-top:-22px;
			background:url(../../resources/images/cont/cerificate-img11.png) center center no-repeat; }
		}
	li:first-child {background-image:url(../../resources/images/cont/cerificate-img1.png) }
	li:nth-child(2) {background-image:url(../../resources/images/cont/cerificate-img2.png) }
	li:last-child {background-image:url(../../resources/images/cont/cerificate-img3.png);
		&::before {display:none;}
	}

	@media screen and (min-width:1px) and (max-width:560px) {
		li {background-size:60px; background-position: center 40px; padding-top:110px;padding-bottom:35px;
			&::before {width:32px; background-size: contain;}
			}
	}
}

.certificate-box2 {display:inline-block;
	li {padding-bottom:45px; margin-top:20px;
		dl {padding-left:115px;position: relative; height:98px; box-sizing: border-box;
			dt {padding-top:5px; color:#275bb6; padding-bottom:15px;font-weight:500; font-size:18px;}
			dd {font-size:15px;}
			&::before {content:'';width:36px; position:absolute; display:inline-block; height:42px;left:30px; bottom:-55px; margin-top:-13px;
				background:url(../../resources/images/cont/cerificate-img10.png) center center no-repeat; }

		}
		&:last-child dl::before {display:none;}
	}
	li:first-child {background:url(../../resources/images/cont/cerificate-img4.png) top left no-repeat}
	li:nth-child(2) {background:url(../../resources/images/cont/cerificate-img5.png) top left no-repeat}
	li:nth-child(3) {background:url(../../resources/images/cont/cerificate-img6.png) top left no-repeat}
	li:nth-child(4) {background:url(../../resources/images/cont/cerificate-img7.png) top left no-repeat}

	@media screen and (min-width:1px) and (max-width:560px) {
		li {
			dl {height:auto;
				&::before { bottom:-20%; }
			}
		}
	}
}


.certificate-box3 {display:inline-block;
	li {padding-bottom:45px; margin-top:20px;
		dl {padding-left:115px;position: relative; height:98px; box-sizing: border-box;
			dt {padding-top:5px; color:#275bb6; padding-bottom:15px;font-weight:500; font-size:18px;}
			dd {font-size:15px;}
			&::before {content:'';width:36px; position:absolute; display:inline-block; height:42px;left:30px; bottom:-55px; margin-top:-13px;
				background:url(../../resources/images/cont/cerificate-img10.png) center center no-repeat; }

		}
		&:last-child dl::before {display:none;}
	}
	li:first-child {background:url(../../resources/images/cont/cerificate-img4.png) top left no-repeat}
	li:nth-child(2) {background:url(../../resources/images/cont/cerificate-img5.png) top left no-repeat}
	li:nth-child(3) {background:url(../../resources/images/cont/cerificate-img6.png) top left no-repeat}
	li:nth-child(4) {background:url(../../resources/images/cont/cerificate-img12.png) top left no-repeat}
	li:nth-child(5) {background:url(../../resources/images/cont/cerificate-img8.png) top left no-repeat}

	@media screen and (min-width:1px) and (max-width:560px) {
		li {
			dl {height:auto;
				&::before { bottom:-50px; }
			}
		}
	}
}


.certificate-box4 {display:inline-block;
	li {padding-bottom:45px; margin-top:20px; background:url(../../resources/images/cont/cerificate-img4.png) top left no-repeat;
		dl {padding-left:115px;position: relative; height:98px; box-sizing: border-box;
			dt {padding-top:5px; color:#275bb6; padding-bottom:15px;font-weight:500; font-size:17px;}
			dd {font-size:15px;}
			&::before {content:'';width:36px; position:absolute; display:inline-block; height:42px;left:30px; bottom:-55px; margin-top:-13px;
				background:url(../../resources/images/cont/cerificate-img10.png) center center no-repeat; }
		}
		&:last-child dl::before {display:none;}
	}
}

/*교습소 설립의 절차안내*/
.academy-guide {overflow:hidden;border:1px solid #e7e7e7; text-align: center;
	li {float:left; position:relative; width:20%; text-align: center; height:65px; padding-top:140px;
		 padding-bottom:75px; font-size:14px; color:#484848; background-position: center 30px; background-repeat: no-repeat;
		 p {font-size:16px; font-weight:500; color:#275bb6;}
		 &::before {content:'';width:42px; position:absolute; display:inline-block; height:36px; right:-20px;top:50%; margin-top:-50px;
			background:url(../../resources/images/cont/cerificate-img11.png) center center no-repeat; }
	}
	li:first-child {background-image:url(../../resources/images/cont/classroom-guide-img2.png) }
	li:nth-child(2) {background-image:url(../../resources/images/cont/classroom-guide-img3.png) }
	li:nth-child(3) {background-image:url(../../resources/images/cont/classroom-guide-img4.png) }
	li:nth-child(4) {background-image:url(../../resources/images/cont/classroom-guide-img5.png) }
	li:nth-child(5) {background-image:url(../../resources/images/cont/classroom-guide-img6.png);
		&::before {display:none;}
	}
	&__bottom {border-radius: 5px; border:5px solid #d5e6f8; display:inline-block; padding:14px 20px; font-size:16px; margin-bottom:30px;}
	@media screen and (min-width:1px) and (max-width:1200px) {
		background:none !important;
		li {padding-bottom:0; width:33.33%;margin-left:-15px;
		}
	}
	@media screen and (min-width:1px) and (max-width:560px) {
		li {background-size:90px; background-position: center 40px; padding-top:140px;padding-bottom:0; width:50%; height:45px;
			&::before {width:32px; background-size: contain; right:-10px; margin-top:-27px;}
			}
	}

	&2 {text-align: left;
		.academy-guide__bottom {margin-left:20px;
		}
	}
	@media screen and (min-width:1px) and (max-width:700px) {

		&2 {
			.academy-guide__bottom {display:block; margin:0 10px; margin-bottom:10px;
			}
		}

	}

}

/*유치원업무안내_설립안내*/

.kinder-box {display:block;
	li {padding-bottom:45px; margin-top:20px;
		dl {padding-left:115px;position: relative; height:98px; box-sizing: border-box;
			dt {padding-top:5px; color:#275bb6; padding-bottom:15px;font-weight:500; font-size:18px;}
			dd {font-size:15px;}
			&::before {content:'';width:36px; position:absolute; display:inline-block; height:42px;left:30px; bottom:-55px; margin-top:-13px;
				background:url(../../resources/images/cont/cerificate-img10.png) center center no-repeat; }

		}
		&:last-child dl::before {display:none;}
	}
	li:first-child {background:url(../../resources/images/cont/kinder-img1.png) top left no-repeat}
	li:nth-child(2) {background:url(../../resources/images/cont/kinder-img2.png) top left no-repeat}
	li:nth-child(3) {background:url(../../resources/images/cont/kinder-img3.png) top left no-repeat}
	li:nth-child(4) {background:url(../../resources/images/cont/kinder-img4.png) top left no-repeat}
	li:nth-child(5) {background:url(../../resources/images/cont/kinder-img5.png) top left no-repeat}
	li:nth-child(6) {background:url(../../resources/images/cont/kinder-img6.png) top left no-repeat}
	@media screen and (min-width:1px) and (max-width:560px) {
		li {padding-bottom:80px;
		}
	}
}

/*학원평생교육시설안내 - 학원설립운영안내*/
.academy-oparition {
	&__map {border:1px solid #e7e7e7; box-sizing: border-box; padding-top:30px; padding-bottom:20px; text-align:center;
		ul { display:inline-block;
			li {float: left; box-sizing: border-box;
				img {width:100%;}
				&:first-child {padding-right:60px;}
			}
		}

		@media screen and (min-width:1px) and (max-width:769px) {
			padding:0;
			ul {
				li { float:none; padding:30px 10px 20px 10px !important;
				&:first-child {padding-right:0; padding-bottom:20px;}
				}
			}
		}
	}
}

/* epki 인증서 로그인 */
.epki-login {border: 2px solid #1c5a94; padding:45px 20px; text-align: center; background-color:#fafbfc; color:#3d3d3d;
	&__tit {font-size:24px; font-weight: 600;}
	&__txt {font-size:18px; padding-top:150px; background:url(../../resources/images/cont/epki-img1.png) top center no-repeat; margin-bottom:40px; margin-top:40px;}
	&__btn {background-color:#1c5a94; color:#fff; padding:20px 90px; font-weight:300;font-size:18px; display:inline-block;}
	@media screen and (min-width:1px) and (max-width:500px) {
		&__tit {font-size:20px;}
		&__txt {font-size:16px;}
		&__btn {font-size:16px; padding:15px 40px}
	}
}

/*학생봉사활동*/
.volunteer {
	&__go {margin-top:80px; display:inline-block;}

}


.txtType-new {
	padding:20px;
	background-color:#e8f0f8;
	border:1px dashed #999;
	border-left:0;
	border-right:0;
	margin-bottom:30px;
	a {
		&:hover,
		&:focus {text-decoration: underline;}
	}
}


.blue-tit {padding-left:20px;color:#21539b;font-size: 17px;font-weight: bold;margin-bottom:5px;}


// 시흥교육
// 조직도
.h4Type5 {
		text-align: left;
		position: relative;
		padding-left:12px;
		font-size:17px;
		font-size:1.7rem;
		line-height:1.7;
		font-weight: 500;
		margin-left:7px;
		margin-bottom:10px;
		&:before {
			position: absolute;
			top:11px;left:0;
			content: ' ';
			@include sprite($list-bul2);
		}
}
.txtType-new2 {
	padding:25px;
	border:1px solid #ddd;
	margin-bottom:30px;
}

//특수교육대상자선정배치
.sp-edu {
	font-size:16px;color:#000;
	.txtType1 {font-size:16px;color:#000;margin-bottom:30px;}
}
.edu-produce {
	text-align: center;
	@include floatWrap;
	width:82%;
	margin:0 auto;
	.edu-num {
		border:2px solid #c0daf5;
		border-radius: 15px;
		font-size:16px;
		padding-top:13px;
		padding-bottom:13px;
		margin-bottom:35px;
		box-sizing: border-box;
		float:left;
		width:100%;
		strong {display:block; color:#21539b;}
		em { color:#21539b;font-weight: bold;}
		position: relative;
		&:after {
			position: absolute;
			bottom:-30px;
			left:50%;
			transform: translateX(-50%);
			content:'▼';
			color:#2e84dd;

		}
		&:last-child {
			&:after {display: none;}
		}
	}
	.edu-num1 {width:35%;float:left;
		&:after {bottom:-70px}
	}
	.edu-num-sp1 {width:35%;float:right;}
	.edu-num-sp2 {width:35%;float:right;}
}

//다문화교육 지원센터 안내
.multi-edu {
	font-size:16px;color:#000;
	&__num1 {
		text-align: center;
		width:80%;margin:0 auto;
		padding-top:20px;
		padding-bottom:20px;
		border:1px solid #b9c0d9;
		border-left:3px solid #2d6fbf;
		border-right:3px solid #2d6fbf;
		strong {
			display:block;
			margin-bottom:10px;
			color:#2d6fbf;
			font-size:22px;
		}
		margin-top:20px;
		margin-bottom:30px;
		@media screen and (max-width: 768px) and (min-width: 1px) {
			width:90%;
		}
	}
	&__num2 {
		text-align: center;
		width:80%;margin:0 auto;
		padding-top:20px;
		padding-bottom:20px;
		border:1px solid #b9c0d9;
		border-left:3px solid #43a7da;
		border-right:3px solid #43a7da;

		strong {
			display:block;
			margin-bottom:10px;
			color:#43a7da;
			font-size:22px;
		}
		margin-bottom:30px;
		div {
			text-align: left;
			padding-left:90px;
			padding-right:20px;
		}
		@media screen and (max-width: 768px) and (min-width: 1px) {
			width:90%;
			div {padding:20px;}
		}
	}
	&__num3 {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin-bottom:40px;
		margin-left:-20px;
		@media screen and (max-width: 768px) and (min-width: 1px) {
			margin-left:0;
		}
		&__tit {
			position: absolute;
			top:48%;
			transform: translateY(-50%);
			left:50%;
			margin-left:-37px;
			color:#fff;
			font-size:22px;
			line-height: 36px;
			text-align: center;
				.hexagon {
					position: relative;
					height: 112px;
					padding-top:30px;
					width: 90px;
					background-color: #50619f;
					margin: 40.99px 0;
				  }

				  .hexagon:before,
				  .hexagon:after {
					content: "";
					position: absolute;
					width: 0;
					top:0;
					border-top: 71px solid transparent;
					border-bottom: 71px solid transparent;
				  }

				  .hexagon:before {
					left: 100%;
					border-left: 40.99px solid #50619f;
				  }

				  .hexagon:after {
					right: 100%;
					width: 0;
					border-right: 40.99px solid #50619f;
				  }

			@media screen and (max-width: 768px) and (min-width: 1px) {
				top:0;
				left:0;
				position: relative;
				transform: translateY(0);
				width:100%;
				margin-left:0;
				.hexagon {width:100%;height:auto;line-height: 1.6;padding-top:10px;padding-bottom:10px;margin-bottom:20px;}
				.hexagon:after,
				.hexagon:before {display:none;}
			}
		}
		&__item {
			flex:1;
			min-width: 50%;
			box-sizing: border-box;
			margin-bottom:20px;
			@media screen and (max-width: 768px) and (min-width: 1px) {
				min-width: 100%;
			}
			div {
				margin-left:20px;
				padding:30px 60px;
				border:1px solid #b9c0d9;
				min-height:135px;
				@media screen and (max-width: 768px) and (min-width: 1px) {
					margin-left:0;
					padding:20px;
				}
			}
			strong {
				color:#50619f;
				font-size:18px;
				margin-bottom:10px;
				display: block;
			}
			&__list {
				@include basicList3
			}
		}
	}

}

/*구직 구인 스승찾기 링크박스*/
.link-box {padding-top:70px;  padding-bottom:60px; display:inline-block;padding-left: 35%;
    text-align: center;
	&__wrap {background-image:url(../../resources/images/cont/link-box-img.png);background-position: 10% center; background-repeat: no-repeat;background-color: #f4f7fa;}
	&__txt {font-size:20px; color:#585858;font-weight:500;
		span {color:#399bd7;}
	}
	a {color:#fff; font-weight:300; font-size:18px; display:inline-block; margin-top:20px;padding:8px 60px 8px 30px; background:url(../../resources/images/cont/link-box-img2.png) 85% center no-repeat #399bd7; }

	@media screen and (max-width: 768px) and (min-width: 1px) {
		padding:180px 15px 60px 15px; display:block;
		&__wrap {background-position:center 40px;}
	}
}

/*정보공개제도안내*/

.text-box{
	padding:20px;
	margin:10px 0 10px -11px;
	border:3px solid #e7e7e7;
}

.info_arrow{
	width:100%;
	height:80px;
	background-image:url("../../resources/images/cont/cerificate-img10.png");
	background-repeat: no-repeat;
	background-position: center center;
}

.bbs_view{
	&__in {margin-bottom: 10px;
		position: relative;
		border-top: 2px solid #000;
		table {width:100%;
			tr {border-bottom:1px solid #cdcdcd;box-sizing: border-box; }
			th,td {padding-top:10px; padding-bottom:10px; box-sizing: border-box; font-size:15px; }
			th {background-color:#f7f7f7; vertical-align: middle; padding-right:20px;  padding-left:24px; text-align: left; color:#010101;}
			td {padding-left:10px; padding-right:10px;color:#484848; vertical-align: middle;}

			@media screen and (max-width: 768px) and (min-width: 1px) {
				td, th {padding:10px 0;}
			}
		}
	}
	&__data {text-align:right;margin-bottom:15px;color:#484848; font-size:15px;
		span {margin-left:20px;  }
	}

}

/*프린트*/
@media print {
	header,footer,.sidebar,.location-box,.special-box {display:none; }
	.sub-contents {width:100%;}
}

/*회원가입*/
.sign {
	&__main {margin-top:90px;
		&__txt1 {font-size:19px; font-weight:300; margin-bottom:65px; margin-top:15px;}
		&__txt2 {font-size:24px; font-weight:500;}
		&__txt3 {font-size:17px; margin-top:7px; margin-bottom:13px; font-weight:300;}
		&__btn {margin-left:10px; font-size:19px; font-weight:500; float:left; background-color:#275bb6; color:#fff; display:inline-block;padding:10px 25px;}
		&__btn2 {padding: 10px 20px;font-size: 15px; color:#fff;background-color: #505151;}
		&__input{display:inline-block;
			input {padding: 5px 10px; float:left;
				box-sizing: border-box;
				height: 43px;
				border: 1px solid #bcbcbc;}
		}
		@media screen and (max-width: 768px) and (min-width: 1px) {
			&__txt1 {font-size:18px; font-weight:300; margin-bottom:65px; margin-top:15px;}
			&__txt2 {font-size:18px; font-weight:500;}
			&__txt3 {font-size:15px; margin-top:7px; margin-bottom:13px; font-weight:300;}
			&__btn {margin-top:10px; font-size:17px; float:none; font-weight:600; background-color:#275bb6; color:#fff; display:inline-block;padding:10px 25px;}
			&__input{
				input {float:none;margin-top: -7px;}
			}
		}
	}

}
@media screen and (min-width:1px) and (max-width:1200px) {
	.js-box{display: none !important;
		&.on{display: block !important;}
	}
	.jojikdo__nav div .cost_list.type2{top: auto;}
	.jojikdo__nav div .cost_list.type3{top: auto;}
	.jojikdo__nav__ctg:before {display:none !important;}
}