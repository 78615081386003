
/*		sidebar
=========================*/
/* sidebar */
.sidebar {
	position:relative;
	width:220px;
	float:left;
	padding-bottom:40px;
	font-family: $font2;
	&__wrap {background-color:#fff;}
	&__h2 {
		text-align:center;
		padding-top:45px;
		padding-bottom:43px;
		padding-left:10px;
		padding-right:10px;
		font-size:32px;
		font-size:3.2rem;
		font-weight:500;
		position:relative;
		color:#fff;
		line-height: 1.2;
		background-color:#2e84dd;
		span {display:block;font-size:12px;color:#fff;text-align:center;}
		a {display:none;}
		/*메뉴 이름 길경우*/
		&.long-type {font-size:22px;padding-top:25px;height:80px;padding-bottom: 40px; word-break: break-all;}
	}


}
.side-list {
	&__li {
		> span > a {
			display:table;
			padding-left:20px;
			padding-top:8px;
			padding-bottom:8px;
			width:100%;
			box-sizing: border-box;
			height:46px;
			border-right:1px solid #e0e0e0;
			border-left:1px solid #e0e0e0;
			border-top:1px solid #fff;
			border-bottom:1px solid #e0e0e0;
			background-color:#fff;
			color:#555;
			font-size:16px;
			font-weight:normal;
			transition: all 0.3s ease 0s;
			background-position: 213px center;

			position: relative;
				&:after {
					position: absolute;
					top:50%;
					transform: translateY(-50%);
					right:20px;
				}
				em {vertical-align:middle; display:table-cell;}
		}

	}
	&__li.open-type > span > a {
		position: relative;
		&:after {
			content:'';
			display: inline-block;
			@include sprite($side-list-li-off);
			position: absolute;
			top:50%;
			margin-top:-1px;
			right:20px;
		}
	}


	// on일때 배경색이 없다면 주석처리 strat
	&__li.open-type > span > a:hover,
	&__li.open-type > span > a:focus,
	&__li.open-type > span.on > a,
	&__li.open-type > span > a.on {
		&:after {
			content:'';
			display: inline-block;
			@include sprite($side-list-li-on);
			position: absolute;
			top:50%;
			margin-top:-2px;
			right:20px;
		}
	}
	// end

	&__li.open-type.on > span > a {
		&:after {
			content:'';
			display: inline-block;
			@include sprite($side-list-li-on);
			position: absolute;
			top:50%;
			margin-top:-1px;
			right:20px;
		}
	}
	&__li.on > span {
		display:block;
		> a {
			font-weight:normal;opacity:0.9;color:#fff;background-color:#275bb6;border:1px solid #275bb6;

			position: relative;
			&:after {
				position: absolute;
				top:50%;
				transform: translateY(-50%);
				right:20px;
				@include sprite($side-bul-on);
			}
		}
	}

	&__li > span > a:hover,
	&__li > span > a:focus,
	&__li > span.on > a,
	&__li > span > a.on,
	&__li.selected > span > a {
		font-weight: normal;
		opacity: 0.9;
		color:#fff;
		background-color:#275bb6;
		border: 1px solid #275bb6;
	}

}



//3차

.side-list__li__inbox {display:none;}

.open-type.on > .side-list__li__inbox {
	display:block;
	background-color:#f6f7f8;
	padding:10px;
	border:1px solid #e0e0e0;
	}

.spp__in > a{
	display:block;
	font-size:15px;
	font-size:1.5rem;
	color:#686868;
	padding-top:4px;
	padding-bottom:4px;
	padding-left:13px;

	&:before {
		content: '';
		display: inline-block;
		@include sprite($side-list-inbox);
		padding-right:8px;
	}

}
.spp__in > a:hover,
.spp__in > a:focus,
.spp__in.on > a,
.spp__in > .on,
.spp__in.selected > a,
.spp__in > .selected {
	color:#275bb6;text-decoration-line: underline; box-sizing: border-box;
	&:before {
		content: '';
		display: inline-block;
		@include sprite($side-list-inbox_on);
	}
}
	.spp__in__small {display:none;}
	.on > .spp__in__small {display:block;padding:3px 10px 10px 15px;}

		.spp__in__small a{
			display:block;
			padding-top:5px;
			padding-bottom:5px;
			padding-left:15px;
			font-size:13px;
			font-size:1.3rem;

		}
		.spp__in__small .on,
		.spp__in__small a:hover,
		.spp__in__small a:focus {text-decoration:underline;}

		.sp-open-type {
			position:relative;
			&:after {
				content:'';
				display: inline-block;
				@include sprite($open-close);
				position: absolute;
				top:13px;
				right:15px;
			}
		}
		.sp-open-type.on {
			&:after {
				content:'';
				display: inline-block;
				@include sprite($open-done);
				position: absolute;
				top:15px;
				right:15px;
			}
		}

			/*4차*/
			.spp__in__small__4th {display:none;}
			.on > .spp__in__small__4th {display:block;padding:10px 5px 10px 10px;background-color:#eff3f7}

			.spp__in__small__4th a{display:block;padding:3px 0;min-height:17px;font-size:13px;font-size:1.3rem; font-weight: normal;padding-left:15px;}

			.spp__in__small__4th > li.on > a,
			.spp__in__small__4th > li > .on,
			.spp__in__small__4th a:hover,
			.spp__in__small__4th a:focus {text-decoration:underline;}


/* 반응형 버튼 */
.mobile-submenu-btn {display:none;background-color:#3d6cbd;text-align:center;color:#fff;padding-left:5px;padding-right:5px;
	box-sizing: border-box;
	height:29px;
	line-height: 29px;
	position:absolute; top:3px;right:7px;z-index:98;
}
.mobile-submenu-btn .type1 {display:block;}
.mobile-submenu-btn .type2 {display:none;}
.selected.mobile-submenu-btn .type1 {display:none;}
.selected.mobile-submenu-btn .type2 {display:block;}

@media screen and (min-width:1px) and (max-width:$siteSize) {

	.sidebar{position:relative;z-index:1;margin-top:0px;float:none;width:100%;padding-bottom:0;}
	.side_in_wrap{display:none;border-bottom:2px solid #2e84dd;opacity:0;}
		.sidebar h2{
		text-align:left;background-image:none;background-color:#2e84dd;padding-left:10px;font-size:1.5rem;height:32px;
		padding-top:5px;line-height:1.6
		}
	.sidebar h2 {color:#fff;min-height:10px;height:30px;border-radius:0px;}
	.sidebar h2 a{display:none;}
	.sidebar__h2{padding-bottom:0px;}
	.sidebar__h2.long-type{padding-bottom:0px;font-size: 16px;padding-top:5px;height:32px;}
	.sidebar h2 {text-align:left;padding-left:20px;font-size:16px;}
	.side-list__li > span > a {height:30px;line-height:30px;}
	.side-list__li.open-type > span > a {background-position:96% center}
	.side-list__li.open-type.on > span > a {background-position:96% center}
	.sp-open-type {background-position:97% center}
	.sp-open-type.on {background-position:97% center}

	/**/
	.sidebar__wrap {opacity:1.0 !important;margin-left:0px !important;}

	.side_in_wrap{display:block;border-bottom:2px solid #000;opacity:1;}
	.side_in_wrap li.selected span a {
		background-color: #fff !important;
		color: #f6804e !important;
	}
	.mobile-submenu-btn {display:block;}
	.open-type.on > .side-list__li__inbox {padding:0 10px;}
	.sidebar .side-list > li {overflow:hidden;height:0;}
	/*.sidebar .side-list > li.on {display:block;}*/


	.sidebar .side-list > li.on {height:auto;}
	.sidebar.selected .side-list > li {height:auto;}
	.side-list__li > span > a {padding:0px 0 0px 20px; line-height: 1.5; padding-top:5px; padding-bottom:8px;
		transition: all 0.3s ease 0s;
	}
	.sidebar.selected .side-list__li > span > a {padding:5px 0 5px 20px}
	.sidebar.selected .side-list > li.open-type {height:auto;}
}

/*통합검색*/
.subpage-type2 { 
	.sidebar {display:none;}
	.sub-contents__top {border:0; margin-bottom:5px;}
	.sub-contents__top h2.sub-tit {font-size:38px; color:#282828; font-weight:bold;}
	.sub-contents {width:100%;}
	.location-box {display:none;}
	.special-box {display:none;}
	.mobile-submenu-btn {display:none;}
}