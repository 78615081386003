

/*	메인페이지
===========================*/


.main-section {position:relative;
	font-family: $font2;
}
.main-section__in {position:relative;min-height: 500px;width:100%;margin:0 auto;
	background-color:#fff;

	&__top {
		position: relative;
		z-index: 2;
		overflow: hidden;
		background-color:#fff;
		background-image:url('../../resources/images/cont/main-section__in__top.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		&__box {
			@include floatWrap;
			width:100%;max-width:$siteSize;margin:0 auto;
			padding:60px 0;
		}
	}
	&__middle {
		background-color:#dbefff;
		position: relative;
		z-index: 2;
		&__box {
			@include floatWrap;
			max-width:$siteSize;margin:0 auto;
			padding:60px 0;
		}

		&__box__in {width:50%; float:left;position: relative; margin-top:30px;
			&__tit {font-size:30px; font-weight:600; color:#282828;}
			&__more {@include sprite($bbs-more); font-size:0; text-indent: -9999px; position:absolute; top:12px; right:30px;}
			&__cont { margin-top:15px;
				li {width:50%; float:left;
					a {position:relative; margin-right:30px; background-color:#fff;box-sizing: border-box; display:block;transition: all 0.3s ease 0s; height:210px;}
				}
			}
			&__cont.bbs {
				li {
					a {padding:25px;}
					dl {
						dt {font-size:18px; color:#484848;height: 2.5em;width:100%;
							display: inline-block; overflow: hidden; text-overflow: ellipsis;white-space: normal; line-height: 1.3;
							text-align: left; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
						}
						.text {padding-top:15px;  display: inline-block; overflow: hidden; text-overflow: ellipsis;font-size:15px; color:#787878;white-space: normal; line-height: 1.3;
							text-align: left; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; height:3.8em}
						.time {font-size:13px; color:#7d7d7d; position:absolute; bottom:30px;}
					}
					a:hover {background-color:#275bb6; -webkit-box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.26);
						-moz-box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.26);
						box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.26);
						dl {
							dt,dd{color:#fff;}
						}

					}

				}
			}
			&__cont.bbs2 {
				li > a {display:block;
					.img_box {position: relative;
						z-index: 1;
						overflow: hidden;
						padding-bottom: 52%;
						background-color: #f7f7f7;
						text-align: center;
						img {width: 100%;
							height: auto;
							vertical-align: middle;transition: all 0.3s;}
					}
					.img_box .inner_box{font-size: 0;}
					.inner_box{position: absolute;
						left: 0;
						top: 0;
						z-index: 1;
						width: 100%;
						height: 100%;}
					.mid_helper {
						display: inline-block;
						height: 100%;
						vertical-align: middle;
					}
					.txt_box {margin-top:10px;
						padding: 0 20px;
						.tit { display: inline-block;box-sizing: border-box;width: 100%;; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
						color:#484848; font-size:16px;
						}
					}
					&:hover {background-color:#275bb6;-webkit-box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.26);
						-moz-box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.26);
						box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.26);
						.tit, .info_box {color:#fff;}

					}
					&:hover img, &:focus img{-webkit-transform:scale(1.3); -moz-transform:scale(1.3); transform:scale(1.3);}
				}
				.info_box {
					display: inline;
					height: 16px;
					color: #7d7d7d;
					font-size: 13px;
					padding: 0 20px;
					position:absolute; bottom:15px;
					span {
						display: inline-block;
						vertical-align: top;
					}
				}
			}

		}
		&__box__in:first-child, &__box__in:nth-child(2) {margin-top:0;}

		&__box__in:nth-child(2n) {
			.main-section__in__middle__box__in__tit {margin-left:30px;}
			.main-section__in__middle__box__in__more {right:0;}
			.main-section__in__middle__box__in__cont	{
				li {
					a {margin-right:0; margin-left:30px;}
				}
			}
		}
	}
	&__bottom {
		background-color:#fff;
		&__box {
			@include floatWrap;
			max-width:$siteSize;margin:0 auto;
			padding-top:34px; padding-bottom:40px;
			li a {float:left; width:12.5%; text-align:center; padding-top:130px;  background-repeat: no-repeat;color:#282828; font-size:17px;
			&:hover {opacity: 0.8;}
			}
			&__1 a{background-image:url(../../resources/images/cont/bottom__box-img1.png); background-position:center top; background-size:100%;}
			&__2 a{background-image:url(../../resources/images/cont/bottom__box-img2.png); background-position:center 20px;}
			&__3 a{background-image:url(../../resources/images/cont/bottom__box-img3.png); background-position:center 20px;}
			&__4 a{background-image:url(../../resources/images/cont/bottom__box-img4.png); background-position:center 10px;}
			&__5 a{background-image:url(../../resources/images/cont/bottom__box-img5.png); background-position:center top;}
			&__6 a{background-image:url(../../resources/images/cont/bottom__box-img6.png); background-position:center 30px;}
			&__7 a{background-image:url(../../resources/images/cont/bottom__box-img8.png); background-position:center 2px;}
			&__8 a{background-image:url(../../resources/images/cont/bottom__box-img9.png); background-position:center 2px;}
		}
	}
}
.main-lay-1 {float:left;max-width:895px; width:100%;}
.main-lay-2 {float:right; width:100%;max-width:275px;margin-bottom: 30px;}
.main-lay-3 {float:right; width:100%; max-width:275px;}
.main-lay-4 {float:left;width:50%;}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.main-section__in {width:auto;
		&__top__box {padding-left:10px;padding-right:10px;width:auto;}
	}
	.main-section__in__middle__box__in{
		&__more {right:10px;}
		&__cont {
			&.bbs {
				li {
					a {padding:20px; margin-right:10px;}
				}
			}

		}
		&:nth-child(2n) {
			.main-section__in__middle__box__in__cont li a {margin-left:10px;}
		}
	}

}

@media screen and (min-width:1px) and (max-width:1000px) {
	.main-section__in__middle__box__in__cont.bbs2 li > a .txt_box .tit {display: -webkit-box; -webkit-line-clamp: 2; white-space: normal;line-height: 1.2; height:2.5em;
	-webkit-box-orient: vertical;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-section {padding-top:51px;}
	.main-lay-1 {padding-bottom:10px;}
	.main-section__in{
		&__top__box {padding:10px 10px 0 10px}
		&__middle__box{padding:30px 10px;
			&__in{width:100%; float:none; display:inline-block;
				&__more {right:0; top:5px;}
				&__cont {margin-top:10px;
					&.bbs a {height:190px;}
					&.bbs2 a {height:auto;}
					&.bbs li dl .time {bottom:20px;}
					li:first-child a {margin-right:5px !important;}
					li:nth-child(2) a {margin-right:0; margin-left:5px !important;}
				}
				&__tit {font-size:23px;}
				&__cont.bbs2 li {
					> a .txt_box .tit {-webkit-line-clamp: 3; height:3.6em;}
				}
			}
		}
		&:nth-child(2n) .main-section__in__middle__box__in__tit {
			margin-left: 0;
		}
	}
	.main-section__in__middle__box__in:nth-child(2) {margin-top:30px;}
	.main-section__in__middle__box__in:nth-child(2n) .main-section__in__middle__box__in__cont li a {margin-left:0; margin-right:0;}
	.main-section__in__middle__box__in__cont.bbs2 .info_box {position: relative; padding-bottom:15px; bottom:auto; display:inline-block;}
	.main-section__in__bottom__box {
		li a {width:33.33%;}
	}
}

@media screen and (min-width:1px) and (max-width:500px) {
	.main-section__in__bottom__box {
		li a {width:50%;}
	}
}


@media screen and (min-width:1px) and (max-width:$siteSize + 80px) {
	.main-lay-1 {width:75%;}
	.main-lay-2 {width:23%; float:left;padding-left:2%;}
	.main-lay-3{width:23%;float:left;padding-left:2%;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-lay-1 {width:100%;float:none; margin:0 auto;}
	.main-lay-2 {float:left; width:49%; padding-right:1%; text-align: center; margin:0 auto;max-width:100%;padding-left:0;}
	.main-lay-3{float:left; width:49%; padding-left:1%; text-align: right; margin:0 auto; max-width:100%; }
}

//메인지도
.main-jido-btnbox {

	position: absolute;
	top:0;
	right:0;
	z-index: 19;
	height:610px;
	width:52px;
	&__btn {
		display: block;
		height:100%;
		font-size:16px;
		font-weight: bold;
		color:#fff;
		width:22px;
		text-align: center;
		line-height:1.2;
		padding:0 15px;
		background-color:#275bb6;

		span {
			top:40%;
			position: relative;
			&:after {
				margin-top:10px;
				@include sprite($jido-more)
			}
		}
	}
	&.on {

		.main-jido-btnbox__btn {
			position: relative;
			z-index: 9;
			span {

				&:after {
					@include sprite($jido-close)
				}
			}
		}
	}
}

.main-jido {
	position: absolute;
	top:0;
	right:0;
	height:610px;
	z-index: 1;
	//width:52px;
	width:100%;
	transition: all 0.3s ease 0s;

	&__box {
		width:100%;
		height:610px;
		overflow: hidden;
		transition: all 0.3s ease 0s;
		box-sizing: border-box;
		position: absolute;
		top:0;
		right:0;
		.root_daum_roughmap_landing {width:100% !important;}
		> div {width:100% !important;
			wrap_map {width:100% !important;}
		}
	}
	&__info {display:block; position:relative; z-index:999;max-width:1200px; width:100%; margin:0 auto; height:0;
		ul  {width:410px; margin-top:30px; height:54px; background-color:#fff; border-radius:10px;-webkit-box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.2);
			-moz-box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.2);box-sizing: border-box;
			box-shadow: 6px 6px 10px 1px rgba(0,0,0,0.2);padding:5px 20px;
			li {float:left;  font-size:16px;box-sizing:border-box; font-weight:500; padding-top:8px;padding-right:13px;
				img {width:22px; padding-right:5px;}
				&.type-pink {color:#e35ea0}
				&.type-blue {color:#3a74bb}
				&.type-green {color:#0bac4c}
				&.type-red {color:#cc2127}
			}
		}
	}
	&.on {
		width: 100%;
		height:610px;
		z-index: 4;
		.main-jido__box {
			position: absolute;
			top:0;
			right:0;
			width:100%;
			padding-right:52px;
			background-color:gold;
			> div {width:100% !important;
				wrap_map {width:100% !important;}
			}
		}
	}
	
	//layer
	&__layer {display:none;
		&.on {display: block;}
		width:300px; border:1px solid #cccccc;
		min-height:180px;
		position: absolute;
		z-index: 99;
		background-color:#fff;
		&__tit {
			padding:10px 10px 10px 20px;
			color:#fff; font-size:18px;
			.type-pink & {background-color:#e35ea0}
			.type-blue & {background-color:#3a74bb}
			.type-green & {background-color:#0bac4c}
			.type-red & {background-color:#cc2127}
		}
		ul {position: relative;
			padding:16px 20px; 
			li {background:url(../../resources/images/cont/jido-box-bul.png) left center no-repeat;margin-bottom:15px;
				position: relative;
				padding-left:65px;font-size:14px;
				strong {
					font-weight:400;
					position: absolute;
					top: 0;
					left: 8px;
				}
			}
		}
		&__close {
			position: absolute;
			right: 15px;
			top:16px;
			font-family: $font1;
			font-weight: bold;
			color:#fff;
			font-size:0;
		}
		ul::before {position:absolute; bottom:-20px; left:50%; margin-left:-10px; content:''; background:url(../../resources/images/cont/jido-box-img.png); width:20px; height:20px; }
	
	}
}
.main-jido__icon.type-pink {position:absolute; }
.main-jido__icon.type-blue {position:absolute;}
.main-jido__icon.type-green {position:absolute;} 
.main-jido__icon.type-red {position:absolute;}
@media screen and (min-width:1px) and (max-width:$siteSize + 80px) {
	.main-jido-btnbox {
		width:auto;
		height:auto;
		&__btn {width:150px;
			padding-top:10px;
			padding-bottom:10px;
			border-radius: 0 0 0 10px;
			span {
				&:after {margin-top:-3px;margin-left:10px;}
			}
		}
	}
	.main-jido.on {
		.main-jido__btn {width:22px;
			padding-top:0px;
			padding-bottom:0px;
			border-radius: 0 0 0 0px;
			span {
				&:after {margin-top:10px;margin-left:0px;}
			}
		}
		.main-jido__box {padding-right:0px;}
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-jido-btnbox {display:none;}
	.main-jido {display:none !important;}
}

.main-jido__layer ul li.jido_btn1, .main-jido__layer ul li.jido_btn2 {display:inline-block; margin-bottom:0; width:50%; padding-left:0; background:none; text-align:center; 
	a {display:inline-block; color:#fff; background-color:#a5a5a5; padding:5px 10px;}
}

/*팝업존*/
.popupzone {

    position: relative;
    margin-top: 0px;
    width: 100%;
	height: auto;
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {
		text-indent:-9999em;
		position: absolute;
		top:50%;
		left:0;
		z-index: 1;
		width:30px;
		height:30px;
		background-color:rgba(0,0,0,0.3);
		overflow:hidden;
		&::before{
			content:"";
			width:100%;
			height:100%;
			display:block;
			background:url('/template/resources/images/icons/prev_icon.png') no-repeat center center;
		}
	}
	.slick-next {
		text-indent:-9999em;
		position: absolute;
		top:50%;
		right:0;
		z-index: 1;
		width:30px;
		height:30px;
		background-color:rgba(0,0,0,0.3);
		overflow:hidden;
		&::before{
			content:"";
			width:100%;
			height:100%;
			display:block;
			background:url('/template/resources/images/icons/next_icon.png') no-repeat center center;
		}
		
	}
	.slick-slide a:hover,
	.slick-slide a:focus {opacity: 0.8;}

	&__tit {@include hiddenWord}
	&__item > img {width:100%}



}
.main-lay-2 .popupzone .popupzone-list__item > a:focus{border:none;}
.popupzone-nav {
	@include slickDots;
}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.popupzone .popupzone-list__item img {height:200px;width:100%;
		//object-fit:cover
	}
}

@media screen and (min-width:1px) and (max-width:900px) {

	.popupzone .popupzone-list__item img {height:170px;}
	.main-lay-2 {margin-bottom:8px;}
}
// ie11~10
@media screen and (min-width:0\0){
	.popupzone .popupzone-list__item {height:230px;overflow: hidden;}
	.popupzone .popupzone-list__item img {height:auto;}

	@media screen and (min-width:1px) and (max-width:$siteSize) {
		.popupzone .popupzone-list__item {height:230px;overflow: hidden;}
		.popupzone .popupzone-list__item img {height:230px;object-fit:cover}
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		.popupzone .popupzone-list__item {height:auto;}
		.popupzone .popupzone-list__item img {height:auto;}
	}
}
// ie9
.lte-ie9 .popupzone .popupzone-list__item {height:340px;overflow: hidden;}
.lte-ie9 .popupzone .popupzone-list__item img {height:auto;}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.lte-ie9 .popupzone .popupzone-list__item {height:300px;overflow: hidden;}
	.lte-ie9 .popupzone .popupzone-list__item img {height:300px;object-fit:cover}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.lte-ie9 .popupzone .popupzone-list__item {height:auto;overflow: hidden;}
	.lte-ie9 .popupzone .popupzone-list__item img {height:auto;}
}

/*유튜브링크*/
.youtube-box {
	&__tit {
		@include hiddenWord;
	}
	&__link {
		display: block;
		position: relative;
		img {height:100%;}
		&:before {
			position: absolute;
			top:33px;
			left:50%;
			transform: translateX(-50%);
			@include sprite($youtube-btn)
		}
		&:hover,
		&:focus {opacity: 0.8;}
	}
}


@media screen and (min-width:1px) and (max-width:768px) {
	.popupzone {max-width:600px;margin:0 auto;margin-bottom:10px;}


	.popupzone{max-width:100%;}
	.popupzone .popupzone-list__item img {width:100%;}
	.youtube-box__link img, .popupzone .popupzone-list__item img {height:260px; width:100%;}
}


@media screen and (min-width:1px) and (max-width:500px) {
	.youtube-box__link img,.popupzone .popupzone-list__item img {height:220px}

}

@media screen and (min-width:1px) and (max-width:390px) {
	.main-lay-2 {width:100%; padding-right:0; }
	.main-lay-3{ width:100%; padding-left:0;}

	.youtube-box__link img, .popupzone .popupzone-list__item img {height:auto;}
	.main-section__in__top__box {padding-bottom:10px;}

}

/* 메인 팝업(우측 상하단) 버튼 위치 변경 */
.popupzone-nav > div{
	padding:0 4px;
	box-sizing:border-box;
}
.ver-total .popupzone-nav .slick-dots-list button{
	font-size:13px;
	height:auto;
	line-height:24px;
}
.ver-total .popupzone-nav .slick-dots-list .slick-active button{
	font-size:13px;
	height:auto;
	line-height:24px;
}
.ver-total .popupzone-nav .popup-total{
	font-size:13px;
	line-height:24px;
}
.ver-total .popupzone-nav .popup-total-num{
	font-size:13px;
	line-height:24px;
}
.popupzone-nav > div > ul li:nth-child(1){
	display:none;
}
.popupzone-nav > div > ul li:nth-child(4){
	display:none;
}
.youtube-box{
	position:relative;
}