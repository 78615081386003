
/*메인슬라이드*/
.main-visual {position: relative;margin-top:0px;width:100%;height: 490px;
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {position: absolute;left:10px;z-index: 1;display:none !important;}
	.slick-next {position: absolute;right:10px;z-index: 1;display:none !important;}
}/*이미지의 상하사이즈로 설정*/

.main-visual {
	.slick-dots-wrap-outwrap{text-align:right;position: absolute;z-index: 3;top: 5px;right: 0;width: 100%;box-sizing: border-box;right:10px;}
	.slick-dots-wrap {display:inline-block;text-align:center;margin-top:0;z-index:999;background-color: rgba(0, 0, 0, 0.8);padding:2px 10px;border-radius:20px; padding-left: 16px;}
	.myslickcarousel {display:inline-block;}
	.slick-dots-on-off {display:inline-block;font-size:0;vertical-align:middle;
		li {
			display: inline-block;
			vertical-align: middle;

			a {color:gold;}
		}

	}

	*+ html .myslickcarousel {display:inline;}
	*+ html .slick-dots-on-off {display:inline;}
	*+ html .slick-dots-on-off li {display:inline;}


	.slick-dots-list {
		display:inline-block !important;
		vertical-align: middle;
		li {
			display:inline-block;
			button { background-color:#444;display:inline-block;color:gold;
				vertical-align: middle;
				overflow: visible;width:22px;height:22px;line-height:22px;border-radius:22px;font-size:0;
			}

		}

		li.slick-active button {background-color:#fff;}
		li button:focus {background-color:#fff;}
	}


	*+ html .slick-dots-list li {display:inline;}
	*+ html .slick-dots-list li button {display:inline;}

	.slick-dots-on-off img {vertical-align:middle;}

	.slide-pause-item {
		display:block;font-size:0;text-indent: -9999px;
		position:relative; 
		&:before {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:10px;
			@include sprite($visual_pause);
		}
		margin-top:0px;
		width:26px; height:26px;
		&:focus {background-color:gold;border-radius: 100%;}
	}
	.slide-play-item {
		display:block;font-size:0;text-indent: -9999px;
		position:relative; 
		&:before {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:10px;
			@include sprite($visual_play);
		}
		margin-top:0px;
		width:26px; height:26px;
		&:focus {background-color:gold;border-radius: 100%;}
	}
	.slide-next-item {
		display:block;font-size:0;text-indent: -9999px;
		position:relative; 
		&:before {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:10px;
			@include sprite($visual_next);
		}
		margin-top:0px;
		width:26px; height:26px;
		&:focus {background-color:gold;border-radius: 100%;}
	}
	.slide-prev-item {
		display:block;font-size:0;text-indent: -9999px;
		position:relative; 
		&:before {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:10px;
			@include sprite($visual_prev);
		}
		margin-top:0px;
		width:26px; height:26px;
		&:focus {background-color:gold;border-radius: 100%;}
	}

	.slick-dots-wrap-outwrap {display:block;}
	.slick-dots-wrap-outwrap.off {display:none;}


	//total ver
	.popup-total {display:none;}
}


.main-visual.ver-total {
	.slick-dots-list {font-size: 0;
		li {margin-right:0;}
		& button {font-size:15px;text-indent: 0;
			background-image:none;display:none;}
		& .slick-active button {
			background-image:none;display:block;width:auto;height:30px;text-align: center;
			color:#fff;
			background-color:transparent;
			font-weight: bold;
			&:focus {border:2px solid #fff;}
		}
	}
	.popup-total {color:#fff;display:inline-block;vertical-align: middle;font-size:15px;}
	*+ html .popup-total {display:inline;}
	.popup-total-num {padding-left:4px;}

}
//arrow ver
.main-visual.nav-arrow {display:none !important;}
.main-visual.ver-arrow {
		.nav-arrow {display:block !important;}
		.slide-play-item {display:none;}
		.popupzone-nav__prev {}
		.popupzone-nav__next {}
}


*+ html .ver-total .main-slide .popup-total {display:none !important;}
*+ html .ver-total .main-slide .slick-dots-list {display:none !important;}


@media screen and (min-width:1px) and (max-width:$siteSize) {
	.main-visual {width:100%;padding-top:0;height: auto;}
	.main-visual {margin-top:0;}
	.main-visual .slick-arrow {top:150px;}
}
@media screen and (min-width:900px) and (max-width:$siteSize) {
	.main-slide__item > img {width:895px}
}
@media screen and (min-width:769px) and (max-width:900px) {
	.main-slide__item > img {width:800px}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-slide__item > img {width:100%}
	.main-visual .slick-arrow {top:70px;}
}
