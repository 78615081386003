
/*탭*/
.js-box {display:none;position:relative;min-height: 150px;}
.js-box.on {display:block;}

/*메인탭*/
.main-tab {
	&__ul {
		padding-left: 10px;
		&__li {
			float:left;
			a {
				height: 56px;
				line-height: 56px;
				display:block;
				font-size: 17px;font-size: 1.7rem;
				text-align: center;
				padding-left: 10px;
				padding-right: 10px;
				color: #ddd;
				opacity: 0.7;
			}
			&.on a{
				color: #333;
				font-weight: 500;
				opacity: 1;
			}
			a:hover,
			a:focus {
				text-decoration: underline;
			}
		}
	}

	&__cont {
		&__ul {
			border: 1px solid #e0e0e0;
			&__li {
				border-top: 1px dotted #ccc;
			}
		}

	}
}


.main-tab__cont__ul__li {border-top:1px dotted #ccc;}
.main-tab__cont__ul__li:first-child {border-top:0;}
.main-tab__cont__ul__li a {display:block;position: relative;padding-left:10px;padding-right: 10px;}
.main-tab__cont__ul__li a:hover,
.main-tab__cont__ul__li a:focus {background-color:#eee}

.main-tab__cont__more {position:absolute;right:18px;top:-42px;display:block;width:26px;height:26px;padding:2px;}
.main-tab__cont__more:focus {border:2px solid gold;padding:0;}
.mtcul-cont {white-space: nowrap;overflow:hidden;display: block;text-overflow: ellipsis; width:70%; padding-top: 10px;padding-bottom:10px;color:#000;}
.mtcul-date {display:block;position: absolute;top:10px;right:10px;}

@media screen and (min-width:1px) and (max-width:768px) {
	.mtcul-cont {width:auto;}
	.mtcul-date {display: none;}
}

/*서브탭*/
.sub-tab__ul:after {content:' ';clear:both;visibility: hidden;display: block;}
	*+ html .sub-tab__ul {display:inline-block;}
.sub-tab__ul__li {float:left; display:table; margin-bottom:34px;}
.sub-tab__ul__li__a {border:1px solid #e7e7e7; box-sizing:border-box; border-right:0; padding:0 10px; display:table-cell;vertical-align: middle ;text-align:center; font-size:15px;font-weight: 400;background-color:#ffffff;height:48px;}
.sub-tab__ul__li:last-child .sub-tab__ul__li__a {border-right:1px solid #e7e7e7;}
.sub-tab__ul__li.on .sub-tab__ul__li__a {background-color:#275bb6; color:#fff;}

/*서브탭2*/
.sub-tab2__ul:after {content:' ';clear:both;visibility: hidden;display: block;}
	*+ html .sub-tab__ul {display:inline-block;}
.sub-tab2__ul__li {float:left; display:table; margin-bottom:34px;}
.sub-tab2__ul__li__a {color:#fff; box-sizing:border-box; border-right:0; padding:0 10px; display:table-cell;vertical-align: middle
	;text-align:center;font-size:15px;font-weight: 400;background-color:#878e96;height:55px; border:1.5px solid #fff; font-size:16px;}
.sub-tab2__ul__li:last-child .sub-tab2__ul__li__a {border-right:1px solid #e7e7e7;}
.sub-tab2__ul__li.on .sub-tab2__ul__li__a {background-color:#1260ab; color:#fff;}

/* 서브탭3 링크연결용 */
.sub-tab3__ul{margin-bottom:34px;}
.sub-tab3__ul::after{content:""; display:block; clear:both;}
.sub-tab3__ul__li{width:25%; box-sizing:border-box; float:left;}
.sub-tab2__ul__li:nth-child(4n){border-right:1px solid #e7e7e7;}
.sub-tab3__ul__li a{color:#fff; box-sizing:border-box; border-right:0; padding:0 10px; display:block; text-align:center; font-size:15px; font-weight:400; line-height:55px; background-color:#878e96; height:55px; border:1.5px solid #fff; font-size:16px; transition:0.3s all;}
.sub-tab3__ul__li.on a, .sub-tab3__ul__li:hover a{background-color:#1260ab; color:#fff;}