
/*sub-page-container*/
.sub-page-container {
	font-family: $font2;
}
.sub-page-container__in {width:$siteSize;margin:0 auto;position: relative;background-color:#fff;
	@include floatWrap;padding-top:30px;
}
.sub-contents {float:right;width:900px;	
	a:focus {border:2px solid gold}
}


.cont-in {padding-bottom:40px;min-height:500px;}

.cont-in img {
	max-width: 100%;
}

@media screen and (min-width:1px) and (max-width:$siteSize) {

	.sub-page-container__in{width:auto;padding-top:0;}
	.sub-contents {width:auto;float:none; padding:10px; box-sizing: border-box;}
	.cont-in img {
		height: auto
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.sub-page-container {padding-top:51px;}
}
.sub-contents__top {position:relative;border-bottom:1px solid #e0e0e0;font-family: 'Noto Sans KR';
	/*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
	background-position:right top;
	background-repeat:no-repeat;
	margin-bottom:40px;
	}

.sub-contents__top h2.sub-tit {font-size:38px;color:#222;padding-top:10px;margin-bottom:0;height:60px;font-weight: 500;}

/* location */
.location-box {position:relative;margin-bottom:20px;}
.location-box__cont {

		span {display:inline-block;font-size:15px;font-size:1.5rem;height:28px;line-height:28px;vertical-align:middle; 

			&:before {
				content:'';
				display:inline-block;font-size:0;
				@include sprite($location-icon);
				margin:0 10px;padding-top: 2px;
				@media screen and (min-width:1px) and (max-width:480px) {
					& {margin:0 10px;}
				}
			}

			color:#6d6d6d;
			&:first-child::before {
				display:none;
			}
		}
	}
.location-box__cont .loc-home {display:block;padding-left:15px;font-size:0;font-size:0;height:28px;line-height:27px;text-indent: -9999px;
	background-image:url("../../resources/images/icons/home.png");
	background-position:0 center;
	background-repeat:no-repeat;
	color:#666;
	}

/* 프린트, url 버튼 등*/
.special-box {
	position: absolute;
	top:10px;right:0;
	a {
		border:1px solid #fff;
		border-radius: 100%;
		&:focus {border:1px solid gold;}
	}

	&__print {
		@include sprite($icon-print);
		margin-right:4px;
		text-indent: -9999px;
	}
	&__url {
		@include sprite($icon-url);
		text-indent: -9999px;
	}
}

@media screen and (min-width:769px) and (max-width:$siteSize) {
	.location-box__cont {position:relative;top:3px;width:auto;text-align:right ;}
	.sub-contents__top h2.sub-tit {padding-top:0px;}
	.special-box {top:0;}

}
@media screen and (min-width:1px) and (max-width:768px) {
		.sub-contents__top {background-image:none !important;margin-bottom:30px;}
		.sub-contents__top h2.sub-tit {font-size:21px;margin-bottom:8px;padding-top:10px;height:auto;}
		.location-box {position:relative;top:0;right:0;padding-bottom:0px;}
		.location-box__cont {position:relative;white-space:normal;width:auto;text-align: left;overflow:visible;top:0;left:0;padding-top:0px;}
		.special-box {display:none;}
}


/* checkbox */

input[class="check-type"] + label {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid #cccccc;
	background-color:#dddddd;
	cursor: pointer;
	border-radius: 30px;
	position: relative;

	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:10px;left:10px;
	}
}

input[class="check-type"]:checked + label {
	background-color: #666666;
}

input[class="check-type"]:focus + label {
	border: 2px solid gold;
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}


/* 2 */
input[class="check-type2"] + label {
	display: inline-block;
	width: 12px;
	height: 12px;
	cursor: pointer;
	position: relative;
	border:1px solid #ddd;
}

input[class="check-type2"]:checked + label {
	&:before {
		content:' ';
		@include sprite($check-type2-on);
		position: absolute;
		top:0px;left:0px;
	}
}

input[class="check-type2"]:focus + label {
	border:1px solid #bbbbbb;
}
input[class="check-type2"] {
	position: absolute;
	left: -9999px;
}



/* 3 */
input[class="check-type3"] + label {
	display: inline-block;
	width: 18px;
	height: 18px;
	cursor: pointer;
	position: relative;
	border:1px solid #ddd;
}

input[class="check-type3"]:checked + label {
	&:before {
		content:' ';
		@include sprite($check-type3-on);
		position: absolute;
		top:3px;left:3px;
	}
}

input[class="check-type3"]:focus + label {
	border:1px solid #ec6159;
}
input[class="check-type3"] {
	position: absolute;
	left: -9999px;
}

/*라디오버튼*/
input[class="radio-type"] + label {
	display: inline-block;
	width: 17px;
	height: 17px;
	border: 0;
	cursor: pointer;
	position: relative;
	font-size: 0;
	text-indent: -9999px;
	&:before {
		content:' ';
		@include sprite($radio-type-off);
		position: absolute;
		top:3px;left:4px;
	}

}

input[class="radio-type"]:checked + label {
	&:before {
		@include sprite($radio-type-on);
	}
}

input[class="radio-type"]:focus + label {}
input[class="radio-type"] {
	position: absolute;
	left: -9999px;
}
.choose-label-text {
	margin-left:0;
	color:#7d7d7d;
	font-weight:bold;
	font-size:16px;
	padding:5px;
}
.choose-textarea {
	display: block;
	box-sizing: border-box;
	border:1px solid #d5d9dd;
	background-color: #e9ecf0;
	padding:20px;
	height: 100px;
	font-size:16px;
	margin-top:10px;
	width:100%;
	color:#768393;
	&.long {height:150px;}
}

// 동영상 게시물 상세에서 아이프레임 있을경우
.editor_view.on {
	& .editor_view__cont {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%; /*16:9*/
		background-color:#00235d;
		color:#fff;
		> p {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			iframe { width: 100%; height: 100%}
		}
	}
}

//test

.sub-slide {
	position: relative;
	margin-bottom: 10px;
	&__box {
		max-width:100%;
		margin:0 auto;
		img {width:100%}
	}

	&__nav {
		position: absolute;

		width:50px;
		display: block;
		top:50%;
		margin-top:-35px;
		height:70px;
		background-color:#000;
		opacity: 0.8;
		text-indent: -9999px;
		&:after {
			width:20px;height:27px;
			display:block;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:20px;
			color:#fff;
			text-indent: 0;
			font-size:22px;
			font-family: $font1;
		}
		&:hover,
		&:focus {
			&:after {color:gold}
		}

	}
	&__prev {
		left:0;
		&:after {
			content:'<';
		}
	}
	&__next {
		right: 0;
		&:after {
			content:'>';
		}
	}
}
.sub-slide-cro {
	margin-bottom:40px;
	margin-left:-10px;
	overflow: hidden;
	img {
		height:100px;
		width:100%;
		padding-left:10px;
		box-sizing: border-box;
		opacity: 0.4;
		transition: all 0.3s ease 0s;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			height:100px !important;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			height:55px !important;
		}
	}
	.slick-current  {
		img {opacity: 1;}
	}
	a:focus {
		img {opacity: 1;}
	}
}
 
/*서브 하단 담당자 정보*/
.sub-info {display:inline-block; margin-top:80px; box-sizing:border-box;width:100%;padding:20px 0 20px 80px; border:1px solid #e0e0e0; border-top:1px solid #222; background:url(../../resources/images/icons/sub-info-img1.png) 30px center no-repeat;
	li{float:left; font-size:15px;
		dt {float:left; color:#275bb6; font-weight:500; padding-right:9px; 
			&:before {@include sprite($sub-info-img2);padding-right:6px;}
		}
		dd {float:left; color:#484848; padding-right:50px;}
	}
	
	@media screen and (min-width:1px) and (max-width:768px) {
		padding:10px 0 10px 80px;
	}
}


/*통합검색 페이지*/

.search_zone {
	.sub-contents__top {border-bottom:0;}
	&__box {border-top:2px solid #282828; 
		> li > a {padding:24px 0; display:inline-block; font-size:16px; color:#484848; border-bottom:1px solid #e8e7e7; width:100%;
			ul {margin-top:5px;
				> li {float:left; 
					&::after {margin-left:10px; margin-right:10px;@include sprite($search_zone-img1);    vertical-align: baseline;}
					&:last-child::after {display:none;}
				}
			}
		}
		
	}
	&__btn {margin-bottom:55px; background-color:#275bb6; text-align:center; color:#fff; font-weight:500; 
		padding:10px 30px; font-size:16px; display:inline-block; margin-top:20px;transition:all 0.4s;
		&:hover {background-color:#163874}
	}
		
	@media screen and (min-width:1px) and (max-width:768px) {
		padding:10px ;
		
	}
}

/* pagination */
.pagination {margin-top:20px; font-size:0; text-align: center;}
.pagination a {display: inline-block; width: 34px; height: 34px; line-height: 34px; margin:0 1px 3px; font-size:18px;font-weight:bold;
text-align:center; vertical-align: middle; -webkit-transition:all 0.3s; -moz-transition:all 0.3s; transition:all 0.3s;}
.pagination .page_arrow {font-size:18px;}
.pagination a:hover,  .pagination .active {color:#c26aa8;text-decoration:underline;}
.pagination a:focus {color:#c26aa8}
.pagination .page_arrow.l1 {margin-right:40px;}
.pagination .page_arrow.r1 {margin-left:40px;}
@media screen and (min-width:1px) and (max-width:768px) {
	/* pagination */
	.pagination a {width: 22px; height: 22px; line-height: 22px; font-size:13px;}
	.pagination .page_arrow {font-size:15px;}
	.pagination .page_arrow.l1 {margin-right:20px;}
	.pagination .page_arrow.r1 {margin-left:20px;}
}

/*탭 타입*/
.tabType1 {margin-bottom:30px; 
	.sub-tab2__ul__li {margin-bottom:0;width:25%; }
	@media screen and (min-width:1px) and (max-width:860px) {
		.sub-tab2__ul__li {width:50%; height: 51px;
			word-break: break-all;	box-sizing: border-box;
			a {font-size:15px;}
		}
	
	}

}