@charset "UTF-8";
/*게시판*/
/*텍스트 박스*/
/*숫자 리스트*/
.icon-all-menu-btn-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -113px -441px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
}

.icon-all-menu-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -197px -441px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
}

.icon-all_menu_close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -365px -441px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-bbs-more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -312px -537px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 26px;
}

.icon-call-center-img1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -67px -615px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 17px;
}

.icon-call-center-img2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -425px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 17px;
}

.icon-check-type {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -182px -682px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
}

.icon-check-type2-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -260px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-check-type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -322px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-check-type3-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -570px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
}

.icon-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -145px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-confirm {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -228px -314px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
}

.icon-copy_li_bg {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -748px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.icon-copy_li_bg2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -472px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-down-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -64px -682px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.icon-down-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -123px -682px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.icon-fb-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -281px -441px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
}

.icon-foot_img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -212px;
  background-image: url("../images/sprite.png");
  width: 246px;
  min-width: 246px;
  height: 52px;
}

.icon-footer-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -314px -249px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 8px;
}

.icon-h3-type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -215px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h3-type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -384px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-h4-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -105px -748px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-home {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -682px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 16px;
}

.icon-ico-roll-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -445px -441px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -537px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -78px -537px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -156px -537px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-start {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -388px -537px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.icon-icon-print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -98px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.icon-icon-url {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -188px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.icon-info-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px 0px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
}

.icon-jido-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -285px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-jido-more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -355px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-join-icon-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -127px -314px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
}

.icon-join-icon-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -314px 0px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
}

.icon-join-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -631px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.icon-join-step-now {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -71px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-jojik-item-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -120px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 9px;
}

.icon-list-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -395px -615px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 1px;
}

.icon-list-bul2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -244px -682px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.icon-location-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -51px -748px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
}

.icon-login-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -851px 0px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-menu_open {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -327px -314px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-menu_search {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -452px 0px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-mobile-bul-minus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -197px -615px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 2px;
}

.icon-mobile-bul-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -234px -537px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-mobile-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -452px -106px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-nav-3rd-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -559px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.icon-notice_down_icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -492px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 17px;
}

.icon-open-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -179px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.icon-open-done {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -337px -615px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
}

.icon-radio-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -446px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -508px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-type-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -134px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-radio-type-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -197px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-sch_result {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px 0px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 10px;
}

.icon-search-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -452px -212px;
  background-image: url("../images/sprite.png");
  width: 50px;
  min-width: 50px;
  height: 50px;
}

.icon-search_zone-img1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -60px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 10px;
}

.icon-second-menu {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -158px -748px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -652px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.icon-select_arrow {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -387px -249px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 8px;
}

.icon-side-bul-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -532px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-side-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -592px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-side-list-inbox {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -211px -748px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-side-list-inbox_on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -355px -682px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.icon-side-list-li-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -237px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.icon-side-list-li-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -275px -615px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 2px;
}

.icon-step-final {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -441px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
}

.icon-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -278px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
}

.icon-step-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -361px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
}

.icon-sub-info-img1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -444px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 33px;
}

.icon-sub-info-img2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -264px -748px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-sub-nav_3rd_bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -409px -682px;
  background-image: url("../images/sprite.png");
  width: 2px;
  min-width: 2px;
  height: 2px;
}

.icon-thrd-menu {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -300px -682px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.icon-title {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-image: url("../images/sprite.png");
  width: 264px;
  min-width: 264px;
  height: 58px;
}

.icon-title2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -108px;
  background-image: url("../images/sprite.png");
  width: 262px;
  min-width: 262px;
  height: 54px;
}

.icon-top-banner-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px 0px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.icon-top-banner-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -452px -312px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.icon-top-banner-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px 0px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.icon-top_banner_pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -73px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.icon-total-count-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -851px -60px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-visual_next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -354px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.icon-visual_pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -295px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 9px;
}

.icon-visual_pause2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -464px -537px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 24px;
}

.icon-visual_play {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -539px -537px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 24px;
}

.icon-visual_play2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -615px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -413px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.icon-years-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -132px -615px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.icon-youtube-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -314px -122px;
  background-image: url("../images/sprite.png");
  width: 77px;
  min-width: 77px;
  height: 77px;
}

.icon-youtube-play {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -314px;
  background-image: url("../images/sprite.png");
  width: 77px;
  min-width: 77px;
  height: 77px;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  word-break: keep-all;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: #ff0;
  font-style: italic;
}

pre,
code,
kbd,
samp {
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  _font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

p {
  margin: 0;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

dl,
dt,
dd {
  margin: 0;
}

img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  display: none;
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

label {
  cursor: pointer;
}

button {
  padding: 0;
  outline: 0;
  border: none;
  background: transparent;
}

button,
input,
select,
textarea {
  font-size: 100%;
  font-family: inherit;
  margin: 0;
  /*vertical-align: baseline;*/
  vertical-align: middle;
  border-radius: 0;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

table button,
table input {
  *overflow: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  width: 100%;
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  border-color: #C82E2D;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

address,
em,
i {
  font-style: normal;
}

caption {
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  font-size: 0;
}

/*
-----------*/
.in_length30 {
  width: 30px;
}

.in_length40 {
  width: 40px;
}

.in_length50 {
  width: 50px;
}

.in_length60 {
  width: 60px;
}

.in_length70 {
  width: 70px;
}

.in_length80 {
  width: 80px;
}

.in_length90 {
  width: 90px;
}

.in_length100 {
  width: 100px;
}

.in_length110 {
  width: 110px;
}

.in_length120 {
  width: 120px;
}

.in_length130 {
  width: 130px;
}

.in_length140 {
  width: 140px;
}

.in_length150 {
  width: 150px;
}

.in_length160 {
  width: 160px;
}

.in_length170 {
  width: 170px;
}

.in_length180 {
  width: 180px;
}

.in_length190 {
  width: 190px;
}

.in_length200 {
  width: 200px;
}

.in_length250 {
  width: 250px;
}

.in_length300 {
  width: 300px;
}

.in_length350 {
  width: 350px;
}

.in_length400 {
  width: 400px;
}

.in_length450 {
  width: 450px;
}

.in_length500 {
  width: 500px;
}

.in_length600 {
  width: 600px;
}

.in_length98pro {
  width: 98%;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .float_wrap {
  display: inline-block;
}

* html .float_wrap {
  height: 1%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.align_right {
  text-align: right;
}

.align_left {
  text-align: left !important;
}

.align_center {
  text-align: center !important;
}

.hidden_word {
  position: absolute;
  top: 0;
  left: -9999px;
  text-indent: -9999px;
  display: block;
}

.mgn_b5 {
  margin-bottom: 5px;
}

.mgn_b8 {
  margin-bottom: 8px;
}

.mgn_b10 {
  margin-bottom: 10px;
}

.mgn_b15 {
  margin-bottom: 15px;
}

.mgn_b18 {
  margin-bottom: 18px;
}

.mgn_b20 {
  margin-bottom: 20px;
}

.mgn_b25 {
  margin-bottom: 25px;
}

.mgn_b30 {
  margin-bottom: 30px;
}

.mgn_b40 {
  margin-bottom: 40px;
}

.mgn_b50 {
  margin-bottom: 50px;
}

.mgn_t5 {
  margin-top: 5px;
}

.mgn_t8 {
  margin-top: 8px;
}

.mgn_t10 {
  margin-top: 10px;
}

.mgn_t15 {
  margin-top: 15px;
}

.mgn_t18 {
  margin-top: 18px;
}

.mgn_t20 {
  margin-top: 20px;
}

.mgn_t25 {
  margin-top: 25px;
}

.mgn_t30 {
  margin-top: 30px;
}

.mgn_t40 {
  margin-top: 40px;
}

.mgn_t50 {
  margin-top: 50px;
}

.mgn_t70 {
  margin-top: 70px;
}

.mgn_t80 {
  margin-top: 80px;
}

.mgn_t105 {
  margin-top: 105px;
}

.mgn_ml20 {
  margin-left: -20px;
}

.mgn_ml10 {
  margin-left: -10px;
}

.mgn_l10 {
  margin-left: 10px;
}

.mgn_l15 {
  margin-left: 15px;
}

.mgn_l20 {
  margin-left: 20px;
}

.mgn_l30 {
  margin-left: 30px;
}

.mgn_r10 {
  margin-right: 10px;
}

.mgn_r20 {
  margin-right: 20px;
}

.mgn_r30 {
  margin-right: 30px;
}

.mgn_r40 {
  margin-right: 40px;
}

.left_10 {
  padding-left: 10px;
}

.left_15 {
  padding-left: 15px;
}

.left_20 {
  padding-left: 20px;
}

.left_30 {
  padding-left: 30px;
}

.left_40 {
  padding-left: 40px;
}

.left_50 {
  padding-left: 50px;
}

.left_100 {
  padding-left: 100px;
}

.left_150 {
  padding-left: 150px;
}

.right_10 {
  padding-right: 10px;
}

.right_20 {
  padding-right: 20px;
}

.right_30 {
  padding-right: 30px;
}

.right_50 {
  padding-right: 50px;
}

.right_100 {
  padding-right: 100px;
}

.right_150 {
  padding-right: 150px;
}

.col_brown {
  color: #996630;
}

.line_14 {
  line-height: 1.4;
}

.line_16 {
  line-height: 1.6;
}

.line_18 {
  line-height: 1.8;
}

.col_red {
  color: #d6046d;
}

.col_red2 {
  color: #e20000;
}

.col_gold {
  color: #CD883D;
}

.col_black {
  color: #000000;
}

.col_black2 {
  color: #484848;
}

.col_org {
  color: #f05914;
}

.col_gray {
  color: #818283;
}

.col_black {
  color: #000;
}

.col_pul {
  color: #3c5e80;
}

.col_blue {
  color: #21539b;
}

.col_blue2 {
  color: #275bb6;
}

.col_gold {
  color: #999900;
}

.col_yellow {
  color: #fff585;
}

.col_pink {
  color: #e3006a;
}

.font_bold {
  font-weight: 500;
}

.font_normal {
  font-weight: 400;
}

.font_11 {
  font-size: 11px;
  font-size: 1.1rem;
}

.font_12 {
  font-size: 12px;
  font-size: 1.2rem;
}

.font_13 {
  font-size: 13px;
  font-size: 1.3rem;
}

.font_14 {
  font-size: 14px;
  font-size: 1.4rem;
}

.font_15 {
  font-size: 15px;
  font-size: 1.5rem;
}

.font_16 {
  font-size: 16px;
  font-size: 1.6rem;
}

.font_17 {
  font-size: 17px;
  font-size: 1.7rem;
}

.font_18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.font_20 {
  font-size: 20px;
  font-size: 2.0rem;
}

.font_22 {
  font-size: 22px;
  font-size: 2.2rem;
}

.chk_box {
  height: 13px;
  margin: 0;
}

.chk_box, label {
  vertical-align: middle;
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot);
  src: local("NanumGothic"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot);
  src: local("NanumGothicBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 800;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot);
  src: local("NanumGothicExtraBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.ttf) format("truetype");
}

/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format("opentype");
}

html {
  font-size: 62.5%;
}

html.websize-1 {
  font-size: 62.5%;
}

html.websize-2 {
  font-size: 68.5%;
}

html.websize-3 {
  font-size: 72.5%;
}

html.websize-4 {
  font-size: 78.5%;
}

html.websize-5 {
  font-size: 82.5%;
}

html.websize-6 {
  font-size: 88.5%;
}

html.websize-7 {
  font-size: 93.5%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float_wrap {
  display: inline-block;
}

/**/
.size-realtime {
  display: none;
  font-size: 12px;
  padding-left: 0 !important;
}

html.websize-1 .size-realtime.s1 {
  display: inline-block;
}

html.websize-2 .size-realtime.s2 {
  display: inline-block;
}

html.websize-3 .size-realtime.s3 {
  display: inline-block;
}

html.websize-4 .size-realtime.s4 {
  display: inline-block;
}

html.websize-5 .size-realtime.s5 {
  display: inline-block;
}

html.websize-6 .size-realtime.s6 {
  display: inline-block;
}

html.websize-7 .size-realtime.s7 {
  display: inline-block;
}

.board_view .editor_view pre {
  line-height: 1;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
}

.board_view .editor_view pre p {
  line-height: 1.2;
}

.mb_none {
  display: block;
}

.pc_none {
  display: none;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .mb_none {
    display: none;
  }
  .pc_none {
    display: block;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**/
html body {
  font-size: 14px;
  font-size: 1.4rem;
}

/*사이즈 확인*/
.resol {
  position: fixed;
  bottom: 0;
  right: 0;
  border: 3px solid red;
  background-color: #fff;
  padding: 5px;
}

/*숫자클래스 삽입*/
.menu-num-2 > ul > li {
  width: 50%;
}

.menu-num-3 > ul > li {
  width: 33.33%;
}

* + html .menu-num-3 > ul > li {
  width: 33%;
}

.menu-num-4 > ul > li {
  width: 25%;
}

.menu-num-5 > ul > li {
  width: 20%;
}

.menu-num-6 > ul > li {
  width: 16.66%;
}

* + html .menu-num-6 > ul > li {
  width: 16%;
}

.menu-num-7 > ul > li {
  width: 14%;
}

.menu-num-8 > ul > li {
  width: 12.5%;
}

.menu-num-9 > ul > li {
  width: 11.20%;
}

* + html .menu-num-9 > ul > li {
  width: 11%;
}

.menu-num-10 > ul > li {
  width: 10%;
}

.menu-num-11 > ul > li {
  width: 9.09%;
}

* + html .menu-num-11 > ul > li {
  width: 9%;
}

.menu-num-12 > ul > li {
  width: 8.3%;
}

* + html .menu-num-12 > ul > li {
  width: 8%;
}

.menu-num-13 > ul > li {
  width: 7.5%;
}

* + html .menu-num-13 > ul > li {
  width: 7%;
}

/*유동적 가로세로 비율 박스*/
#movie-player-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

#movie-player-wrapper .mov-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#movie-player-wrapper iframe {
  width: 100%;
  height: 100%;
}

/*표 반응형*/
.test-table {
  margin-bottom: 30px;
}

.test-table table {
  border-collapse: collapse;
  width: 100%;
}

.test-table th {
  border: 1px solid #777;
  padding: 5px;
}

.test-table td {
  border: 1px solid #777;
  padding: 5px;
}

@media (max-width: 800px) {
  .test-table th {
    display: none;
  }
  .test-table td {
    display: block;
    margin-bottom: 10px;
  }
  .test-table td:before {
    color: blue;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
}

.show-list__top {
  position: relative;
  min-height: 120px;
  margin-bottom: 50px;
  background-color: #f7f7f7;
}

.show-list__top:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .show-list__top {
  display: inline-block;
}

.show-list__top__info {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.show-list__top__info strong {
  color: #d6046d;
}

.show-list__top__search {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.show-list__top__search__selectbox {
  margin-right: 30px;
}

.show-list__top__search__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.show-list__top__search__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -652px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.show-list__top__search__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 200px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.show-list__top__search__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .show-list__top__search__selectbox select {
  width: 125%;
  vertical-align: top;
}

.show-list__top__search__selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__selectbox select {
    min-width: 170px;
  }
}

.show-list__top__search__selectbox label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.show-list__top__search__inputbox {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.show-list__top__search__inputbox input {
  width: 300px;
  border: 1px solid #000;
  height: 38px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
}

.show-list__top__search__inputbox a {
  font-size: 0;
  text-indent: -9999px;
  background: url(/template/resources/images/icons/search-btn.png) center center no-repeat;
  height: 36px;
  width: 50px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}

.show-list__top__search__inputbox a:focus {
  border: 2px solid gold;
}

.show-list__top.ver2 {
  background-color: #fff;
}

.ver2 .show-list__top__info {
  left: 0;
}

.ver2 .show-list__top__search {
  right: 0;
}

.ver2 .show-list__top__search .show-list__top__search__selectbox {
  border: 1px solid #dbdbdb;
}

.ver2 .show-list__top__search input {
  border: 1px solid #dbdbdb;
}

/* show list top */
@media screen and (min-width: 1px) and (max-width: 1200px) {
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #000;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -792px -652px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    background-color: transparent;
    appearance: none;
    min-width: 130px;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    height: 38px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    position: relative;
    border: 0px;
    /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 125%;
    vertical-align: top;
  }
  .show-list__top__search__selectbox select option {
    font-family: "Nanum Gothic";
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__selectbox select {
    min-width: 100px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 200px;
    border: 1px solid #000;
    height: 38px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
  }
  .show-list__top__search__inputbox a {
    font-size: 0;
    text-indent: -9999px;
    background: url(/template/resources/images/icons/search-btn.png) center center no-repeat;
    height: 36px;
    width: 50px;
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
  }
  .show-list__top__search__inputbox a:focus {
    border: 2px solid gold;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top {
    padding: 20px;
  }
  .show-list__top__info {
    transform: translateY(0);
    top: 0;
    left: 0;
    position: relative;
    margin-bottom: 10px;
  }
  .show-list__top__search {
    transform: translateY(0);
    top: 0;
    right: 0;
    position: relative;
  }
  .show-list__top__search__selectbox {
    margin-bottom: 10px;
  }
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #000;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -792px -652px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    background-color: transparent;
    appearance: none;
    min-width: 200px;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    height: 38px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    position: relative;
    border: 0px;
    /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 125%;
    vertical-align: top;
  }
  .show-list__top__search__selectbox select option {
    font-family: "Nanum Gothic";
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__selectbox select {
    min-width: 170px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top__search__inputbox {
    margin-bottom: 10px;
  }
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 280px;
    border: 1px solid #000;
    height: 38px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
  }
  .show-list__top__search__inputbox a {
    font-size: 0;
    text-indent: -9999px;
    background: url(/template/resources/images/icons/search-btn.png) center center no-repeat;
    height: 36px;
    width: 50px;
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
  }
  .show-list__top__search__inputbox a:focus {
    border: 2px solid gold;
  }
  .show-list__top.ver2 {
    margin-bottom: 0px;
  }
}

/*skip navigation*/
.skiptoContent {
  position: fixed;
  left: 50%;
  top: -30px;
  height: 30px;
  line-height: 30px;
  background-color: #00235d;
  width: 540px;
  margin-left: -270px;
  text-align: center;
  transition: all 0.3s ease 0s;
  z-index: 9999;
}

.skiptoContent.on {
  top: 0;
}

.skiptoContent strong {
  font-size: 0;
}

.skiptoContent a {
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.skiptoContent a:focus {
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .skiptoContent {
    display: none;
  }
}

/*ul 사이즈 설정필수*/
/*컬러설정*/
/*header*/
.header {
  height: 143px;
  position: relative;
  z-index: 99;
  background-image: url("../../resources/images/basic/bg.png");
  background-repeat: repeat-x;
  background-position: center top;
  /*헤더 위 링크들*/
  /*header search*/
  /*
	&__search {position:absolute;top:0px;left:50%;margin-left:-70px;}
	&__search__in {border:1px solid #333;background-color:#fff;}
	&__search-in_box {border:0px;padding:0;height:32px;width:150px;line-height: 32px; vertical-align: top;}
    */
}

.header__links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  background-color: #eee;
  height: 0px;
  /*font-size*/
}

.header__links__1 {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  top: 7px;
  text-align: right;
  white-space: nowrap;
  font-size: 0;
  box-sizing: border-box;
  padding-right: 205px;
}

header.add-search-btn .header__links__1 {
  padding-right: 0px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__1 {
    padding-right: 0px;
  }
}

.header__links__1 > a {
  padding-left: 20px;
  font-size: 14px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__1 > a {
    color: #000;
  }
}

.header__links__1 > a:focus {
  text-decoration: underline;
}

.add-search-btn .header__links__1 {
  right: 5px;
}

.header__links__font-size-box {
  font-size: 13px;
}

.header__links__font-size-box h3 {
  display: inline;
  margin-left: 10px;
  font-size: 13px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .header__links__1 {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__font-size-box {
    display: none;
  }
}

.header__wrap {
  position: relative;
}

.header__wrap__h1 {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 10000;
}

.header__wrap__h1 h1 {
  position: absolute;
  left: 0px;
  top: 25px;
}

.header__wrap__h1 h1 a {
  display: block;
  text-align: center;
  font-size: 0;
}

.header__wrap__h1 h1 a:focus {
  border: 1px solid gold;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .header__links {
    width: 100%;
  }
  .header__wrap__h1 {
    width: auto;
  }
  .header__wrap__h1 h1 {
    left: 5px;
  }
  .header__wrap__h1 h1 a img {
    max-height: 45px;
  }
}

.header__search {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

.header__search__in {
  position: absolute;
  top: 34px;
  right: 39%;
  border: 1px solid #c7c7c7;
  background-color: #fff;
  border-radius: 50px;
  height: 40px;
}

.header__search-in_box {
  border: 0px;
  padding: 0;
  padding-left: 20px;
  height: 41px;
  width: 185px;
  line-height: 41px;
  vertical-align: top;
  background: transparent;
}

.header__search input[type="image"] {
  margin-top: 3px;
  margin-right: 8px;
}

.header-search-btn {
  display: none;
}

.add-search-btn .header__search__in {
  right: 50%;
  margin-right: -70px;
}

.add-search-btn .header__search {
  display: none;
}

.add-search-btn .header__search.on {
  display: block;
}

.add-search-btn .header-search-btn {
  display: inline-block;
}

* + html .add-search-btn .header-search-btn {
  display: inline;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header__search {
    width: auto;
  }
  .header__search__in {
    right: 35%;
    top: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search {
    display: none;
  }
  .header__search__in {
    top: 55px;
    right: 10px;
  }
  .add-search-btn .header__search__in {
    right: 0;
    margin-right: 0;
  }
  .header__search.on {
    display: block;
    width: auto;
  }
  .header-search-btn {
    display: none !important;
  }
}

/*gnb*/
.gnb {
  width: 100%;
  position: absolute;
  left: 0;
  top: 84px;
  background-color: #fff;
  background-repeat: repeat-x;
  background-position: center top;
  box-shadow: 0px 7px 11px -13px rgba(0, 0, 0, 0.75);
  /*한개씩 떨어짐*/
}

.gnb .gnb-ul {
  display: table;
  width: 1200px;
  padding-left: 0px;
  margin: 0 auto;
}

.gnb .gnb-menu {
  display: table-cell;
  position: relative;
}

.gnb .gnb-menu > a {
  display: block;
  text-align: center;
  white-space: nowrap;
  color: #000;
  height: 59px;
  line-height: 59px;
  text-decoration: none;
  font-size: 18px;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  font-weight: 500;
}

.gnb .gnb-menu.over > a,
.gnb .gnb-menu.focus > a,
.gnb .gnb-menu > a:hover,
.gnb .gnb-menu > a:focus {
  color: #fff;
}

.gnb .sub-nav {
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 59px;
  left: 0;
  width: 100%;
  z-index: 98;
  background-color: #fff;
  padding-top: 0;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.gnb .gnb-menu.focus .sub-nav,
.gnb .gnb-menu.over .sub-nav,
.gnb.selected .gnb-menu.focus .sub-nav {
  visibility: visible;
  height: auto;
  opacity: 1;
}

.gnb .sub-nav__box {
  border: 2px solid #00235d;
  border-top: 0px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s ease 0s;
}

.gnb .sub-nav__box__list > a {
  display: block;
  padding: 8px 0 8px 10px;
  color: #666;
  font-size: 14px;
  font-size: 1.4rem;
}

.gnb .sub-nav__box__list > a:hover,
.gnb .sub-nav__box__list > a:focus {
  text-decoration: underline;
  color: #275bb6;
}

.gnb .gnb-menu.over .sub-nav__box,
.gnb.selected .gnb-menu.focus .sub-nav__box {
  padding-bottom: 20px;
  padding-top: 20px;
}

* + html .gnb {
  position: absolute;
  top: 0;
  padding-top: 0;
}

/* all 2nd menu down
===============================*/
.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver1.png");
  background-position: center -93px;
}

.down-all .gnb .sub-nav {
  display: block;
  text-align: center;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  background: transparent;
}

.down-all .gnb .sub-nav__box {
  border: 0px;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.down-all .gnb .sub-nav__box__list a {
  display: block;
  width: 100%;
  padding: 8px 0;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  font-weight: 500;
  /*
                &:hover,
				&:focus {}
				*/
}

.down-all .gnb.selected .gnb-ul .sub-nav {
  height: auto;
  visibility: visible;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0s ease 0s;
}

.down-all .gnb .gnb-menu.over .sub-nav {
  background-color: #fff;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list > a {
  color: #666;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list > a:hover,
.down-all .gnb .gnb-menu.over .sub-nav__box__list > a:focus {
  color: #275bb6;
}

.down-all .gnb .gnb-menu > a {
  background-repeat: repeat-x;
  background-position: left bottom;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .down-all .gnb .gnb-menu > a {
    border-bottom: 1px solid #ddd;
  }
}

.down-all .gnb .gnb-menu.over > a,
.down-all .gnb .gnb-menu.focus > a {
  background-image: url("../../resources/images/basic/header-gnb-bg-on.png");
  background-image: none;
  background-position: left bottom;
  background-repeat: repeat-x;
  background-color: #275bb6;
}

.down-all .gnb .gnb-menu:first-child > a > span {
  background-image: none;
}

/* 2차메뉴 균일 박스 디자인 적용시 */
.down-all .gnb .gnb-ul {
  display: table;
}

.down-all .gnb .gnb-menu {
  display: table-cell;
  float: none;
}

.down-all .gnb .gnb-menu.over {
  background-color: #fff;
}

/* 2차메뉴 보더적용시 주석 해제 */
/*
		*/
.down-all .gnb .gnb-menu > a {
  background-image: url("../../resources/images/basic/header-gnb-bg.png");
  background-position: left bottom;
  background-repeat: repeat-x;
}

.down-all .gnb .gnb-menu:first-child {
  border-left: 1px solid #ededed;
}

.down-all .gnb .gnb-menu:last-child {
  border-right: 1px solid #ededed;
}

.down-all .gnb .gnb-menu {
  padding-right: 1px;
}

.down-all .gnb.selected .gnb-menu {
  border-right: 1px solid #ededed;
  padding-right: 0;
}

.down-all .gnb .gnb-menu > a {
  display: block;
  margin-left: -1px;
  position: relative;
  text-align: center;
  width: 102.5%;
}

/*ie7*/
* + html .gnb {
  top: 90px;
}

* + html .gnb .gnb-menu {
  float: left;
  width: 25%;
}

* + html .gnb .gnb-menu > a {
  white-space: nowrap;
}

* + html .down-all .gnb .gnb {
  position: absolute;
  top: 34px;
}

* + html .down-all .gnb .gnb-ul {
  display: block;
}

* + html .down-all .gnb .gnb-menu {
  display: block;
  float: left;
  width: 25%;
}

* + html .down-all .gnb .gnb-menu.over {
  background-color: #fff;
}

* + html .down-all .gnb .gnb-menu:first-child {
  border-left: 0px solid #acc1bf;
}

* + html .down-all .gnb .gnb-menu {
  border-right: 0px solid #fff;
  border-left: 0px;
}

* + html .down-all .gnb .gnb-menu > a {
  width: 100%;
  margin-left: 0;
}

* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a {
  color: #666;
}

* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:hover,
* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:focus {
  color: #275bb6;
}

* + html .down-all .gnb .selected .gnb-menu {
  border-right: 0;
}

/*모드별 디자인
	ver2
	down-all
	add-all-menu
	add-search-btn
--------------*/
/*ver2*/
.ver2 .header__wrap__h1 h1 {
  top: 65px;
  z-index: 9999;
}

.ver2 .gnb {
  top: 0px;
}

.ver2 .gnb .gnb-ul {
  width: 900px;
  padding-left: 300px;
}

.ver2 .gnb .gnb-menu > a {
  height: 143px;
  line-height: 143px;
}

.ver2 .gnb .sub-nav {
  top: 143px;
}

.ver2.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver2.png");
  background-position: center -9px;
}

.ver2.down-all .gnb .sub-nav {
  top: 0 !important;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .ver2 .gnb .sub-nav {
    top: 60px !important;
  }
}

/* add-all-menu */
.add-all-menu .all_menu_wrap__outwrap {
  display: block;
}

.add-all-menu .gnb .gnb-ul {
  padding-right: 0px;
  width: 1200px;
}

/*ver2 + add-all-menu*/
.ver2.add-all-menu .gnb .gnb-ul {
  padding-left: 350px;
  width: 850px;
  padding-right: 0px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header {
    height: 182px;
  }
  .header__wrap__h1 h1 {
    top: 22px !important;
  }
  .gnb {
    top: 90px !important;
    background-image: none !important;
  }
  .gnb .gnb-ul {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .gnb .gnb-menu > a {
    height: 50px !important;
    line-height: 50px !important;
  }
  .gnb .sub-nav {
    top: 50px;
  }
}

/*유동적 메뉴사이즈*/
.none-width.header .gnb .gnb-ul {
  display: block;
  width: 1200px;
  margin: 0 auto;
}

.none-width.header .gnb .gnb-ul .gnb-menu {
  display: block;
  float: left;
}

.none-width.header.down-all .gnb .gnb-ul .gnb-menu {
  display: table-cell;
  float: none;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .none-width.header .gnb .gnb-ul {
    display: table;
    width: 100%;
    margin: 0 auto;
  }
  .none-width.header .gnb .gnb-ul .gnb-menu {
    display: table-cell;
    float: none;
  }
  .none-width.header .gnb .gnb-ul .gnb-menu > a {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.none-width.ver2.header .gnb .gnb-ul {
  width: 900px;
}

.none-width.header.add-all-menu .gnb .gnb-ul {
  width: 1200px;
}

.none-width.header.add-all-menu.ver2 .gnb .gnb-ul {
  width: 850px;
}

/* 펼쳤을때 좌측 타이틀*/
.gnb-tit {
  display: none;
}

.ver-left .gnb-tit {
  display: none !important;
}

.ver-left .down-all.ver2.left-tit .gnb {
  height: 143px;
  overflow: hidden;
}

.down-all.ver2.left-tit .gnb .gnb-ul {
  position: relative;
}

.down-all.ver2.left-tit .gnb.selected .gnb-ul {
  background-image: url("../../resources/images/basic/gnb-tit-bg.png");
  background-position: left bottom;
  background-repeat: no-repeat;
}

.down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  position: fixed;
  left: 50%;
  margin-left: -600px;
  z-index: 999;
  width: 350px;
  text-align: center;
  color: #000;
  letter-spacing: -2px;
  font-size: 27px;
  font-weight: bold;
}

.mainpage .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 331px;
}

.pop-closed .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 183px;
}

.top-banner-none .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 183px;
}

.subpage .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 183px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
    display: none;
  }
}

.down-all.ver2.left-tit .gnb .gnb-menu.over .gnb-tit, .down-all.ver2.left-tit .gnb .gnb-menu.focus .gnb-tit {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .down-all.ver2.left-tit .gnb .gnb-menu.over .gnb-tit, .down-all.ver2.left-tit .gnb .gnb-menu.focus .gnb-tit {
    display: none;
  }
}

.gnb .sub-nav__3rd {
  display: none;
}

.top-sns-go {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.top-sns-go__btn {
  position: absolute;
  top: 40px;
  right: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -281px -441px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
  text-indent: -9999px;
}

.top-sns-go__btn:focus, .top-sns-go__btn:hover {
  opacity: 0.9;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .top-sns-go {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .top-sns-go {
    display: none;
  }
}

/*로그아웃*/
.main__sign__name {
  position: absolute;
  top: 45px;
  right: 155px;
  color: #fff;
  background-color: #727272;
  border-radius: 5px;
  padding: 5px 10px;
}

.main__sign__login {
  position: absolute;
  top: 45px;
  right: 90px;
  color: #fff;
  background-color: #275bb6;
  border-radius: 5px;
  padding: 5px 10px;
}

.top-sns-go > a:focus {
  border: 1px solid #000;
}

.down-all .gnb {
  border-top: 1px solid #dbdbdb;
}

.gnb .gnb-menu > a {
  line-height: 1.4;
}

.down-all .gnb .gnb-menu > a {
  display: table-cell;
  vertical-align: middle;
  width: 1%;
}

.down-all .gnb .gnb-menu > a > span {
  display: block;
  padding-top: 0;
}

.down-all .gnb .gnb-menu > a > p {
  display: block;
  font-size: 12px;
}

.down-all .gnb .gnb-menu:first-child {
  border-left: 0;
}

.down-all .gnb .gnb-menu:last-child {
  border-right: 0;
}

.down-all .gnb.selected .gnb-menu:first-child {
  border-left: 1px solid #ededed;
}

.down-all .gnb.selected .gnb-menu:last-child {
  border-right: 1px solid #ededed;
}

.sidebar__h2 > p {
  font-size: 16px;
}

.side-list__li > span > a > p {
  display: table-row;
  font-size: 12px;
}

.down-all .gnb .sub-nav__box__list a > p {
  font-size: 12px;
}

.spp__in > a > p {
  font-size: 12px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .gnb .gnb-menu > a {
    height: 90px !important;
    line-height: unset !important;
  }
}

/*상단배너*/
.top-banner {
  min-height: 30px;
  max-height: 148px;
}

.top-banner__in {
  min-height: 30px;
  position: relative;
  text-align: center;
}

.top-banner__in > div:nth-child(1) {
  min-height: 30px;
}

.top-banner__in__link {
  display: block;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 17px;
}

.top-banner__in__link img {
  margin: 0 auto;
}

.top-banner__in__link__tit {
  color: #f2cb8e;
  font-weight: normal;
  padding-right: 10px;
}

.top-banner__in img {
  max-width: 100%;
  margin: 0 auto;
}

.top-banner__in p {
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  overflow: hidden;
  width: 0;
  height: 0;
}

.top-banner__in .top-banner-close {
  background-color: #03243f;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 0;
  position: absolute;
  top: -2px;
  right: 10px;
  text-align: right;
}

.top-banner__in .top-banner-close a,
.top-banner__in .top-banner-close label {
  vertical-align: middle;
  color: #fff;
  font-size: 14px;
  margin-lefT: 5px;
}

.top-banner__in .top-banner-close .top-banner-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px 0px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
  font-size: 0;
}

.top-banner__in .top-banner-close .top-banner-btn:focus {
  opacity: 0.7;
}

.top-banner__in__slick-pause {
  position: absolute;
  top: 1px;
  right: 200px;
  display: block;
  width: 22px;
  height: 22px;
  font-size: 0;
  text-indent: -9999px;
  border-radius: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -73px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
  vertical-align: top;
}

.top-banner__in__slick-pause:focus {
  background-color: gold;
  opacity: 0.7;
}

.top-banner .slick-arrow {
  display: none !important;
}

.top-banner .top-banner-nav {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
}

.top-banner .top-banner-nav__in {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .top-banner .top-banner-nav__in {
    width: auto;
  }
}

.top-banner .top-banner-nav__in .slick-dots {
  position: absolute;
  top: 3px;
  right: 225px;
}

.top-banner .top-banner-nav__in .slick-dots:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .top-banner .top-banner-nav__in .slick-dots {
  display: inline-block;
}

.top-banner .top-banner-nav__in .slick-dots li {
  float: left;
  margin-right: 15px;
}

.top-banner .top-banner-nav__in .slick-dots li button {
  background-color: #6e7880;
  display: inline-block;
  color: gold;
  vertical-align: middle;
  overflow: visible;
  width: 9px;
  height: 9px;
  line-height: 9px;
  border-radius: 100%;
  font-size: 0;
}

.top-banner .top-banner-nav__in .slick-dots li.slick-active button {
  background-color: transparent;
  border: 3px solid #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.top-banner .top-banner-nav__in .slick-dots li button:focus {
  background-color: gold;
}

.top-banner .top-banner-nav__in__prev {
  position: absolute;
  left: 0;
  top: 40px;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px 0px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.top-banner .top-banner-nav__in__prev:focus {
  opacity: 0.7;
}

.top-banner .top-banner-nav__in__next {
  position: absolute;
  right: 0;
  top: 40px;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -452px -312px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.top-banner .top-banner-nav__in__next:focus {
  opacity: 0.7;
}

body .top-banner {
  max-height: 148px;
  position: relative;
  z-index: 9;
}

body.pop-closed .top-banner {
  height: 0;
  min-height: 0;
  overflow: hidden;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  body .top-banner {
    height: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  body .top-banner {
    display: none;
  }
}

/*모바일메뉴*/
.mobile_top_nav {
  display: none;
}

#gnb_nav_mobile {
  height: auto;
  display: none;
}

/*모바일 메뉴 닫기*/
.mobile-close {
  position: fixed;
  left: -30px;
  top: 10px;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.mobile_on_off a {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mobile_on_off a {
    display: block;
  }
}

.mobile_top_nav.selected .mobile-close {
  opacity: 1;
  left: 285px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header {
    overflow: visible;
    position: fixed;
    border-bottom: 1px solid #333;
    width: 100%;
    padding-bottom: 0px;
    height: 50px;
    background-color: #fff;
    background-image: none;
  }
  .header.selected {
    height: 100%;
  }
  .header__wrap__h1 h1 {
    margin-left: 0;
    left: 0;
    position: relative;
    top: 9px;
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }
  .header__wrap__h1 h1 a {
    height: 35px;
    line-height: 35px;
    display: inline-block;
    margin: 0 auto;
  }
  .header__wrap__h1 h1 a img {
    max-height: 35px;
  }
  .ver2 .header__wrap__h1 h1 {
    top: 9px;
  }
  .header__wrap .gnb {
    display: none;
  }
  [class*="header__wrap"] {
    height: 50px;
  }
  /*모바일 메뉴*/
  .mobile_top_nav {
    display: block;
    z-index: 10000;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    background-image: url("../../resources/images/basic/opa.png");
    background-repeat: repeat;
    background-position: 0 0;
  }
  .mobile_top_nav .m_s_btn {
    position: absolute;
    top: 8px;
    right: 15px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -265.88235px 0px;
    background-image: url("../images/sprite.png");
    background-size: 501.17647px auto;
    width: 32.94118px;
    min-width: 32.94118px;
    height: 32.94118px;
  }
  .mobile_top_nav .m_open_btn {
    position: absolute;
    top: 12px;
    left: 15px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -163.5px -157px;
    background-image: url("../images/sprite.png");
    background-size: 426px auto;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .mobile_top_nav__in {
    left: -320px;
    width: 320px;
    position: fixed;
    top: 0;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s ease 0s;
    overflow: auto;
  }
  .mobile_top_nav.selected {
    height: 100%;
    overflow: auto;
    width: 100%;
    left: 0px;
  }
  .mobile_top_nav.selected .m_s_btn {
    display: none;
  }
  .mobile_top_nav.selected .mobile_top_nav__in {
    left: 0;
    box-shadow: 3px 4px 18px -7px rgba(0, 0, 0, 0.75);
  }
  #gnb_nav_mobile {
    opacity: 0;
    position: relative;
    padding-top: 44px;
    z-index: 9999;
    background-color: #fff;
    border-top: 1px solid #d1d1d6;
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu.over {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu:first-child {
    border-left: 0px;
  }
  #gnb_nav_mobile li.gnb-menu:last-child > a {
    border-bottom: 1px solid #d1d1d6;
  }
  #gnb_nav_mobile li.gnb-menu > a {
    width: auto;
    display: block;
    text-shadow: none;
    padding: 0px 10px 0px 20px;
    height: auto;
    line-height: 38px;
    font-size: 17px;
    font-weight: 500;
    color: #222;
    text-align: left;
    border-top: 1px solid #d1d1d6;
  }
  #gnb_nav_mobile li.gnb-menu > a > span {
    background-image: none;
  }
  #gnb_nav_mobile li.gnb-menu > a > p {
    font-size: 13px;
    line-height: 22px;
    padding-bottom: 10px;
  }
  #gnb_nav_mobile .sub-nav__box__ul {
    background-color: #eee;
  }
  #gnb_nav_mobile .sub-nav__box__list a {
    border-top: 1px solid #d1d1d6;
    padding: 10px 10px 10px 25px;
    width: auto;
    height: auto;
    font-weight: bold;
    display: block;
    color: #000;
    text-shadow: 0px 0px 0px;
  }
  #gnb_nav_mobile .sub-nav__box__list a > p {
    font-size: 13px;
    font-weight: 200;
    line-height: 22px;
  }
  #gnb_nav_mobile .sub-nav__box__list a:hover,
  #gnb_nav_mobile .sub-nav__box__list a:focus {
    background-color: #fff;
  }
  /*불켜짐*/
  #gnb_nav_mobile .gnb-menu {
    position: relative;
  }
  #gnb_nav_mobile .gnb-menu:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -117px -268.5px;
    background-image: url("../images/sprite.png");
    background-size: 426px auto;
    width: 14px;
    min-width: 14px;
    height: 14px;
    position: absolute;
    top: 12px;
    right: 10px;
  }
  #gnb_nav_mobile .gnb-menu.selected:after,
  #gnb_nav_mobile .gnb-menu.on:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -98.5px -307.5px;
    background-image: url("../images/sprite.png");
    background-size: 426px auto;
    width: 14px;
    min-width: 14px;
    height: 1px;
    position: absolute;
    top: 20px;
    right: 10px;
  }
  #gnb_nav_mobile .gnb-menu.on > a {
    color: #000;
  }
  #gnb_nav_mobile .gnb-menu.no-has-menu:after {
    display: none;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a {
    color: #000;
    text-decoration: underline;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #000;
    text-decoration: underline;
  }
  /*3rd*/
  #gnb_nav_mobile .sub-nav__3rd {
    position: relative;
  }
  #gnb_nav_mobile .sub-nav__3rd a {
    color: #666;
    border-bottom: 0px;
    font-size: 13px;
    font-weight: normal;
    width: auto;
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 35px;
    position: relative;
  }
  #gnb_nav_mobile .sub-nav__3rd a:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -409px -682px;
    background-image: url("../images/sprite.png");
    width: 2px;
    min-width: 2px;
    height: 2px;
    position: absolute;
    top: 20px;
    left: 25px;
  }
  #gnb_nav_mobile .sub-nav__3rd li:first-child {
    border-top: 0px;
  }
  /*#gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav{height:auto;		visibility: visible;}*/
  #gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav {
    height: auto;
    visibility: visible;
    display: block;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item .sub-nav {
    display: none;
    height: auto;
    visibility: visible;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item.on > a {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__3rd .on a {
    color: #000;
    font-weight: bold;
  }
  #gnb_nav_mobile .sub-nav__3rd a.on {
    color: #000;
    font-weight: bold;
  }
  /*모바일 메뉴 닫기*/
  .mobile-close {
    position: fixed;
    left: -30px;
    top: 9px;
    opacity: 0;
    transition: all 0.3s ease 0s;
  }
  .mobile_menu_open .mobile-close {
    left: 275px;
    z-index: 10000;
    opacity: 1;
  }
  .mobile-close a {
    font-size: 0;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    display: block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -226px -53px;
    background-image: url("../images/sprite.png");
    background-size: 426px auto;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .mobile_top_nav.selected .mobile-close {
    opacity: 1;
    left: 285px;
  }
  /*gnb 방식변경*/
  .gnb-ul {
    width: 100%;
    display: block;
    padding-right: 0;
  }
  .gnb-menu {
    width: 100%;
    display: block;
    border-right: 0px;
  }
  .gnb-menu > a {
    text-align: left;
    line-height: 30px;
    height: 30px;
    padding-left: 15px;
  }
  .sub-nav {
    width: auto;
    border: 0;
    background-color: #f7f7f7;
    text-align: left;
  }
  .sub-nav__box__list a span {
    color: #666666;
    background-image: none !important;
    font-size: 17px;
  }
  #gnb_nav_mobile li.gnb-menu.selected .sub-nav {
    height: auto;
    visibility: visible;
  }
  #gnb_nav_mobile.selected {
    display: block;
    opacity: 1;
  }
  .gnb.selected {
    display: block;
    opacity: 1;
  }
  .header__links {
    z-index: 0;
    width: auto;
    height: 0;
  }
  .header__links.selected {
    z-index: 99999;
  }
  .header__links__1 {
    width: 250px;
    right: unset;
    left: -250px;
    top: 15px;
    transition: all 0.3s ease 0s;
  }
  .header__links__1 a {
    padding-left: 15px;
  }
  .header__links.selected .header__links__1 {
    left: 0;
  }
}

/*전체메뉴보기*/
.all_menu_wrap__outwrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: none;
}

.all_menu_wrap__outwrap.selected {
  z-index: 10001;
}

.btn_all-wrap {
  width: 1200px;
  margin: 0 auto;
  z-index: 10000;
  position: relative;
}

.all_menu_wrap__outwrap.selected .btn_all {
  right: 0;
}

.btn_all {
  position: absolute;
  right: 40px;
  top: 40px;
  display: block;
  width: 36px;
  height: 36px;
}

.btn_all span {
  display: block;
  font-size: 0;
}

.btn_all span.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -197px -441px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
  margin-top: 1px;
  margin-left: 1px;
}

.btn_all span.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -113px -441px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
  display: none !important;
  margin-top: 1px;
  margin-left: 1px;
}

.selected .btn_all span.type1 {
  display: none !important;
}

.selected .btn_all span.type2 {
  display: inline-block !important;
}

.btn_all:focus {
  background-color: #e0e0e0;
  border-radius: 100%;
}

.all_menu_wrap__outwrap.selected .btn_all {
  right: 0;
}

.btn_all:hover,
.btn_all:focus {
  opacity: 0.8;
}

.btn_all:focus {
  border: 1px solid #000;
}

.all_menu_tit {
  height: 109px;
  width: 100%;
  background: #275bb6;
}

.all_menu_tit__in {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  font-size: 0;
}

.all_menu_wrap {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  z-index: 9999;
  position: absolute;
  top: 0;
  border-top: 1px solid #ddd;
  left: 0px;
  box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.75);
  /*
	height:0;
	overflow:hidden;
	visibility: hidden;*/
  opacity: 0;
  transition: all 0.7s ease 0s;
  display: none;
}

.all_menu_wrap__h2 {
  height: 110px;
  background-color: #0068b7;
  display: none;
}

.selected .all_menu_wrap {
  display: block;
  height: auto;
  opacity: 1;
  visibility: visible;
  display: block;
}

.all_menu_in {
  position: relative;
  padding: 20px;
  padding-top: 40px;
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

.selected .all_menu_in .gnb-menu {
  border-right: 0;
}

.all_menu_in .gnb-ul {
  padding-right: 0;
  margin-left: -10px;
}

.all_menu_in .gnb-menu {
  flex: 1;
}

.all_menu_in .gnb-menu > a {
  display: block;
  color: #fff;
  font-size: 18px;
  height: 54px;
  line-height: 54px;
  margin-left: 20px;
  text-align: center;
  font-weight: bold;
  background-color: #275bb6;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.all_menu_in .gnb-menu > a:focus {
  text-decoration: underline;
}

.all_menu_in .all_menu_close {
  color: #333;
  display: block;
  font-size: 0;
  position: absolute;
  right: 20px;
  top: -85px;
  z-index: 10000;
  width: 30px;
  height: 30px;
}

.all_menu_in .sub-nav {
  height: auto;
  visibility: visible;
}

.all_menu_in .sub-nav__box__ul {
  margin-left: 20px;
  padding-top: 20px;
}

.all_menu_in .sub-nav__box__list > a {
  display: block;
  min-height: 30px;
  font-weight: 600;
  line-height: 30px;
  padding: 0 0 6px 0;
  color: #222222;
  font-size: 15px;
  position: relative;
}

.all_menu_in .sub-nav__box__list > a:hover,
.all_menu_in .sub-nav__box__list > a:focus {
  color: #275bb6;
}

.all_menu_in .gnb-ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.all_menu_in .gnb-menu {
  min-width: 20%;
  margin-bottom: 20px;
  max-width: 20%;
}

.all_menu_in .gnb-menu:first-child {
  border-left: 0px;
}

.all_menu_in .sub-nav__3rd {
  text-align: left;
  padding-left: 0;
  padding-top: 0px;
}

.all_menu_in .sub-nav__3rd__ul__li a,
.all_menu_in .gnb-thrd-menu__item {
  display: block;
  margin-bottom: 8px;
  word-break: break-all;
  padding-left: 13px;
  color: #666;
  position: relative;
}

.all_menu_in .sub-nav__3rd__ul__li a:hover, .all_menu_in .sub-nav__3rd__ul__li a:focus,
.all_menu_in .gnb-thrd-menu__item:hover,
.all_menu_in .gnb-thrd-menu__item:focus {
  text-decoration: underline;
}

.all_menu_in .sub-nav__3rd__ul__li a:before,
.all_menu_in .gnb-thrd-menu__item:before {
  position: absolute;
  top: 8px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -300px -682px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .all_menu_wrap__outwrap {
    width: 100%;
    top: 0;
  }
  .all_menu_in {
    width: auto;
  }
  .all_menu_in .gnb-menu > a {
    background-image: none !important;
  }
  .btn_all-wrap {
    width: 100%;
  }
  .all_menu_wrap__outwrap.selected .btn_all {
    right: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .all_menu_wrap__outwrap {
    display: none !important;
  }
  .selected .all_menu_wrap {
    display: none;
  }
}

/*	메인페이지
===========================*/
.main-section {
  position: relative;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
}

.main-section__in {
  position: relative;
  min-height: 500px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}

.main-section__in__top {
  position: relative;
  z-index: 2;
  overflow: hidden;
  background-color: #fff;
  background-image: url("../../resources/images/cont/main-section__in__top.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.main-section__in__top__box {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 0;
}

.main-section__in__top__box:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-section__in__top__box {
  display: inline-block;
}

.main-section__in__middle {
  background-color: #dbefff;
  position: relative;
  z-index: 2;
}

.main-section__in__middle__box {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 0;
}

.main-section__in__middle__box:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-section__in__middle__box {
  display: inline-block;
}

.main-section__in__middle__box__in {
  width: 50%;
  float: left;
  position: relative;
  margin-top: 30px;
}

.main-section__in__middle__box__in__tit {
  font-size: 30px;
  font-weight: 600;
  color: #282828;
}

.main-section__in__middle__box__in__more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -312px -537px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 26px;
  font-size: 0;
  text-indent: -9999px;
  position: absolute;
  top: 12px;
  right: 30px;
}

.main-section__in__middle__box__in__cont {
  margin-top: 15px;
}

.main-section__in__middle__box__in__cont li {
  width: 50%;
  float: left;
}

.main-section__in__middle__box__in__cont li a {
  position: relative;
  margin-right: 30px;
  background-color: #fff;
  box-sizing: border-box;
  display: block;
  transition: all 0.3s ease 0s;
  height: 210px;
}

.main-section__in__middle__box__in__cont.bbs li a {
  padding: 25px;
}

.main-section__in__middle__box__in__cont.bbs li dl dt {
  font-size: 18px;
  color: #484848;
  height: 2.5em;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.3;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.main-section__in__middle__box__in__cont.bbs li dl .text {
  padding-top: 15px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #787878;
  white-space: normal;
  line-height: 1.3;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 3.8em;
}

.main-section__in__middle__box__in__cont.bbs li dl .time {
  font-size: 13px;
  color: #7d7d7d;
  position: absolute;
  bottom: 30px;
}

.main-section__in__middle__box__in__cont.bbs li a:hover {
  background-color: #275bb6;
  -webkit-box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.26);
  box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.26);
}

.main-section__in__middle__box__in__cont.bbs li a:hover dl dt, .main-section__in__middle__box__in__cont.bbs li a:hover dl dd {
  color: #fff;
}

.main-section__in__middle__box__in__cont.bbs2 li > a {
  display: block;
}

.main-section__in__middle__box__in__cont.bbs2 li > a .img_box {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 52%;
  background-color: #f7f7f7;
  text-align: center;
}

.main-section__in__middle__box__in__cont.bbs2 li > a .img_box img {
  width: 100%;
  height: auto;
  vertical-align: middle;
  transition: all 0.3s;
}

.main-section__in__middle__box__in__cont.bbs2 li > a .img_box .inner_box {
  font-size: 0;
}

.main-section__in__middle__box__in__cont.bbs2 li > a .inner_box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.main-section__in__middle__box__in__cont.bbs2 li > a .mid_helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.main-section__in__middle__box__in__cont.bbs2 li > a .txt_box {
  margin-top: 10px;
  padding: 0 20px;
}

.main-section__in__middle__box__in__cont.bbs2 li > a .txt_box .tit {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #484848;
  font-size: 16px;
}

.main-section__in__middle__box__in__cont.bbs2 li > a:hover {
  background-color: #275bb6;
  -webkit-box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.26);
  box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.26);
}

.main-section__in__middle__box__in__cont.bbs2 li > a:hover .tit, .main-section__in__middle__box__in__cont.bbs2 li > a:hover .info_box {
  color: #fff;
}

.main-section__in__middle__box__in__cont.bbs2 li > a:hover img, .main-section__in__middle__box__in__cont.bbs2 li > a:focus img {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  transform: scale(1.3);
}

.main-section__in__middle__box__in__cont.bbs2 .info_box {
  display: inline;
  height: 16px;
  color: #7d7d7d;
  font-size: 13px;
  padding: 0 20px;
  position: absolute;
  bottom: 15px;
}

.main-section__in__middle__box__in__cont.bbs2 .info_box span {
  display: inline-block;
  vertical-align: top;
}

.main-section__in__middle__box__in:first-child, .main-section__in__middle__box__in:nth-child(2) {
  margin-top: 0;
}

.main-section__in__middle__box__in:nth-child(2n) .main-section__in__middle__box__in__tit {
  margin-left: 30px;
}

.main-section__in__middle__box__in:nth-child(2n) .main-section__in__middle__box__in__more {
  right: 0;
}

.main-section__in__middle__box__in:nth-child(2n) .main-section__in__middle__box__in__cont li a {
  margin-right: 0;
  margin-left: 30px;
}

.main-section__in__bottom {
  background-color: #fff;
}

.main-section__in__bottom__box {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 34px;
  padding-bottom: 40px;
}

.main-section__in__bottom__box:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-section__in__bottom__box {
  display: inline-block;
}

.main-section__in__bottom__box li a {
  float: left;
  width: 12.5%;
  text-align: center;
  padding-top: 130px;
  background-repeat: no-repeat;
  color: #282828;
  font-size: 17px;
}

.main-section__in__bottom__box li a:hover {
  opacity: 0.8;
}

.main-section__in__bottom__box__1 a {
  background-image: url(../../resources/images/cont/bottom__box-img1.png);
  background-position: center top;
  background-size: 100%;
}

.main-section__in__bottom__box__2 a {
  background-image: url(../../resources/images/cont/bottom__box-img2.png);
  background-position: center 20px;
}

.main-section__in__bottom__box__3 a {
  background-image: url(../../resources/images/cont/bottom__box-img3.png);
  background-position: center 20px;
}

.main-section__in__bottom__box__4 a {
  background-image: url(../../resources/images/cont/bottom__box-img4.png);
  background-position: center 10px;
}

.main-section__in__bottom__box__5 a {
  background-image: url(../../resources/images/cont/bottom__box-img5.png);
  background-position: center top;
}

.main-section__in__bottom__box__6 a {
  background-image: url(../../resources/images/cont/bottom__box-img6.png);
  background-position: center 30px;
}

.main-section__in__bottom__box__7 a {
  background-image: url(../../resources/images/cont/bottom__box-img8.png);
  background-position: center 2px;
}

.main-section__in__bottom__box__8 a {
  background-image: url(../../resources/images/cont/bottom__box-img9.png);
  background-position: center 2px;
}

.main-lay-1 {
  float: left;
  max-width: 895px;
  width: 100%;
}

.main-lay-2 {
  float: right;
  width: 100%;
  max-width: 275px;
  margin-bottom: 30px;
}

.main-lay-3 {
  float: right;
  width: 100%;
  max-width: 275px;
}

.main-lay-4 {
  float: left;
  width: 50%;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .main-section__in {
    width: auto;
  }
  .main-section__in__top__box {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
  }
  .main-section__in__middle__box__in__more {
    right: 10px;
  }
  .main-section__in__middle__box__in__cont.bbs li a {
    padding: 20px;
    margin-right: 10px;
  }
  .main-section__in__middle__box__in:nth-child(2n) .main-section__in__middle__box__in__cont li a {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .main-section__in__middle__box__in__cont.bbs2 li > a .txt_box .tit {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    white-space: normal;
    line-height: 1.2;
    height: 2.5em;
    -webkit-box-orient: vertical;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-section {
    padding-top: 51px;
  }
  .main-lay-1 {
    padding-bottom: 10px;
  }
  .main-section__in__top__box {
    padding: 10px 10px 0 10px;
  }
  .main-section__in__middle__box {
    padding: 30px 10px;
  }
  .main-section__in__middle__box__in {
    width: 100%;
    float: none;
    display: inline-block;
  }
  .main-section__in__middle__box__in__more {
    right: 0;
    top: 5px;
  }
  .main-section__in__middle__box__in__cont {
    margin-top: 10px;
  }
  .main-section__in__middle__box__in__cont.bbs a {
    height: 190px;
  }
  .main-section__in__middle__box__in__cont.bbs2 a {
    height: auto;
  }
  .main-section__in__middle__box__in__cont.bbs li dl .time {
    bottom: 20px;
  }
  .main-section__in__middle__box__in__cont li:first-child a {
    margin-right: 5px !important;
  }
  .main-section__in__middle__box__in__cont li:nth-child(2) a {
    margin-right: 0;
    margin-left: 5px !important;
  }
  .main-section__in__middle__box__in__tit {
    font-size: 23px;
  }
  .main-section__in__middle__box__in__cont.bbs2 li > a .txt_box .tit {
    -webkit-line-clamp: 3;
    height: 3.6em;
  }
  .main-section__in:nth-child(2n) .main-section__in__middle__box__in__tit {
    margin-left: 0;
  }
  .main-section__in__middle__box__in:nth-child(2) {
    margin-top: 30px;
  }
  .main-section__in__middle__box__in:nth-child(2n) .main-section__in__middle__box__in__cont li a {
    margin-left: 0;
    margin-right: 0;
  }
  .main-section__in__middle__box__in__cont.bbs2 .info_box {
    position: relative;
    padding-bottom: 15px;
    bottom: auto;
    display: inline-block;
  }
  .main-section__in__bottom__box li a {
    width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .main-section__in__bottom__box li a {
    width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1280px) {
  .main-lay-1 {
    width: 75%;
  }
  .main-lay-2 {
    width: 23%;
    float: left;
    padding-left: 2%;
  }
  .main-lay-3 {
    width: 23%;
    float: left;
    padding-left: 2%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-lay-1 {
    width: 100%;
    float: none;
    margin: 0 auto;
  }
  .main-lay-2 {
    float: left;
    width: 49%;
    padding-right: 1%;
    text-align: center;
    margin: 0 auto;
    max-width: 100%;
    padding-left: 0;
  }
  .main-lay-3 {
    float: left;
    width: 49%;
    padding-left: 1%;
    text-align: right;
    margin: 0 auto;
    max-width: 100%;
  }
}

.main-jido-btnbox {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 19;
  height: 610px;
  width: 52px;
}

.main-jido-btnbox__btn {
  display: block;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  width: 22px;
  text-align: center;
  line-height: 1.2;
  padding: 0 15px;
  background-color: #275bb6;
}

.main-jido-btnbox__btn span {
  top: 40%;
  position: relative;
}

.main-jido-btnbox__btn span:after {
  margin-top: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -355px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.main-jido-btnbox.on .main-jido-btnbox__btn {
  position: relative;
  z-index: 9;
}

.main-jido-btnbox.on .main-jido-btnbox__btn span:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -285px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.main-jido {
  position: absolute;
  top: 0;
  right: 0;
  height: 610px;
  z-index: 1;
  width: 100%;
  transition: all 0.3s ease 0s;
}

.main-jido__box {
  width: 100%;
  height: 610px;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
}

.main-jido__box .root_daum_roughmap_landing {
  width: 100% !important;
}

.main-jido__box > div {
  width: 100% !important;
}

.main-jido__box > div wrap_map {
  width: 100% !important;
}

.main-jido__info {
  display: block;
  position: relative;
  z-index: 999;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 0;
}

.main-jido__info ul {
  width: 410px;
  margin-top: 30px;
  height: 54px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 5px 20px;
}

.main-jido__info ul li {
  float: left;
  font-size: 16px;
  box-sizing: border-box;
  font-weight: 500;
  padding-top: 8px;
  padding-right: 13px;
}

.main-jido__info ul li img {
  width: 22px;
  padding-right: 5px;
}

.main-jido__info ul li.type-pink {
  color: #e35ea0;
}

.main-jido__info ul li.type-blue {
  color: #3a74bb;
}

.main-jido__info ul li.type-green {
  color: #0bac4c;
}

.main-jido__info ul li.type-red {
  color: #cc2127;
}

.main-jido.on {
  width: 100%;
  height: 610px;
  z-index: 4;
}

.main-jido.on .main-jido__box {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding-right: 52px;
  background-color: gold;
}

.main-jido.on .main-jido__box > div {
  width: 100% !important;
}

.main-jido.on .main-jido__box > div wrap_map {
  width: 100% !important;
}

.main-jido__layer {
  display: none;
  width: 300px;
  border: 1px solid #cccccc;
  min-height: 180px;
  position: absolute;
  z-index: 99;
  background-color: #fff;
}

.main-jido__layer.on {
  display: block;
}

.main-jido__layer__tit {
  padding: 10px 10px 10px 20px;
  color: #fff;
  font-size: 18px;
}

.type-pink .main-jido__layer__tit {
  background-color: #e35ea0;
}

.type-blue .main-jido__layer__tit {
  background-color: #3a74bb;
}

.type-green .main-jido__layer__tit {
  background-color: #0bac4c;
}

.type-red .main-jido__layer__tit {
  background-color: #cc2127;
}

.main-jido__layer ul {
  position: relative;
  padding: 16px 20px;
}

.main-jido__layer ul li {
  background: url(../../resources/images/cont/jido-box-bul.png) left center no-repeat;
  margin-bottom: 15px;
  position: relative;
  padding-left: 65px;
  font-size: 14px;
}

.main-jido__layer ul li strong {
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 8px;
}

.main-jido__layer__close {
  position: absolute;
  right: 15px;
  top: 16px;
  font-family: "Nanum Gothic";
  font-weight: bold;
  color: #fff;
  font-size: 0;
}

.main-jido__layer ul::before {
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -10px;
  content: '';
  background: url(../../resources/images/cont/jido-box-img.png);
  width: 20px;
  height: 20px;
}

.main-jido__icon.type-pink {
  position: absolute;
}

.main-jido__icon.type-blue {
  position: absolute;
}

.main-jido__icon.type-green {
  position: absolute;
}

.main-jido__icon.type-red {
  position: absolute;
}

@media screen and (min-width: 1px) and (max-width: 1280px) {
  .main-jido-btnbox {
    width: auto;
    height: auto;
  }
  .main-jido-btnbox__btn {
    width: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0 0 0 10px;
  }
  .main-jido-btnbox__btn span:after {
    margin-top: -3px;
    margin-left: 10px;
  }
  .main-jido.on .main-jido__btn {
    width: 22px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0 0 0 0px;
  }
  .main-jido.on .main-jido__btn span:after {
    margin-top: 10px;
    margin-left: 0px;
  }
  .main-jido.on .main-jido__box {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-jido-btnbox {
    display: none;
  }
  .main-jido {
    display: none !important;
  }
}

.main-jido__layer ul li.jido_btn1, .main-jido__layer ul li.jido_btn2 {
  display: inline-block;
  margin-bottom: 0;
  width: 50%;
  padding-left: 0;
  background: none;
  text-align: center;
}

.main-jido__layer ul li.jido_btn1 a, .main-jido__layer ul li.jido_btn2 a {
  display: inline-block;
  color: #fff;
  background-color: #a5a5a5;
  padding: 5px 10px;
}

/*팝업존*/
.popupzone {
  position: relative;
  margin-top: 0px;
  width: 100%;
  height: auto;
}

.popupzone .slick-arrow {
  border: 0px;
  top: 220px;
}

.popupzone .slick-prev {
  text-indent: -9999em;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.popupzone .slick-prev::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: url("/template/resources/images/icons/prev_icon.png") no-repeat center center;
}

.popupzone .slick-next {
  text-indent: -9999em;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.popupzone .slick-next::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: url("/template/resources/images/icons/next_icon.png") no-repeat center center;
}

.popupzone .slick-slide a:hover,
.popupzone .slick-slide a:focus {
  opacity: 0.8;
}

.popupzone__tit {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.popupzone__item > img {
  width: 100%;
}

.main-lay-2 .popupzone .popupzone-list__item > a:focus {
  border: none;
}

.popupzone-nav {
  text-align: right;
  position: absolute;
  top: 10px;
  right: 0px;
  box-sizing: border-box;
  padding-right: 5px;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone-nav.off {
  display: none;
}

.popupzone-nav > div {
  display: inline-block;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 0;
  z-index: 999;
  padding: 2px 10px;
  border-radius: 50px;
  margin-top: -44px;
}

.popupzone-nav > div > div {
  display: inline-block;
}

* + html .popupzone-nav > div > div {
  display: inline;
}

.popupzone-nav > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

* + html .popupzone-nav > div > ul {
  display: inline;
}

.popupzone-nav > div > ul li {
  display: inline-block;
  margin-top: -2px;
  vertical-align: middle;
}

* + html .popupzone-nav > div > ul li {
  display: inline;
}

.popupzone-nav > div > ul li a {
  color: gold;
}

.popupzone-nav > div > ul li:nth-child(3) {
  display: none;
}

.popupzone-nav > div > ul li .slide-pause-item {
  display: block;
  position: relative;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.popupzone-nav > div > ul li .slide-pause-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -295px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 9px;
}

.popupzone-nav > div > ul li .slide-pause-item:focus {
  border: 1px solid gold;
  border-radius: 100%;
}

.popupzone-nav > div > ul li .slide-play-item {
  display: none;
  position: relative;
  width: 17px;
  height: 17px;
  font-size: 0;
  text-indent: -9999px;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.popupzone-nav > div > ul li .slide-play-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -539px -537px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 24px;
}

.popupzone-nav > div > ul li .slide-play-item:focus {
  border: 1px solid gold;
  border-radius: 100%;
}

.popupzone-nav > div > ul li .slide-more-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0;
  width: 26px;
  height: 26px;
}

.popupzone-nav > div > ul li .slide-more-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -312px -537px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 26px;
}

.popupzone-nav > div > ul li .slide-more-item:focus {
  border: 1px solid gold;
  border-radius: 100%;
}

.popupzone-nav > div > ul li .slide-next-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.popupzone-nav > div > ul li .slide-next-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -354px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.popupzone-nav > div > ul li .slide-next-item:focus {
  border: 1px solid gold;
  border-radius: 100%;
}

.popupzone-nav > div > ul li .slide-prev-item {
  display: block;
  position: relative;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.popupzone-nav > div > ul li .slide-prev-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -413px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.popupzone-nav > div > ul li .slide-prev-item:focus {
  border: 1px solid gold;
  border-radius: 100%;
}

.popupzone-nav .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

* + html .popupzone-nav .slick-dots-list {
  display: inline !important;
}

.popupzone-nav .slick-dots-list li {
  display: inline-block;
  margin-right: 3px;
}

.popupzone-nav .slick-dots-list li button {
  background-color: transparent;
  display: inline-block;
  color: #e19026;
  vertical-align: middle;
  background-color: #cccccc;
  overflow: visible;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
}

.popupzone-nav .slick-dots-list li.slick-active button {
  background-color: #000;
}

.popupzone-nav .slick-dots-list li button:focus {
  background-color: #e19026;
  border: 2px solid #fff;
}

.popupzone-nav * + html .slick-dots-list li {
  display: inline;
}

.popupzone-nav * + html .slick-dots-list li button {
  display: inline;
}

.popupzone-nav .popup-total {
  display: none;
}

.ver-total .popupzone-nav .slick-dots-list {
  font-size: 0;
}

.ver-total .popupzone-nav .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone-nav .slick-dots-list button {
  font-size: 15px;
  text-indent: 0;
  background-image: none;
  display: none;
}

.ver-total .popupzone-nav .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  height: 30px;
  text-align: center;
  color: #fff;
  background-color: transparent;
  font-weight: bold;
}

.ver-total .popupzone-nav .slick-dots-list .slick-active button:focus {
  border: 2px solid #fff;
}

.ver-total .popupzone-nav .popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
}

.ver-total .popupzone-nav * + html .popup-total {
  display: inline;
}

.ver-total .popupzone-nav .popup-total-num {
  padding-left: 4px;
}

.popupzone-nav .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone-nav .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone-nav .slide-play-item {
  display: none;
}

* + html .popupzone-nav.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone-nav.ver-total .slick-dots-list {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .popupzone .popupzone-list__item img {
    height: 200px;
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .popupzone .popupzone-list__item img {
    height: 170px;
  }
  .main-lay-2 {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 0\0) {
  .popupzone .popupzone-list__item {
    height: 230px;
    overflow: hidden;
  }
  .popupzone .popupzone-list__item img {
    height: auto;
  }
}

@media screen and (min-width: 0\0) and (min-width: 1px) and (max-width: 1200px) {
  .popupzone .popupzone-list__item {
    height: 230px;
    overflow: hidden;
  }
  .popupzone .popupzone-list__item img {
    height: 230px;
    object-fit: cover;
  }
}

@media screen and (min-width: 0\0) and (min-width: 1px) and (max-width: 768px) {
  .popupzone .popupzone-list__item {
    height: auto;
  }
  .popupzone .popupzone-list__item img {
    height: auto;
  }
}

.lte-ie9 .popupzone .popupzone-list__item {
  height: 340px;
  overflow: hidden;
}

.lte-ie9 .popupzone .popupzone-list__item img {
  height: auto;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .lte-ie9 .popupzone .popupzone-list__item {
    height: 300px;
    overflow: hidden;
  }
  .lte-ie9 .popupzone .popupzone-list__item img {
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .lte-ie9 .popupzone .popupzone-list__item {
    height: auto;
    overflow: hidden;
  }
  .lte-ie9 .popupzone .popupzone-list__item img {
    height: auto;
  }
}

/*유튜브링크*/
.youtube-box__tit {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.youtube-box__link {
  display: block;
  position: relative;
}

.youtube-box__link img {
  height: 100%;
}

.youtube-box__link:before {
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -314px -122px;
  background-image: url("../images/sprite.png");
  width: 77px;
  min-width: 77px;
  height: 77px;
}

.youtube-box__link:hover, .youtube-box__link:focus {
  opacity: 0.8;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .popupzone {
    max-width: 100%;
  }
  .popupzone .popupzone-list__item img {
    width: 100%;
  }
  .youtube-box__link img, .popupzone .popupzone-list__item img {
    height: 260px;
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .youtube-box__link img, .popupzone .popupzone-list__item img {
    height: 220px;
  }
}

@media screen and (min-width: 1px) and (max-width: 390px) {
  .main-lay-2 {
    width: 100%;
    padding-right: 0;
  }
  .main-lay-3 {
    width: 100%;
    padding-left: 0;
  }
  .youtube-box__link img, .popupzone .popupzone-list__item img {
    height: auto;
  }
  .main-section__in__top__box {
    padding-bottom: 10px;
  }
}

/* 메인 팝업(우측 상하단) 버튼 위치 변경 */
.popupzone-nav > div {
  padding: 0 4px;
  box-sizing: border-box;
}

.ver-total .popupzone-nav .slick-dots-list button {
  font-size: 13px;
  height: auto;
  line-height: 24px;
}

.ver-total .popupzone-nav .slick-dots-list .slick-active button {
  font-size: 13px;
  height: auto;
  line-height: 24px;
}

.ver-total .popupzone-nav .popup-total {
  font-size: 13px;
  line-height: 24px;
}

.ver-total .popupzone-nav .popup-total-num {
  font-size: 13px;
  line-height: 24px;
}

.popupzone-nav > div > ul li:nth-child(1) {
  display: none;
}

.popupzone-nav > div > ul li:nth-child(4) {
  display: none;
}

.youtube-box {
  position: relative;
}

/*메인슬라이드*/
.main-visual {
  position: relative;
  margin-top: 0px;
  width: 100%;
  height: 490px;
}

.main-visual .slick-arrow {
  border: 0px;
  top: 220px;
}

.main-visual .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
  display: none !important;
}

.main-visual .slick-next {
  position: absolute;
  right: 10px;
  z-index: 1;
  display: none !important;
}

/*이미지의 상하사이즈로 설정*/
.main-visual .slick-dots-wrap-outwrap {
  text-align: right;
  position: absolute;
  z-index: 3;
  top: 5px;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  right: 10px;
}

.main-visual .slick-dots-wrap {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2px 10px;
  border-radius: 20px;
  padding-left: 16px;
}

.main-visual .myslickcarousel {
  display: inline-block;
}

.main-visual .slick-dots-on-off {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

.main-visual .slick-dots-on-off li {
  display: inline-block;
  vertical-align: middle;
}

.main-visual .slick-dots-on-off li a {
  color: gold;
}

.main-visual * + html .myslickcarousel {
  display: inline;
}

.main-visual * + html .slick-dots-on-off {
  display: inline;
}

.main-visual * + html .slick-dots-on-off li {
  display: inline;
}

.main-visual .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

.main-visual .slick-dots-list li {
  display: inline-block;
}

.main-visual .slick-dots-list li button {
  background-color: #444;
  display: inline-block;
  color: gold;
  vertical-align: middle;
  overflow: visible;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  font-size: 0;
}

.main-visual .slick-dots-list li.slick-active button {
  background-color: #fff;
}

.main-visual .slick-dots-list li button:focus {
  background-color: #fff;
}

.main-visual * + html .slick-dots-list li {
  display: inline;
}

.main-visual * + html .slick-dots-list li button {
  display: inline;
}

.main-visual .slick-dots-on-off img {
  vertical-align: middle;
}

.main-visual .slide-pause-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.main-visual .slide-pause-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -295px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 9px;
}

.main-visual .slide-pause-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.main-visual .slide-play-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.main-visual .slide-play-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -539px -537px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 24px;
}

.main-visual .slide-play-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.main-visual .slide-next-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.main-visual .slide-next-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -354px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.main-visual .slide-next-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.main-visual .slide-prev-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.main-visual .slide-prev-item:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -413px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 9px;
}

.main-visual .slide-prev-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.main-visual .slick-dots-wrap-outwrap {
  display: block;
}

.main-visual .slick-dots-wrap-outwrap.off {
  display: none;
}

.main-visual .popup-total {
  display: none;
}

.main-visual.ver-total .slick-dots-list {
  font-size: 0;
}

.main-visual.ver-total .slick-dots-list li {
  margin-right: 0;
}

.main-visual.ver-total .slick-dots-list button {
  font-size: 15px;
  text-indent: 0;
  background-image: none;
  display: none;
}

.main-visual.ver-total .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  height: 30px;
  text-align: center;
  color: #fff;
  background-color: transparent;
  font-weight: bold;
}

.main-visual.ver-total .slick-dots-list .slick-active button:focus {
  border: 2px solid #fff;
}

.main-visual.ver-total .popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
}

.main-visual.ver-total * + html .popup-total {
  display: inline;
}

.main-visual.ver-total .popup-total-num {
  padding-left: 4px;
}

.main-visual.nav-arrow {
  display: none !important;
}

.main-visual.ver-arrow .nav-arrow {
  display: block !important;
}

.main-visual.ver-arrow .slide-play-item {
  display: none;
}

* + html .ver-total .main-slide .popup-total {
  display: none !important;
}

* + html .ver-total .main-slide .slick-dots-list {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .main-visual {
    width: 100%;
    padding-top: 0;
    height: auto;
  }
  .main-visual {
    margin-top: 0;
  }
  .main-visual .slick-arrow {
    top: 150px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .main-slide__item > img {
    width: 895px;
  }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .main-slide__item > img {
    width: 800px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-slide__item > img {
    width: 100%;
  }
  .main-visual .slick-arrow {
    top: 70px;
  }
}

/*배너존*/
.banner_zone_outwrap {
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
  background-color: #f2f3f5;
}

.banner_zone_wrap {
  position: relative;
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  height: 60px;
}

.banner_zone {
  width: 1100px;
  overflow: hidden;
  z-index: 0;
  position: relative;
  text-align: center;
  margin-left: 100px;
}

.banner_zone a {
  padding: 0 0;
}

.selectbox_title_focus {
  border: 1px solid #ffc169 !important;
}

.listwrap {
  position: relative;
  left: 0;
  overflow: hidden;
}

* + html .listwrap {
  overflow: hidden;
  height: 60px;
  white-space: nowrap;
}

.listwrap li {
  float: left;
  width: 222px;
  height: 60px;
}

.listwrap li a {
  display: block;
}

.listwrap li a:focus img {
  border: 1px solid gold;
}

.listwrap li a img {
  width: 198px;
  height: 58px;
  border: 1px solid #ddd;
}

.navi {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 18px;
}

.navi .on {
  background-color: red;
}

.banner_zone_wrap h3 {
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 17px;
  color: #333333;
  position: absolute;
  top: 0px;
  left: 2px;
}

.foot_roll_nav2 {
  position: relative;
  z-index: 1;
}

.foot_roll_nav2 a:focus {
  border: 1px solid gold;
}

.foot_roll_nav2 .r_prev {
  left: 0px;
  top: 30px;
}

.foot_roll_nav2 .r_pause {
  left: 27px;
  top: 30px;
}

.foot_roll_nav2 .r_next {
  left: 54px;
  top: 30px;
}

.foot_roll_nav2 .r_plus {
  left: 87px;
  top: 30px;
}

.foot_roll_nav2 .r_start {
  left: 116px;
  top: 30px;
}

.foot_roll_nav2 span {
  float: left;
  position: absolute;
}

.foot_roll_nav2 span.go_all_banner {
  padding-left: 3px;
}

.banner_zone_wrap .pos_banner {
  position: absolute;
  top: 15px;
  left: 133px;
}

.r_plus a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -78px -537px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_pause a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -537px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_start a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -388px -537px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.r_prev a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -445px -441px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_next a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -156px -537px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-right: -1px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .banner_zone_outwrap {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .banner_zone_wrap {
    width: auto;
  }
  .banner_zone {
    width: 870px;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  /*배너존*/
  .banner_zone {
    width: 655px;
  }
}

@media screen and (min-width: 769px) and (max-width: 850px) {
  /*배너존*/
  .banner_zone {
    width: 440px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .banner_zone_outwrap {
    padding-bottom: 10px;
  }
  .banner_zone_wrap {
    height: auto;
    padding-left: 10px;
  }
  .banner_zone_wrap h3 {
    top: 0px;
    left: 10px;
  }
  .foot_roll_nav2 .r_prev {
    left: 83px;
    top: 0px;
  }
  .foot_roll_nav2 .r_pause {
    left: 110px;
    top: 0px;
  }
  .foot_roll_nav2 .r_next {
    left: 137px;
    top: 0px;
  }
  .foot_roll_nav2 .r_plus {
    left: 164px;
    top: 0px;
  }
  .foot_roll_nav2 .r_start {
    left: 34px;
    top: 0px;
  }
  .banner_zone {
    width: 440px;
    margin: 0 auto;
    padding-top: 40px;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .banner_zone {
    width: 230px;
  }
}

/*		sidebar
=========================*/
/* sidebar */
.sidebar {
  position: relative;
  width: 220px;
  float: left;
  padding-bottom: 40px;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
}

.sidebar__wrap {
  background-color: #fff;
}

.sidebar__h2 {
  text-align: center;
  padding-top: 45px;
  padding-bottom: 43px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: 500;
  position: relative;
  color: #fff;
  line-height: 1.2;
  background-color: #2e84dd;
  /*메뉴 이름 길경우*/
}

.sidebar__h2 span {
  display: block;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.sidebar__h2 a {
  display: none;
}

.sidebar__h2.long-type {
  font-size: 22px;
  padding-top: 25px;
  height: 80px;
  padding-bottom: 40px;
  word-break: break-all;
}

.side-list__li > span > a {
  display: table;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  height: 46px;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
  color: #555;
  font-size: 16px;
  font-weight: normal;
  transition: all 0.3s ease 0s;
  background-position: 213px center;
  position: relative;
}

.side-list__li > span > a:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.side-list__li > span > a em {
  vertical-align: middle;
  display: table-cell;
}

.side-list__li.open-type > span > a {
  position: relative;
}

.side-list__li.open-type > span > a:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -237px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 20px;
}

.side-list__li.open-type > span > a:hover:after,
.side-list__li.open-type > span > a:focus:after,
.side-list__li.open-type > span.on > a:after,
.side-list__li.open-type > span > a.on:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -275px -615px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 2px;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 20px;
}

.side-list__li.open-type.on > span > a:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -275px -615px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 2px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 20px;
}

.side-list__li.on > span {
  display: block;
}

.side-list__li.on > span > a {
  font-weight: normal;
  opacity: 0.9;
  color: #fff;
  background-color: #275bb6;
  border: 1px solid #275bb6;
  position: relative;
}

.side-list__li.on > span > a:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -532px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.side-list__li > span > a:hover,
.side-list__li > span > a:focus,
.side-list__li > span.on > a,
.side-list__li > span > a.on,
.side-list__li.selected > span > a {
  font-weight: normal;
  opacity: 0.9;
  color: #fff;
  background-color: #275bb6;
  border: 1px solid #275bb6;
}

.side-list__li__inbox {
  display: none;
}

.open-type.on > .side-list__li__inbox {
  display: block;
  background-color: #f6f7f8;
  padding: 10px;
  border: 1px solid #e0e0e0;
}

.spp__in > a {
  display: block;
  font-size: 15px;
  font-size: 1.5rem;
  color: #686868;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 13px;
}

.spp__in > a:before {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -211px -748px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
  padding-right: 8px;
}

.spp__in > a:hover,
.spp__in > a:focus,
.spp__in.on > a,
.spp__in > .on,
.spp__in.selected > a,
.spp__in > .selected {
  color: #275bb6;
  text-decoration-line: underline;
  box-sizing: border-box;
}

.spp__in > a:hover:before,
.spp__in > a:focus:before,
.spp__in.on > a:before,
.spp__in > .on:before,
.spp__in.selected > a:before,
.spp__in > .selected:before {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -355px -682px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.spp__in__small {
  display: none;
}

.on > .spp__in__small {
  display: block;
  padding: 3px 10px 10px 15px;
}

.spp__in__small a {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 13px;
  font-size: 1.3rem;
}

.spp__in__small .on,
.spp__in__small a:hover,
.spp__in__small a:focus {
  text-decoration: underline;
}

.sp-open-type {
  position: relative;
}

.sp-open-type:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -179px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
  position: absolute;
  top: 13px;
  right: 15px;
}

.sp-open-type.on:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -337px -615px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
  position: absolute;
  top: 15px;
  right: 15px;
}

/*4차*/
.spp__in__small__4th {
  display: none;
}

.on > .spp__in__small__4th {
  display: block;
  padding: 10px 5px 10px 10px;
  background-color: #eff3f7;
}

.spp__in__small__4th a {
  display: block;
  padding: 3px 0;
  min-height: 17px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: normal;
  padding-left: 15px;
}

.spp__in__small__4th > li.on > a,
.spp__in__small__4th > li > .on,
.spp__in__small__4th a:hover,
.spp__in__small__4th a:focus {
  text-decoration: underline;
}

/* 반응형 버튼 */
.mobile-submenu-btn {
  display: none;
  background-color: #3d6cbd;
  text-align: center;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  height: 29px;
  line-height: 29px;
  position: absolute;
  top: 3px;
  right: 7px;
  z-index: 98;
}

.mobile-submenu-btn .type1 {
  display: block;
}

.mobile-submenu-btn .type2 {
  display: none;
}

.selected.mobile-submenu-btn .type1 {
  display: none;
}

.selected.mobile-submenu-btn .type2 {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .sidebar {
    position: relative;
    z-index: 1;
    margin-top: 0px;
    float: none;
    width: 100%;
    padding-bottom: 0;
  }
  .side_in_wrap {
    display: none;
    border-bottom: 2px solid #2e84dd;
    opacity: 0;
  }
  .sidebar h2 {
    text-align: left;
    background-image: none;
    background-color: #2e84dd;
    padding-left: 10px;
    font-size: 1.5rem;
    height: 32px;
    padding-top: 5px;
    line-height: 1.6;
  }
  .sidebar h2 {
    color: #fff;
    min-height: 10px;
    height: 30px;
    border-radius: 0px;
  }
  .sidebar h2 a {
    display: none;
  }
  .sidebar__h2 {
    padding-bottom: 0px;
  }
  .sidebar__h2.long-type {
    padding-bottom: 0px;
    font-size: 16px;
    padding-top: 5px;
    height: 32px;
  }
  .sidebar h2 {
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
  }
  .side-list__li > span > a {
    height: 30px;
    line-height: 30px;
  }
  .side-list__li.open-type > span > a {
    background-position: 96% center;
  }
  .side-list__li.open-type.on > span > a {
    background-position: 96% center;
  }
  .sp-open-type {
    background-position: 97% center;
  }
  .sp-open-type.on {
    background-position: 97% center;
  }
  /**/
  .sidebar__wrap {
    opacity: 1.0 !important;
    margin-left: 0px !important;
  }
  .side_in_wrap {
    display: block;
    border-bottom: 2px solid #000;
    opacity: 1;
  }
  .side_in_wrap li.selected span a {
    background-color: #fff !important;
    color: #f6804e !important;
  }
  .mobile-submenu-btn {
    display: block;
  }
  .open-type.on > .side-list__li__inbox {
    padding: 0 10px;
  }
  .sidebar .side-list > li {
    overflow: hidden;
    height: 0;
  }
  /*.sidebar .side-list > li.on {display:block;}*/
  .sidebar .side-list > li.on {
    height: auto;
  }
  .sidebar.selected .side-list > li {
    height: auto;
  }
  .side-list__li > span > a {
    padding: 0px 0 0px 20px;
    line-height: 1.5;
    padding-top: 5px;
    padding-bottom: 8px;
    transition: all 0.3s ease 0s;
  }
  .sidebar.selected .side-list__li > span > a {
    padding: 5px 0 5px 20px;
  }
  .sidebar.selected .side-list > li.open-type {
    height: auto;
  }
}

/*통합검색*/
.subpage-type2 .sidebar {
  display: none;
}

.subpage-type2 .sub-contents__top {
  border: 0;
  margin-bottom: 5px;
}

.subpage-type2 .sub-contents__top h2.sub-tit {
  font-size: 38px;
  color: #282828;
  font-weight: bold;
}

.subpage-type2 .sub-contents {
  width: 100%;
}

.subpage-type2 .location-box {
  display: none;
}

.subpage-type2 .special-box {
  display: none;
}

.subpage-type2 .mobile-submenu-btn {
  display: none;
}

/*sub-page-container*/
.sub-page-container {
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
}

.sub-page-container__in {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
  padding-top: 30px;
}

.sub-page-container__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sub-page-container__in {
  display: inline-block;
}

.sub-contents {
  float: right;
  width: 900px;
}

.sub-contents a:focus {
  border: 2px solid gold;
}

.cont-in {
  padding-bottom: 40px;
  min-height: 500px;
}

.cont-in img {
  max-width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .sub-page-container__in {
    width: auto;
    padding-top: 0;
  }
  .sub-contents {
    width: auto;
    float: none;
    padding: 10px;
    box-sizing: border-box;
  }
  .cont-in img {
    height: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-page-container {
    padding-top: 51px;
  }
}

.sub-contents__top {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  font-family: 'Noto Sans KR';
  /*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
  background-position: right top;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.sub-contents__top h2.sub-tit {
  font-size: 38px;
  color: #222;
  padding-top: 10px;
  margin-bottom: 0;
  height: 60px;
  font-weight: 500;
}

/* location */
.location-box {
  position: relative;
  margin-bottom: 20px;
}

.location-box__cont span {
  display: inline-block;
  font-size: 15px;
  font-size: 1.5rem;
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  color: #6d6d6d;
}

.location-box__cont span:before {
  content: '';
  display: inline-block;
  font-size: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -51px -748px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
  margin: 0 10px;
  padding-top: 2px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .location-box__cont span:before {
    margin: 0 10px;
  }
}

.location-box__cont span:first-child::before {
  display: none;
}

.location-box__cont .loc-home {
  display: block;
  padding-left: 15px;
  font-size: 0;
  font-size: 0;
  height: 28px;
  line-height: 27px;
  text-indent: -9999px;
  background-image: url("../../resources/images/icons/home.png");
  background-position: 0 center;
  background-repeat: no-repeat;
  color: #666;
}

/* 프린트, url 버튼 등*/
.special-box {
  position: absolute;
  top: 10px;
  right: 0;
}

.special-box a {
  border: 1px solid #fff;
  border-radius: 100%;
}

.special-box a:focus {
  border: 1px solid gold;
}

.special-box__print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -98px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 4px;
  text-indent: -9999px;
}

.special-box__url {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -188px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
  text-indent: -9999px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .location-box__cont {
    position: relative;
    top: 3px;
    width: auto;
    text-align: right;
  }
  .sub-contents__top h2.sub-tit {
    padding-top: 0px;
  }
  .special-box {
    top: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-contents__top {
    background-image: none !important;
    margin-bottom: 30px;
  }
  .sub-contents__top h2.sub-tit {
    font-size: 21px;
    margin-bottom: 8px;
    padding-top: 10px;
    height: auto;
  }
  .location-box {
    position: relative;
    top: 0;
    right: 0;
    padding-bottom: 0px;
  }
  .location-box__cont {
    position: relative;
    white-space: normal;
    width: auto;
    text-align: left;
    overflow: visible;
    top: 0;
    left: 0;
    padding-top: 0px;
  }
  .special-box {
    display: none;
  }
}

/* checkbox */
input[class="check-type"] + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #cccccc;
  background-color: #dddddd;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
}

input[class="check-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -182px -682px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 10px;
}

input[class="check-type"]:checked + label {
  background-color: #666666;
}

input[class="check-type"]:focus + label {
  border: 2px solid gold;
}

input[class="check-type"] {
  position: absolute;
  left: -9999px;
}

/* 2 */
input[class="check-type2"] + label {
  display: inline-block;
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ddd;
}

input[class="check-type2"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -260px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
  position: absolute;
  top: 0px;
  left: 0px;
}

input[class="check-type2"]:focus + label {
  border: 1px solid #bbbbbb;
}

input[class="check-type2"] {
  position: absolute;
  left: -9999px;
}

/* 3 */
input[class="check-type3"] + label {
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ddd;
}

input[class="check-type3"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -570px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
  position: absolute;
  top: 3px;
  left: 3px;
}

input[class="check-type3"]:focus + label {
  border: 1px solid #ec6159;
}

input[class="check-type3"] {
  position: absolute;
  left: -9999px;
}

/*라디오버튼*/
input[class="radio-type"] + label {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 0;
  cursor: pointer;
  position: relative;
  font-size: 0;
  text-indent: -9999px;
}

input[class="radio-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -134px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
  position: absolute;
  top: 3px;
  left: 4px;
}

input[class="radio-type"]:checked + label:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -197px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

input[class="radio-type"] {
  position: absolute;
  left: -9999px;
}

.choose-label-text {
  margin-left: 0;
  color: #7d7d7d;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}

.choose-textarea {
  display: block;
  box-sizing: border-box;
  border: 1px solid #d5d9dd;
  background-color: #e9ecf0;
  padding: 20px;
  height: 100px;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  color: #768393;
}

.choose-textarea.long {
  height: 150px;
}

.editor_view.on .editor_view__cont {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

.editor_view.on .editor_view__cont > p {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editor_view.on .editor_view__cont > p iframe {
  width: 100%;
  height: 100%;
}

.sub-slide {
  position: relative;
  margin-bottom: 10px;
}

.sub-slide__box {
  max-width: 100%;
  margin: 0 auto;
}

.sub-slide__box img {
  width: 100%;
}

.sub-slide__nav {
  position: absolute;
  width: 50px;
  display: block;
  top: 50%;
  margin-top: -35px;
  height: 70px;
  background-color: #000;
  opacity: 0.8;
  text-indent: -9999px;
}

.sub-slide__nav:after {
  width: 20px;
  height: 27px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  color: #fff;
  text-indent: 0;
  font-size: 22px;
  font-family: "Nanum Gothic";
}

.sub-slide__nav:hover:after, .sub-slide__nav:focus:after {
  color: gold;
}

.sub-slide__prev {
  left: 0;
}

.sub-slide__prev:after {
  content: '<';
}

.sub-slide__next {
  right: 0;
}

.sub-slide__next:after {
  content: '>';
}

.sub-slide-cro {
  margin-bottom: 40px;
  margin-left: -10px;
  overflow: hidden;
}

.sub-slide-cro img {
  height: 100px;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  opacity: 0.4;
  transition: all 0.3s ease 0s;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .sub-slide-cro img {
    height: 100px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-slide-cro img {
    height: 55px !important;
  }
}

.sub-slide-cro .slick-current img {
  opacity: 1;
}

.sub-slide-cro a:focus img {
  opacity: 1;
}

/*서브 하단 담당자 정보*/
.sub-info {
  display: inline-block;
  margin-top: 80px;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0 20px 80px;
  border: 1px solid #e0e0e0;
  border-top: 1px solid #222;
  background: url(../../resources/images/icons/sub-info-img1.png) 30px center no-repeat;
}

.sub-info li {
  float: left;
  font-size: 15px;
}

.sub-info li dt {
  float: left;
  color: #275bb6;
  font-weight: 500;
  padding-right: 9px;
}

.sub-info li dt:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -264px -748px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
  padding-right: 6px;
}

.sub-info li dd {
  float: left;
  color: #484848;
  padding-right: 50px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-info {
    padding: 10px 0 10px 80px;
  }
}

/*통합검색 페이지*/
.search_zone .sub-contents__top {
  border-bottom: 0;
}

.search_zone__box {
  border-top: 2px solid #282828;
}

.search_zone__box > li > a {
  padding: 24px 0;
  display: inline-block;
  font-size: 16px;
  color: #484848;
  border-bottom: 1px solid #e8e7e7;
  width: 100%;
}

.search_zone__box > li > a ul {
  margin-top: 5px;
}

.search_zone__box > li > a ul > li {
  float: left;
}

.search_zone__box > li > a ul > li::after {
  margin-left: 10px;
  margin-right: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -60px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 10px;
  vertical-align: baseline;
}

.search_zone__box > li > a ul > li:last-child::after {
  display: none;
}

.search_zone__btn {
  margin-bottom: 55px;
  background-color: #275bb6;
  text-align: center;
  color: #fff;
  font-weight: 500;
  padding: 10px 30px;
  font-size: 16px;
  display: inline-block;
  margin-top: 20px;
  transition: all 0.4s;
}

.search_zone__btn:hover {
  background-color: #163874;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .search_zone {
    padding: 10px;
  }
}

/* pagination */
.pagination {
  margin-top: 20px;
  font-size: 0;
  text-align: center;
}

.pagination a {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  margin: 0 1px 3px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.pagination .page_arrow {
  font-size: 18px;
}

.pagination a:hover, .pagination .active {
  color: #c26aa8;
  text-decoration: underline;
}

.pagination a:focus {
  color: #c26aa8;
}

.pagination .page_arrow.l1 {
  margin-right: 40px;
}

.pagination .page_arrow.r1 {
  margin-left: 40px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  /* pagination */
  .pagination a {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
  }
  .pagination .page_arrow {
    font-size: 15px;
  }
  .pagination .page_arrow.l1 {
    margin-right: 20px;
  }
  .pagination .page_arrow.r1 {
    margin-left: 20px;
  }
}

/*탭 타입*/
.tabType1 {
  margin-bottom: 30px;
}

.tabType1 .sub-tab2__ul__li {
  margin-bottom: 0;
  width: 25%;
}

@media screen and (min-width: 1px) and (max-width: 860px) {
  .tabType1 .sub-tab2__ul__li {
    width: 50%;
    height: 51px;
    word-break: break-all;
    box-sizing: border-box;
  }
  .tabType1 .sub-tab2__ul__li a {
    font-size: 15px;
  }
}

/*탭*/
.js-box {
  display: none;
  position: relative;
  min-height: 150px;
}

.js-box.on {
  display: block;
}

/*메인탭*/
.main-tab__ul {
  padding-left: 10px;
}

.main-tab__ul__li {
  float: left;
}

.main-tab__ul__li a {
  height: 56px;
  line-height: 56px;
  display: block;
  font-size: 17px;
  font-size: 1.7rem;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  color: #ddd;
  opacity: 0.7;
}

.main-tab__ul__li.on a {
  color: #333;
  font-weight: 500;
  opacity: 1;
}

.main-tab__ul__li a:hover,
.main-tab__ul__li a:focus {
  text-decoration: underline;
}

.main-tab__cont__ul {
  border: 1px solid #e0e0e0;
}

.main-tab__cont__ul__li {
  border-top: 1px dotted #ccc;
}

.main-tab__cont__ul__li {
  border-top: 1px dotted #ccc;
}

.main-tab__cont__ul__li:first-child {
  border-top: 0;
}

.main-tab__cont__ul__li a {
  display: block;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.main-tab__cont__ul__li a:hover,
.main-tab__cont__ul__li a:focus {
  background-color: #eee;
}

.main-tab__cont__more {
  position: absolute;
  right: 18px;
  top: -42px;
  display: block;
  width: 26px;
  height: 26px;
  padding: 2px;
}

.main-tab__cont__more:focus {
  border: 2px solid gold;
  padding: 0;
}

.mtcul-cont {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000;
}

.mtcul-date {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mtcul-cont {
    width: auto;
  }
  .mtcul-date {
    display: none;
  }
}

/*서브탭*/
.sub-tab__ul:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .sub-tab__ul {
  display: inline-block;
}

.sub-tab__ul__li {
  float: left;
  display: table;
  margin-bottom: 34px;
}

.sub-tab__ul__li__a {
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-right: 0;
  padding: 0 10px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  background-color: #ffffff;
  height: 48px;
}

.sub-tab__ul__li:last-child .sub-tab__ul__li__a {
  border-right: 1px solid #e7e7e7;
}

.sub-tab__ul__li.on .sub-tab__ul__li__a {
  background-color: #275bb6;
  color: #fff;
}

/*서브탭2*/
.sub-tab2__ul:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .sub-tab__ul {
  display: inline-block;
}

.sub-tab2__ul__li {
  float: left;
  display: table;
  margin-bottom: 34px;
}

.sub-tab2__ul__li__a {
  color: #fff;
  box-sizing: border-box;
  border-right: 0;
  padding: 0 10px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  background-color: #878e96;
  height: 55px;
  border: 1.5px solid #fff;
  font-size: 16px;
}

.sub-tab2__ul__li:last-child .sub-tab2__ul__li__a {
  border-right: 1px solid #e7e7e7;
}

.sub-tab2__ul__li.on .sub-tab2__ul__li__a {
  background-color: #1260ab;
  color: #fff;
}

/* 서브탭3 링크연결용 */
.sub-tab3__ul {
  margin-bottom: 34px;
}

.sub-tab3__ul::after {
  content: "";
  display: block;
  clear: both;
}

.sub-tab3__ul__li {
  width: 25%;
  box-sizing: border-box;
  float: left;
}

.sub-tab2__ul__li:nth-child(4n) {
  border-right: 1px solid #e7e7e7;
}

.sub-tab3__ul__li a {
  color: #fff;
  box-sizing: border-box;
  border-right: 0;
  padding: 0 10px;
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 55px;
  background-color: #878e96;
  height: 55px;
  border: 1.5px solid #fff;
  font-size: 16px;
  transition: 0.3s all;
}

.sub-tab3__ul__li.on a, .sub-tab3__ul__li:hover a {
  background-color: #1260ab;
  color: #fff;
}

/* 회원가입 인트로 */
.join-wrap {
  margin-left: -20px;
}

.join-wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-wrap {
  display: inline-block;
}

.join-wrap__box {
  float: left;
}

.join-wrap__box__in {
  margin-left: 20px;
  padding-top: 60px;
  padding-bottom: 10px;
  min-height: 300px;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
}

.join-wrap__box__in__tit {
  display: block;
  font-size: 28px;
  margin-bottom: 35px;
  position: relative;
  padding-top: 80px;
}

.join-wrap__box__in__desc {
  margin-bottom: 30px;
}

.join-wrap__box__in__go {
  display: block;
  color: #fff;
  background-color: #0e5390;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.join-wrap__box__in__go:hover, .join-wrap__box__in__go:focus {
  background-color: #3174b0;
}

.join-wrap__box--1 {
  width: 50%;
}

.join-wrap__box--1 strong:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -127px -314px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-wrap__box--2 {
  width: 50%;
}

.join-wrap__box--2 strong:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -314px 0px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-wrap {
    margin-left: 0;
  }
  .join-wrap__box {
    float: none;
    margin: 0 20px;
    margin-bottom: 10px;
    width: auto;
  }
  .join-wrap__box__in {
    margin-left: 0;
  }
}

/* 회원가입 스탭
===========================*/
/*input checkbox 설정*/
input[class="check-type"] + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #cccccc;
  background-color: #dddddd;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
  font-size: 0;
  text-indent: -9999px;
}

input[class="check-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -182px -682px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 10px;
}

input[class="check-type"]:checked + label {
  background-color: #666666;
}

input[class="check-type"]:focus + label {
  border: 2px solid gold;
}

input[class="check-type"] {
  position: absolute;
  left: -9999px;
}

/* step top */
.join-step__top {
  margin-bottom: 50px;
}

.join-step__top__ul {
  margin-left: -7px;
}

.join-step__top__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__top__ul {
  display: inline-block;
}

.join-step__top__ul__li {
  float: left;
  width: 33%;
}

.join-step__top__ul__li:first-child {
  width: 34%;
}

.join-step__top__ul__li p {
  white-space: nowrap;
  background-color: #f7f8fc;
  border: 1px solid #d6dde7;
  padding: 10px 20px;
  margin-left: 7px;
  position: relative;
}

.join-step__top__ul__li p:after {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -631px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.join-step__top__ul__li.on p {
  color: #fff;
  background-color: #444444;
  border: 1px solid #444444;
}

.join-step__top__ul__li.on p:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -71px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .join-step__top__ul__li p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__top__ul__li {
    display: none;
  }
  .join-step__top__ul__li.on {
    display: block;
    width: 100%;
  }
}

/* step1 */
.join-step--1__tit {
  margin-bottom: 20px;
}

.join-step--1__tit .label-text {
  font-size: 16px;
  margin-left: 5px;
}

.join-step--1__tit.allcheck {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 60px;
}

.join-step--1__textbox {
  height: 140px;
  overflow: auto;
  margin-bottom: 30px;
  border: 1px solid #eee;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #000;
  color: #666;
}

.join-step__confirm {
  background-color: #eeeeee;
  border-top: 1px solid #000;
  padding: 50px 30px;
  text-align: center;
  margin-bottom: 40px;
}

.join-step__confirm__tit {
  display: block;
  font-size: 40px;
  margin-bottom: 20px;
}

.join-step__confirm__desc {
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.join-step__confirm__btn {
  margin-left: 24% !important;
  margin-right: 24% !important;
  margin-top: 100px !important;
  position: relative;
}

.join-step__confirm__btn {
  display: block;
  color: #fff;
  background-color: #0e5390;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.join-step__confirm__btn:hover, .join-step__confirm__btn:focus {
  background-color: #3174b0;
}

.join-step__confirm__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -228px -314px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
  position: absolute;
  top: -83px;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__info {
  margin-bottom: 20px;
}

.join-step__info__tit {
  position: relative;
  padding-left: 22px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.join-step__info__tit:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px 0px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
  position: absolute;
  left: 0;
  top: -2px;
}

.join-step__info__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__info__ul {
  display: inline-block;
}

.join-step__info__ul > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  font-weight: 400;
  color: #484848;
  font-size: 15px;
}

.join-step__info__ul > li:before {
  position: absolute;
  top: 11px;
  left: 3px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -395px -615px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 1px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__confirm__tit {
    font-size: 28px;
  }
}

/* step2 */
.serius {
  color: #d6046d;
}

.join-form {
  margin: 0 5%;
  margin-bottom: 30px;
}

.join-form__line {
  border-bottom: 1px solid #d1d1d1;
  padding: 20px 0;
  position: relative;
}

.join-form__line__tit {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 16px;
  transform: translateY(-50%);
}

.join-form__line__in {
  padding-left: 220px;
}

.join-form__line__in__input {
  border: 1px solid #000;
  height: 60px;
  width: 50%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input2 {
  border: 1px solid #000;
  height: 60px;
  width: 15%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input3 {
  border: 1px solid #000;
  height: 60px;
  width: 25%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input4 {
  border: 1px solid #000;
  height: 60px;
  width: 35%;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.join-form__line__in__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -652px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.join-form__line__in__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 130px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.join-form__line__in__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .join-form__line__in__selectbox select {
  width: 125%;
  vertical-align: top;
}

.join-form__line__in__selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__in__selectbox select {
    min-width: 100px;
  }
}

.join-form__line__in__btn {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.join-form__line__in__btn:focus {
  background-color: #333;
  color: gold;
}

.join-subs {
  margin-bottom: 30px;
}

.join-subs__box {
  background-color: #f6f6f6;
  margin-bottom: 7px;
  font-size: 15px;
  height: 58px;
  line-height: 58px;
  position: relative;
  padding-left: 20px;
}

.join-subs__box__radiobox {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.join-subs__box__radiobox .in_check {
  margin-left: 20px;
}

.ok-btn {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.ok-btn:hover, .ok-btn:focus {
  background-color: #da5b67;
  color: gold;
}

.cancel-btn {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.cancel-btn:hover, .cancel-btn:focus {
  background-color: #eee;
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__tit {
    position: relative;
    top: 0;
    left: 0;
    font-size: 16px;
    transform: translateY(0);
    margin-bottom: 5px;
  }
  .join-form__line__in {
    padding-left: 0;
  }
  .join-form__line__in__input {
    width: 100%;
    box-sizing: border-box;
  }
  .join-form__line__in__input3 {
    width: 50%;
    box-sizing: border-box;
  }
  .join-form__line__in__input2 {
    width: 30%;
    box-sizing: border-box;
  }
  .join-form__line__in__input4 {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .join-form__line__in__btn {
    margin-top: 10px;
    display: block;
    width: auto;
    text-align: center;
  }
  .join-subs__box {
    height: auto;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .join-subs__box__radiobox {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
  }
}

/* step 3 */
.join-step__final {
  text-align: center;
  padding-top: 30px;
}

.join-step__final__text1 {
  position: relative;
  padding-top: 60px;
  font-size: 22px;
  margin-bottom: 20px;
}

.join-step__final__text1 span {
  color: #d6046d;
}

.join-step__final__text1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -441px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__final__text2 {
  font-size: 15px;
  color: #666;
  margin-bottom: 80px;
}

.join-step__final__text2 strong {
  color: #000;
}

.idpass-wrap {
  border: 1px solid #333;
  width: 760px;
  box-sizing: border-box;
  margin: 0 auto;
}

.idpass-wrap__top {
  background-color: #f7f7f7;
  border-bottom: 1px solid #333;
  text-align: center;
  padding: 10px;
}

.idpass-wrap__top__list {
  margin-right: 10px;
  padding-left: 20px;
  position: relative;
  display: inline-block;
}

.idpass-wrap__top__list:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -446px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__top__list.on:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -508px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__form {
  padding: 60px;
  text-align: center;
}

.idpass-wrap__form .line-box {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.idpass-wrap__form__label {
  width: 100px;
  display: inline-block;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
}

.idpass-wrap__form__input {
  margin: 0 auto;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.idpass-wrap__form__input--1 {
  width: 476px;
}

.idpass-wrap__form__input--2 {
  width: 150px;
}

.idpass-wrap__form__select {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.idpass-wrap__form__select:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -652px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.idpass-wrap__form__select select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 150px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.idpass-wrap__form__select select::-ms-expand {
  display: none;
}

.lte-ie9 .idpass-wrap__form__select select {
  width: 125%;
  vertical-align: top;
}

.idpass-wrap__form__select select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap__form__select select {
    min-width: 120px;
  }
}

.idpass-wrap__form__submit {
  margin-top: 30px;
}

.idpass-wrap__form__submit {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.idpass-wrap__form__submit:focus {
  background-color: #333;
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap {
    width: 100%;
    text-align: left;
  }
  .idpass-wrap__form {
    padding: 30px 14px;
    text-align: left;
  }
  .idpass-wrap__form__label {
    width: 100%;
  }
  .idpass-wrap__form__input--1 {
    width: 100%;
  }
  .idpass-wrap__form__input--2 {
    width: 50%;
    margin-bottom: 5px;
  }
  .idpass-wrap__form__select {
    margin-bottom: 5px;
  }
}

.login-wrap {
  padding-top: 100px;
  text-align: center;
}

.login-wrap__desc {
  font-size: 15px;
  color: #666;
  margin-bottom: 30px;
}

.login-wrap__form {
  margin-bottom: 20px;
}

.login-wrap__form__input {
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
  width: 500px;
  border: 1px solid #000;
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.login-wrap__form__submit {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  width: 500px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
}

.login-wrap__form__submit:focus {
  background-color: #333;
  color: gold;
}

.login-wrap__form--bottom {
  width: 500px;
  margin: 0 auto;
  display: flex;
}

.login-wrap__form--bottom a {
  flex: 1;
  position: relative;
}

.login-wrap__form--bottom a:before {
  position: absolute;
  top: 5px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -851px 0px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.login-wrap__form--bottom a:first-child:before {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .login-wrap {
    padding-top: 50px;
  }
  .login-wrap__desc {
    font-size: 14px;
  }
  .login-wrap__form__input {
    width: 100%;
  }
  .login-wrap__form__submit {
    width: 100%;
  }
  .login-wrap__form--bottom {
    width: 100%;
  }
}

.guide-box__table {
  margin-bottom: 10px;
  border-top: 1px solid #000;
}

.guide-box__table table {
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  min-width: 540px;
}

.guide-box__table thead th {
  border-bottom: 0px;
}

.guide-box__table th {
  border: 1px solid #dcdcdc;
  background-color: #f4f4f4;
  padding: 10px 10px;
  word-break: break-all;
}

.guide-box__table td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

.guide-box__table .bg {
  background-color: #eee;
}

.guide-box__table.th_center th {
  text-align: center;
}

.guide-box__table.td_left td {
  text-align: left;
}

.guide-box__table.td_left2 td {
  text-align: center;
}

.guide-box__table.td_left2 td:first-child {
  text-align: left;
}

.guide-box__table.td_left3 td {
  text-align: left;
}

.guide-box__table.td_left3 td:first-child {
  text-align: center;
}

@media (max-width: 768px) {
  .guide-box__table {
    overflow: auto;
  }
  .guide-box__table.res {
    padding-top: 10px;
  }
  .guide-box__table.res table {
    min-width: auto;
  }
  .guide-box__table.res colgroup {
    display: none;
  }
  .guide-box__table.res thead {
    display: none;
  }
  .guide-box__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .guide-box__table.res th {
    display: none;
  }
  .guide-box__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .guide-box__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
  .guide-box__table td, .guide-box__table th {
    padding: 5px 8px;
  }
}

.guide-box__table table colgroup .col-1 {
  width: 40%;
}

.guide-box__table table colgroup .col-2 {
  width: 30%;
}

.guide-box__table table colgroup .col-3 {
  width: 30%;
}

.guide-box__table table td {
  text-align: left;
}

.guide-box__box {
  border: 1px solid #ddd;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px;
  margin-bottom: 20px;
}

.guide-box__box__item {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

.guide-box__box__item:last-child {
  margin-bottom: 0px;
}

.h3Type1 {
  color: #222222;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.h3Type1:before {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -656px -215px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .h3Type1 {
    font-size: 18px;
  }
}

.h3Type2 {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 18px;
  position: relative;
}

.h3Type2:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -384px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

@media (max-width: 768px) {
  .h3Type2 {
    font-size: 18px;
  }
}

.h4Type1 {
  color: #0e5390;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .h4Type1 {
    font-size: 16px;
  }
}

.h4Type2 {
  color: #000;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
  position: relative;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .h4Type2 {
    font-size: 14px;
  }
}

.h4Type2:before {
  content: ' ';
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -105px -748px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.h4Type2.ver2 {
  font-weight: normal !important;
}

.h4Type3 {
  color: #0e5390;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .h4Type3 {
    font-size: 15px;
  }
}

.btn1 {
  display: block;
  color: #fff;
  background-color: #0e5390;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.btn1:hover, .btn1:focus {
  background-color: #3174b0;
}

.btn2 {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.btn2:focus {
  background-color: #333;
  color: gold;
}

.btn3 {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn3:hover, .btn3:focus {
  background-color: #da5b67;
  color: gold;
}

.btn4 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn4:hover, .btn4:focus {
  background-color: #eee;
  color: #000;
}

.btn5 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #f43142;
  font-size: 19px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn5:hover, .btn5:focus {
  background-color: #ff0016;
  color: gold;
}

.btn6 {
  display: inline-block;
  color: #fff;
  background-color: #2e84dd;
  font-size: 14px;
  height: 33px;
  padding: 0 10px;
  text-align: center;
  line-height: 33px;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn6:hover, .btn6:focus {
  background-color: #194a80;
}

.btn7 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #000;
  font-size: 18px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn7:hover, .btn7:focus {
  background-color: #666;
  color: gold;
}

.downBtn1 {
  display: inline-block;
  color: #fff;
  background-color: #0c5493;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #0c5493;
  position: relative;
}

.downBtn1:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -64px -682px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.downBtn1:hover, .downBtn1:focus {
  background-color: #1e79c8;
  color: gold;
}

.downBtn2 {
  display: inline-block;
  color: #0c5493;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #0c5493;
  position: relative;
}

.downBtn2:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -123px -682px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.downBtn2:hover, .downBtn2:focus {
  background-color: #1e79c8;
  color: gold;
}

.btnSmall-1 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btnSmall-1:hover, .btnSmall-1:focus {
  background-color: #eee;
}

.btnSmall-2 {
  display: inline-block;
  color: #fff;
  background-color: #0e5390;
  border: 1px solid #0e5390;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btnSmall-2:hover, .btnSmall-2:focus {
  background-color: #3177b6;
}

.btnMore {
  display: inline-block;
  color: #0e5390;
  background-color: #fff;
  border: 1px solid #0e5390;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 20px 0 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btnMore:hover, .btnMore:focus {
  background-color: #eee;
}

.basicList {
  margin-bottom: 10px;
}

.basicList > li {
  text-align: left;
  position: relative;
  padding-left: 11px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.6;
}

.basicList > li:before {
  position: absolute;
  top: 10px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -244px -682px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.basicList2 {
  margin-bottom: 10px;
}

.basicList2 > li {
  font-size: 16px;
  color: #484848;
  text-align: left;
  position: relative;
  padding-left: 11px;
  line-height: 1.6;
  margin-bottom: 10px;
  font-weight: 500;
}

.basicList2 > li:before {
  position: absolute;
  top: 10px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -244px -682px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.basicList2 ol {
  list-style-type: decimal !important;
  text-align: left;
  padding-left: 10px;
  font-weight: 400;
}

.basicList3 > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  font-weight: 400;
  color: #484848;
  font-size: 15px;
}

.basicList3 > li:before {
  position: absolute;
  top: 11px;
  left: 3px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -395px -615px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 1px;
}

.basicTable1 {
  margin-bottom: 10px;
  border-top: 1px solid #000;
}

.basicTable1 table {
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  min-width: 540px;
}

.basicTable1 thead th {
  border-bottom: 0px;
}

.basicTable1 th {
  border: 1px solid #dcdcdc;
  background-color: #f4f4f4;
  padding: 10px 10px;
  word-break: break-all;
}

.basicTable1 td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

.basicTable1 .bg {
  background-color: #eee;
}

.basicTable1.th_center th {
  text-align: center;
}

.basicTable1.td_left td {
  text-align: left;
}

.basicTable1.td_left2 td {
  text-align: center;
}

.basicTable1.td_left2 td:first-child {
  text-align: left;
}

.basicTable1.td_left3 td {
  text-align: left;
}

.basicTable1.td_left3 td:first-child {
  text-align: center;
}

@media (max-width: 768px) {
  .basicTable1 {
    overflow: auto;
  }
  .basicTable1.res {
    padding-top: 10px;
  }
  .basicTable1.res table {
    min-width: auto;
  }
  .basicTable1.res colgroup {
    display: none;
  }
  .basicTable1.res thead {
    display: none;
  }
  .basicTable1.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable1.res th {
    display: none;
  }
  .basicTable1.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable1.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
  .basicTable1 td, .basicTable1 th {
    padding: 5px 8px;
  }
}

.basicTable2 {
  margin-bottom: 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  overflow: auto;
}

.basicTable2 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable2 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 13px 0;
  background-color: #f4f4f4;
  text-align: left;
  padding-left: 30px;
}

.basicTable2 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 13px 5px;
  text-align: left;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .basicTable2.res {
    padding-top: 10px;
  }
  .basicTable2.res table {
    min-width: auto;
  }
  .basicTable2.res colgroup {
    display: none;
  }
  .basicTable2.res thead {
    display: none;
  }
  .basicTable2.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable2.res th {
    display: none;
  }
  .basicTable2.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable2.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable3 {
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
  overflow: auto;
}

.basicTable3 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable3 thead th {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}

.basicTable3 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 18px 0;
  background-color: #f4f4f4;
  padding-left: 30px;
}

.basicTable3 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 18px 5px;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .basicTable3.res {
    padding-top: 10px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable3.res table {
    min-width: auto;
  }
  .basicTable3.res colgroup {
    display: none;
  }
  .basicTable3.res thead {
    display: none;
  }
  .basicTable3.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable3.res th {
    display: none;
  }
  .basicTable3.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable3.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable4 {
  margin-bottom: 30px;
  border-top: 2px solid #203c92;
  border-bottom: 1px solid #000;
  overflow: auto;
}

.basicTable4 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
  margin-left: -1px;
}

.basicTable4 thead th {
  border-bottom: 1px solid #203c92;
}

.basicTable4 th {
  border-left: 1px solid #dcdcdc;
  padding: 13px 0;
  background-color: #fbfcff;
  text-align: center;
  color: #000;
}

.basicTable4 tbody th {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  border-right: 0px;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

.basicTable4 td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  border-right: 0px;
  border-bottom: 0px;
}

.basicTable4 td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.basicTable4 td.sbj-line > a:hover, .basicTable4 td.sbj-line > a:focus {
  text-decoration: underline;
}

.basicTable4 td > a:hover, .basicTable4 td > a:focus {
  text-decoration: underline;
}

.basicTable4 td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .basicTable4.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable4.res table {
    min-width: auto;
  }
  .basicTable4.res colgroup {
    display: none;
  }
  .basicTable4.res thead {
    display: none;
  }
  .basicTable4.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .basicTable4.res th {
    display: none;
  }
  .basicTable4.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .basicTable4.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .basicTable4.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .basicTable4.res td.align-left {
    padding-left: 10px;
  }
}

.basicTable5 {
  text-align: center;
  margin-bottom: 30px;
  max-width: 100%;
  overflow: auto;
}

.basicTable5 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable5 thead th {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}

.basicTable5 th {
  border: 1px solid #3774a9;
  padding: 8px 0;
  background-color: #0e5390;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
  color: #fff;
}

.basicTable5 td {
  border: 1px solid #dcdcdc;
  padding: 8px 2px;
  text-align: center;
}

@media (max-width: 768px) {
  .basicTable5.res {
    padding-top: 10px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable5.res table {
    min-width: auto;
  }
  .basicTable5.res colgroup {
    display: none;
  }
  .basicTable5.res thead {
    display: none;
  }
  .basicTable5.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable5.res th {
    display: none;
  }
  .basicTable5.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable5.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -652px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox select {
  width: 125%;
  vertical-align: top;
}

.selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .selectbox select {
    min-width: 200px;
  }
}

.selectbox2 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  text-align: left;
  border-bottom: 2px solid #000;
}

.selectbox2:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -652px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.selectbox2 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox2 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox2 select {
  width: 125%;
  vertical-align: top;
}

.selectbox2 select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .selectbox2 select {
    min-width: 200px;
  }
}

.txtType1 {
  font-size: 14px;
  color: #686868;
  background-color: #eaf2fc;
  border: 1px solid #7896cf;
  padding: 20px 25px;
}

.pType1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 2px;
}

.olType1 {
  list-style-type: decimal !important;
  text-align: left;
  padding-left: 10px;
  font-weight: 400;
  font-size: 15px;
}

/*인사말*/
.greetings {
  background: url(../../resources/images/cont/greetings-img1.jpg) left top no-repeat;
  padding-top: 30px;
}

.greetings img {
  padding-bottom: 25px;
  padding-left: 28%;
}

.greetings p {
  padding-left: 338px;
  padding-bottom: 30px;
  font-size: 16px;
}

@media screen and (max-width: 600px) and (min-width: 1px) {
  .greetings {
    text-align: center;
    background: none;
    padding-top: 0;
    background-size: 200px;
  }
  .greetings img {
    padding-left: 0;
  }
  .greetings p {
    padding-left: 0;
    text-align: left;
  }
}

/*역대교육장*/
.history-people {
  background: url(../../resources/images/cont/history-people-bg.png) left top no-repeat;
  display: inline-block;
  padding-left: 20px;
}

.history-people li {
  float: left;
  width: 33.33%;
  padding-bottom: 70px;
}

.history-people li dl {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  max-width: 214px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.history-people li dl dd {
  color: #333333;
  font-size: 14px;
  background-color: #f5f5f5;
  padding: 8px 0;
}

.history-people li dl dd p {
  font-weight: 600;
  font-size: 17px;
}

.history-people li img {
  height: 128px;
  padding: 20px 0;
}

@media screen and (max-width: 730px) and (min-width: 1px) {
  .history-people {
    padding-left: 0px;
  }
  .history-people li {
    width: 50%;
    padding-bottom: 10px;
  }
  .history-people li dl {
    margin: 0 auto;
    max-width: 95%;
  }
}

@media screen and (max-width: 390px) and (min-width: 1px) {
  .history-people li {
    width: 100%;
  }
}

/*교육중점*/
.focus-education {
  text-align: center;
}

.focus-education li {
  width: 25%;
  float: left;
}

.focus-education img {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 940px) and (min-width: 1px) {
  .focus-education li {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) and (min-width: 1px) {
  .focus-education li {
    width: 100%;
    margin-bottom: 10px;
  }
}

/*교육중점-시흥교육*/
.focus-education2 li {
  width: 25%;
  float: left;
  text-align: center;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 200px;
}

.focus-education2 li dt {
  font-size: 25px;
  color: #222222;
}

.focus-education2 li dd {
  font-size: 15px;
  color: #3a3a3a;
  padding-top: 15px;
}

.focus-education2__in1 {
  background-image: url(../../resources/images/cont/focus-education2-img1.jpg);
}

.focus-education2__in2 {
  background-image: url(../../resources/images/cont/focus-education2-img2.jpg);
}

.focus-education2__in3 {
  background-image: url(../../resources/images/cont/focus-education2-img3.jpg);
}

.focus-education2__in4 {
  background-image: url(../../resources/images/cont/focus-education2-img4.jpg);
}

@media screen and (max-width: 680px) and (min-width: 1px) {
  .focus-education2 li {
    width: 50%;
    margin-bottom: 30px;
    padding: 200px 20px 0 20px;
    box-sizing: border-box;
    height: 340px;
  }
}

@media screen and (max-width: 440px) and (min-width: 1px) {
  .focus-education2 li {
    width: 100%;
  }
}

/*특색교육*/
.feature-edu {
  text-align: center;
}

.feature-edu__tit {
  color: #414346;
  font-size: 23px;
  font-weight: 500;
  display: inline-block;
  padding: 0 25px;
  background-image: url(../../resources/images/cont/feature-edu-img1.png), url(../../resources/images/cont/feature-edu-img2.png);
  background-repeat: no-repeat;
  background-position: left center, right center;
  background-size: contain;
}

.feature-edu__in {
  margin-top: 30px;
  display: block;
}

.feature-edu__in li {
  float: left;
  width: 33.33%;
}

@media screen and (max-width: 800px) and (min-width: 1px) {
  .feature-edu__in li {
    padding: 0 5px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 600px) and (min-width: 1px) {
  .feature-edu__tit {
    font-size: 19px;
  }
  .feature-edu__in li {
    width: 50%;
  }
}

@media screen and (max-width: 400px) and (min-width: 1px) {
  .feature-edu__in li {
    width: 100%;
    padding: 0;
  }
}

/*내부시설 안내*/
.office-layout li {
  display: block;
  border: 1px solid #919191;
  background-color: #f6f7f8;
  padding-top: 24px;
  padding-bottom: 102px;
  margin-bottom: 50px;
}

.office-layout li dt {
  color: #5b5b5b;
  font-size: 40px;
  font-weight: bold;
  padding-left: 27px;
}

.office-layout li dd {
  text-align: center;
  margin-top: 25px;
  padding: 0 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) and (min-width: 1px) {
  .office-layout li {
    margin-bottom: 30px;
    padding-bottom: 50px;
  }
  .office-layout li dt {
    font-size: 30px;
    padding-left: 15px;
  }
  .office-layout li dd {
    padding: 0 15px;
  }
}

.sub-slide {
  position: relative;
  margin-bottom: 10px;
}

.sub-slide .slick-prev {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 50px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 0;
  text-indent: -9999px;
}

.sub-slide .slick-prev::before {
  content: "";
  display: block;
  height: 72px;
  background-image: url(../../resources/images/cont/sub-slide-prev.png);
}

.sub-slide .slick-next {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 50px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 0;
  text-indent: -9999px;
}

.sub-slide .slick-next::before {
  content: "";
  display: block;
  height: 72px;
  background-image: url(../../resources/images/cont/sub-slide-next.png);
}

.sub-slide__box {
  max-width: 100%;
  margin: 0 auto;
}

.sub-slide__box img {
  width: 100%;
  max-height: 600px;
}

.sub-slide__prev {
  left: 0;
}

.sub-slide__prev:after {
  content: '<';
}

.sub-slide__next {
  right: 0;
}

.sub-slide__next:after {
  content: '>';
}

/*찾아오시는 길*/
.way-to-come > ul {
  margin-top: 35px;
  background-color: #fbfbfb;
  border-top: 2px solid #abb6c2;
  border-bottom: 2px solid #abb6c2;
  display: flex;
}

.way-to-come > ul li {
  flex: 1;
}

.way-to-come > ul li dl {
  color: #353535;
  padding: 20px 10px 20px 55px;
  background-repeat: no-repeat;
  background-position: 18px 18px;
  margin-bottom: 30px;
}

.way-to-come > ul li dl dt {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.way-to-come > ul li dl dd {
  font-size: 16px;
}

.way-to-come > ul > li:first-child {
  border-right: 1px solid #e7e8e9;
}

.way-to-come > ul > li:last-child dl {
  margin-bottom: 0;
}

.way-to-come__dl1 {
  background-image: url(../../resources/images/cont/way-to-come-img1.png);
}

.way-to-come__dl2 {
  background-image: url(../../resources/images/cont/way-to-come-img2.png);
}

.way-to-come__dl3 {
  background-image: url(../../resources/images/cont/way-to-come-img3.png);
}

.way-to-come__dl4 {
  background-image: url(../../resources/images/cont/way-to-come-img4.png);
}

.way-to-come-list li {
  margin-left: -10px;
  background: url(../../resources/images/cont/way-to-come-list.png) left center no-repeat;
  padding-left: 10px;
}

@media screen and (max-width: 500px) and (min-width: 1px) {
  .way-to-come > ul {
    display: block;
  }
  .way-to-come > ul li dl {
    margin-bottom: 10px;
  }
  .way-to-come > ul > li:first-child {
    border-right: 0;
  }
  .way-to-come > ul > li:last-child dl {
    margin-bottom: 10px;
  }
}

.innovation-edu__img {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.innovation-edu__img li:first-child img {
  margin-bottom: 50px;
}

.innovation-edu__img li:nth-child(2) img {
  margin-bottom: 50px;
}

.jojik-tb {
  background-color: #fff;
}

.jojik-tb {
  margin-bottom: 10px;
  border-top: 1px solid #000;
}

.jojik-tb table {
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  min-width: 540px;
}

.jojik-tb thead th {
  border-bottom: 0px;
}

.jojik-tb th {
  border: 1px solid #dcdcdc;
  background-color: #f4f4f4;
  padding: 10px 10px;
  word-break: break-all;
}

.jojik-tb td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

.jojik-tb .bg {
  background-color: #eee;
}

.jojik-tb.th_center th {
  text-align: center;
}

.jojik-tb.td_left td {
  text-align: left;
}

.jojik-tb.td_left2 td {
  text-align: center;
}

.jojik-tb.td_left2 td:first-child {
  text-align: left;
}

.jojik-tb.td_left3 td {
  text-align: left;
}

.jojik-tb.td_left3 td:first-child {
  text-align: center;
}

@media (max-width: 768px) {
  .jojik-tb {
    overflow: auto;
  }
  .jojik-tb.res {
    padding-top: 10px;
  }
  .jojik-tb.res table {
    min-width: auto;
  }
  .jojik-tb.res colgroup {
    display: none;
  }
  .jojik-tb.res thead {
    display: none;
  }
  .jojik-tb.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .jojik-tb.res th {
    display: none;
  }
  .jojik-tb.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .jojik-tb.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
  .jojik-tb td, .jojik-tb th {
    padding: 5px 8px;
  }
}

.jojik-tb .left-td {
  text-align: left;
  padding-left: 20px;
}

.new-js-box {
  display: none;
}

.new-js-box.on {
  display: block;
}

.noafter:after {
  display: none !important;
}

.jojikdo {
  min-height: 1080px;
  background: url(../../resources/images/cont/jojikdo-bg.jpg) center 120px no-repeat;
}

.jojikdo__top {
  position: relative;
}

.jojikdo__top__jang {
  box-sizing: border-box;
  display: block;
  width: 120px;
  height: 120px;
  color: #2961c9;
  background-image: url("../../resources/images/cont/jojik-item1-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
  line-height: 120px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  position: relative;
  margin-bottom: 87px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__top__jang {
    margin-bottom: 0px;
    margin: 0 auto;
  }
}

.jojikdo__nav {
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav {
    margin-top: 40px;
  }
}

.jojikdo__nav__ctg {
  width: 900px;
  min-height: 800px;
  margin: 0 auto;
  position: relative;
}

.jojikdo__nav__ctg.open {
  padding-bottom: 0;
}

.jojikdo__nav__ctg:after {
  position: absolute;
  content: '';
  border-left: 1px solid #ccc;
  height: 658px;
  left: 4px;
  top: 0;
  display: block !important;
}

.jojikdo__nav__ctg:before {
  position: absolute;
  content: '';
  border-left: 1px dashed #ccc;
  height: 242px;
  left: 130px;
  top: 390px;
  display: block !important;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__ctg {
    width: 100%;
    min-height: 50px;
  }
  .jojikdo__nav__ctg:after {
    content: ' ';
    clear: both;
    display: block;
    visibility: hidden;
  }
  * + html .jojikdo__nav__ctg {
    display: inline-block;
  }
}

.jojikdo__nav__ctg__link {
  position: absolute;
  top: 0;
  display: block;
  width: 162px;
  text-align: center;
  background-color: #3361ae;
  background-size: cover;
  height: 48px;
  line-height: 48px;
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
}

.jojikdo__nav__ctg__link.num--1 {
  left: 50px;
}

.jojikdo__nav__ctg__link.num--2 {
  left: 50px;
  top: 342px;
}

.jojikdo__nav__ctg__link.num--3 {
  left: 50px;
  top: 632px;
}

.jojikdo__nav__ctg__link:before {
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -120px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 9px;
  position: absolute;
  top: 20px;
  z-index: 1;
  left: -46px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.jojikdo__nav__ctg__link:after {
  content: ' ';
  position: absolute;
  top: 24px;
  left: -23px;
  transform: translateX(-50%);
  display: block;
  width: 46px;
  height: 1px;
  background-color: #ccc;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__ctg__link:after {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__ctg__link {
    position: relative;
    width: 100%;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    margin-left: auto !important;
    margin-bottom: 5px;
  }
}

.jojikdo__nav__box.js-box {
  display: block;
  position: static;
  min-height: 0;
}

.jojikdo__nav__box .cost_list {
  width: 162px;
  position: absolute;
  top: 0;
  left: 222px;
}

.jojikdo__nav__box .cost_list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .jojikdo__nav__box .cost_list {
  display: inline-block;
}

.jojikdo__nav__box .cost_list li {
  margin-bottom: 5px;
  position: relative;
}

.jojikdo__nav__box .cost_list li > a {
  display: block;
  width: 162px;
  margin: 0 auto;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background-color: #5d656f;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  word-break: normal;
  color: #fff;
  font-size: 15px;
}

.jojikdo__nav__box .cost_list li > a:hover {
  opacity: 0.8;
}

.jojikdo__nav__box .cost_list li > a:hover .new-js-list__in {
  background-image: url("../../resources/images/cont/jojik-item1-img6.jpg");
}

.jojikdo__nav__box .cost_list li > a .new-js-list__in {
  position: absolute;
  left: 159px;
  text-align: center;
  font-size: 12px;
  top: 6px;
  width: 116px;
  line-height: 40px;
  height: 40px;
  background-image: url("../../resources/images/cont/jojik-item1-img4.jpg");
}

.jojikdo__nav__box .cost_list li > a .new-js-list__in:before {
  content: '';
  background-color: #ccc;
  width: 12px;
  height: 1px;
  position: absolute;
  left: -12px;
  top: 20px;
}

.jojikdo__nav__box .cost_list li.on .new-js-list__in {
  background-image: url("../../resources/images/cont/jojik-item1-img6.jpg");
}

.jojikdo__nav__box .cost_list li.on a {
  opacity: 0.9;
}

.jojikdo__nav__box .cost_box {
  min-height: 500px;
  padding-top: 0;
}

.jojikdo__nav__box .cost_box__tit {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 18px;
  position: relative;
}

.jojikdo__nav__box .cost_box__tit:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -384px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

@media (max-width: 768px) {
  .jojikdo__nav__box .cost_box__tit {
    font-size: 18px;
  }
}

.jojikdo__nav div .cost_list.type2 {
  top: 343px;
}

.jojikdo__nav div .cost_list.type3 {
  top: 633px;
}

@media screen and (min-width: 0\0) {
  .jojikdo__nav__box .cost_list li a {
    letter-spacing: 0;
    writing-mode: lr-tb;
    text-align: center;
  }
}

.jojikdo__nav__ctg.special:after {
  display: none;
}

.jojikdo__nav__jang {
  display: none;
  position: absolute;
  top: 777px;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__jang {
    position: relative;
    top: 0;
  }
}

.jojikdo__nav__jang.on {
  display: block;
}

.cost-big {
  display: none;
}

.cost-big__tit {
  background-color: #fff;
}

.cost-big__tit {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 18px;
  position: relative;
}

.cost-big__tit:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -384px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

@media (max-width: 768px) {
  .cost-big__tit {
    font-size: 18px;
  }
}

.cost-big.on {
  display: block;
  top: 200px;
  left: 0;
  width: 100%;
  margin-top: 50px;
}

/*조직도 모바일*/
@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo {
    min-height: auto;
  }
  .jojikdo__nav__box .cost_list {
    position: relative;
    width: 100%;
    top: auto;
    right: auto;
    left: auto !important;
  }
  .jojikdo__nav__box .cost_list:before {
    display: none;
  }
  .jojikdo__nav div:last-child .cost_list:before {
    display: none;
  }
  .jojikdo__nav div:last-child .cost_list {
    right: auto;
  }
  .jojikdo__nav div .cost_list li {
    width: 100%;
    margin-left: 0;
  }
  .jojikdo__nav div .cost_list li a {
    width: 100%;
    background-image: none;
    background-color: #888;
    box-sizing: border-box;
    text-align: left;
  }
  .jojikdo__nav div .cost_list li a:focus {
    background-image: none;
  }
  .new-js-list__in {
    text-align: center;
    left: auto !important;
    right: 6px;
    background-size: 101%;
  }
  .new-js-list__in:before {
    display: none;
  }
  .jojikdo__nav__box .cost_list li.on a {
    background-size: 100%;
  }
  .jojikdo__nav div .cost_list li a:hover {
    background-size: 100%;
  }
  .jojikdo__nav div:last-child .cost_list li {
    margin-left: 0;
  }
  .cost-big {
    display: none;
  }
  .jojikdo__nav__ctg__link.num--3 {
    width: 100%;
  }
  .jojikdo__nav__box.js-box.on {
    padding-top: 10px;
  }
  .cost-big.on {
    margin-top: 0px;
  }
  .jojikdo__nav__ctg__link.on {
    color: gold;
  }
  .jojikdo__nav__ctg__link:before {
    display: none !important;
  }
  .new-js-list.ver2 a:before {
    margin-left: 5px;
  }
}

/*조직도 211231 추가*/
.jojikdo__nav__box .cost_list li:first-child a {
  background-color: #3e9f8b;
  position: relative;
}

.jojikdo__nav__box .cost_list li:first-child a:before {
  content: '';
  width: 10px;
  height: 1px;
  position: absolute;
  left: -10px;
  top: 50%;
  background-color: #ccc;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_1 .cost_list {
  left: 222px;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_2 .cost_list {
  left: 394px;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_3 .cost_list {
  left: 566px;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_4 .cost_list {
  left: 740px;
}

.jojikdo__nav__box .cost_list li:before {
  content: '';
  width: 1px;
  height: 5px;
  background-color: #ccc;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: 5px;
}

.new-js-list.ver2 a {
  background-color: #3e9f8b !important;
  margin-top: 30px !important;
  position: relative;
}

.new-js-list.ver2 a:before {
  content: '';
  width: 1px;
  height: 30px;
  background-color: #ccc;
  position: absolute;
  top: -30px;
  left: 50%;
}

.jojikdo__nav__box .cost_list li:first-child:before {
  display: none;
}

/* 연혁 */
.years--info {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 90px;
}

.years--info strong {
  color: #275bb6;
}

.years {
  margin-left: 180px;
  margin-bottom: 30px;
}

.years__box {
  border-left: 2px solid #d8d8d8;
  padding-left: 38px;
  position: relative;
  padding-bottom: 60px;
}

.years__box:last-child {
  padding-bottom: 0px;
  border-left: 0px;
  padding-left: 40px;
}

.years__box__num {
  position: absolute;
  top: -21px;
  left: -180px;
  background-color: #275bb6;
  color: #fff;
  font-size: 24px;
  width: 130px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  border-radius: 30px;
}

.years__box__num:before {
  content: ' ';
  display: block;
  width: 46px;
  height: 2px;
  background-color: #d8d8d8;
  position: absolute;
  right: -46px;
  top: 21px;
}

.years__box__num:after {
  position: absolute;
  right: -56px;
  top: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -132px -615px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.years__box__ul {
  position: relative;
  top: -14px;
}

.years__box__ul__li {
  font-size: 17px;
  position: relative;
  padding-left: 130px;
  margin-bottom: 15px;
}

.years__box__ul__li em {
  position: absolute;
  top: 0;
  left: 20px;
  color: #275bb6;
}

@media screen and (min-width: 1px) and (max-width: 550px) {
  .years {
    margin-left: 0px;
  }
  .years__box__num {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 20px;
  }
  .years__box__num:before {
    display: none;
  }
  .years__box__num:after {
    display: none;
  }
  .years__box {
    border-left: 0px;
    padding-left: 0;
  }
  .years__box:last-child {
    padding-left: 0;
  }
  .years__box__ul {
    top: 0px;
  }
  .years__box__ul__li {
    font-size: 14px;
    padding-left: 100px;
  }
}

/*혁신교육지구 콜센터*/
.call-center p {
  font-size: 15px;
  color: #484848;
}

.call-center-call {
  text-align: left;
  padding-left: 30px;
}

.call-center-call li {
  padding-left: 30px;
}

.call-center-call li.call {
  background: url(../../resources/images/icons/call-center-img1.png) left center no-repeat;
}

.call-center-call li.mail {
  background: url(../../resources/images/icons/call-center-img2.png) left center no-repeat;
}

.call-center-operate {
  margin-top: 30px;
  padding-left: 50%;
  background: url(../../resources/images/cont/call-center-img1.png) left center no-repeat;
}

.call-center-operate .basicList > li {
  margin-bottom: 10px;
}

@media screen and (min-width: 1px) and (max-width: 620px) {
  .call-center-operate {
    background: none;
    margin-top: 0;
    padding-left: 0;
  }
}

/*민원 신청*/
.minwon__step {
  text-align: center;
}

.minwon__step li {
  display: inline-block;
  padding-left: 97px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #666666;
  font-size: 16px;
  text-align: left;
  background-position: left center;
  background-repeat: no-repeat;
}

.minwon__step li p {
  color: #484848;
  font-weight: 500;
  font-size: 18px;
}

.minwon__step li:first-child {
  background-image: url(../../resources/images/cont/minwon-img1.png);
}

.minwon__step li:last-child {
  margin-left: 70px;
  position: relative;
  background-image: url(../../resources/images/cont/minwon-img2.png);
}

.minwon__step li:last-child::before {
  content: '';
  position: absolute;
  background: url(../../resources/images/cont/minwon-img3.png);
  height: 20px;
  width: 12px;
  display: inline-block;
  left: -40px;
  top: 50%;
  margin-top: -12px;
}

.minwon .agr_pt {
  height: 240px;
  padding: 20px 30px;
  overflow-y: scroll;
  border: 1px solid #e7e7e7;
}

.minwon .agr_pt dt {
  color: #484848;
  font-weight: 500;
  font-size: 18px;
}

.minwon .agr_pt dd {
  color: #666666;
  font-size: 16px;
  padding: 20px 0;
}

.minwon .agr_pt li {
  padding: 10px 0;
}

.minwon .agree_sel {
  color: #484848;
  font-size: 16px;
  margin-top: 10px;
}

.minwon .agree_sel input {
  margin-right: 5px;
}

.minwon__login {
  display: inline-block;
  width: 100%;
}

.minwon__login li {
  float: left;
  width: 25%;
  text-align: center;
}

.minwon__login li a {
  padding-top: 140px;
  display: inline-block;
  background-position: top center;
  background-repeat: no-repeat;
}

.minwon__login li p {
  color: #484848;
  border: 1px solid #e7e7e7;
  padding: 10px 32px;
  display: inline-block;
}

.minwon__login__in1 {
  background-image: url(../../resources/images/cont/minwon-img4.png);
}

.minwon__login__in2 {
  background-image: url(../../resources/images/cont/minwon-img5.png);
}

.minwon__login__in3 {
  background-image: url(../../resources/images/cont/minwon-img6.png);
}

.minwon__login__in4 {
  background-image: url(../../resources/images/cont/minwon-img7.png);
}

@media screen and (min-width: 1px) and (max-width: 780px) {
  .minwon__login {
    display: flex;
  }
  .minwon__login li {
    margin-bottom: 20px;
    padding: 0 5px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .minwon__login {
    display: table;
  }
  .minwon__login li {
    width: 50%;
    font-size: 14px;
  }
  .minwon__login li p {
    height: 40px;
    display: table-cell;
    vertical-align: middle;
  }
  .minwon__step {
    text-align: center;
  }
  .minwon__step li {
    padding-left: 60px;
    background-size: 50px;
    font-size: 14px;
  }
  .minwon__step li p {
    font-size: 16px;
  }
  .minwon__step li:last-child {
    margin-left: 40px;
  }
  .minwon__step li:last-child::before {
    left: -25px;
  }
  .minwon__agr_box p {
    font-size: 16px;
    padding: 0 10px;
  }
}

/*각종증명서 신청_이용안내*/
.certificate-box1 {
  border: 1px solid #e7e7e7;
}

.certificate-box1 li {
  float: left;
  position: relative;
  width: 33.33%;
  text-align: center;
  height: 65px;
  padding-top: 140px;
  padding-bottom: 25px;
  font-size: 14px;
  color: #484848;
  background-position: center 30px;
  background-repeat: no-repeat;
}

.certificate-box1 li p {
  font-size: 16px;
  font-weight: 500;
  color: #275bb6;
}

.certificate-box1 li::before {
  content: '';
  width: 42px;
  position: absolute;
  display: inline-block;
  height: 36px;
  right: -20px;
  top: 50%;
  margin-top: -22px;
  background: url(../../resources/images/cont/cerificate-img11.png) center center no-repeat;
}

.certificate-box1 li:first-child {
  background-image: url(../../resources/images/cont/cerificate-img1.png);
}

.certificate-box1 li:nth-child(2) {
  background-image: url(../../resources/images/cont/cerificate-img2.png);
}

.certificate-box1 li:last-child {
  background-image: url(../../resources/images/cont/cerificate-img3.png);
}

.certificate-box1 li:last-child::before {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .certificate-box1 li {
    background-size: 60px;
    background-position: center 40px;
    padding-top: 110px;
    padding-bottom: 35px;
  }
  .certificate-box1 li::before {
    width: 32px;
    background-size: contain;
  }
}

.certificate-box2 {
  display: inline-block;
}

.certificate-box2 li {
  padding-bottom: 45px;
  margin-top: 20px;
}

.certificate-box2 li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.certificate-box2 li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.certificate-box2 li dl dd {
  font-size: 15px;
}

.certificate-box2 li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.certificate-box2 li:last-child dl::before {
  display: none;
}

.certificate-box2 li:first-child {
  background: url(../../resources/images/cont/cerificate-img4.png) top left no-repeat;
}

.certificate-box2 li:nth-child(2) {
  background: url(../../resources/images/cont/cerificate-img5.png) top left no-repeat;
}

.certificate-box2 li:nth-child(3) {
  background: url(../../resources/images/cont/cerificate-img6.png) top left no-repeat;
}

.certificate-box2 li:nth-child(4) {
  background: url(../../resources/images/cont/cerificate-img7.png) top left no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .certificate-box2 li dl {
    height: auto;
  }
  .certificate-box2 li dl::before {
    bottom: -20%;
  }
}

.certificate-box3 {
  display: inline-block;
}

.certificate-box3 li {
  padding-bottom: 45px;
  margin-top: 20px;
}

.certificate-box3 li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.certificate-box3 li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.certificate-box3 li dl dd {
  font-size: 15px;
}

.certificate-box3 li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.certificate-box3 li:last-child dl::before {
  display: none;
}

.certificate-box3 li:first-child {
  background: url(../../resources/images/cont/cerificate-img4.png) top left no-repeat;
}

.certificate-box3 li:nth-child(2) {
  background: url(../../resources/images/cont/cerificate-img5.png) top left no-repeat;
}

.certificate-box3 li:nth-child(3) {
  background: url(../../resources/images/cont/cerificate-img6.png) top left no-repeat;
}

.certificate-box3 li:nth-child(4) {
  background: url(../../resources/images/cont/cerificate-img12.png) top left no-repeat;
}

.certificate-box3 li:nth-child(5) {
  background: url(../../resources/images/cont/cerificate-img8.png) top left no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .certificate-box3 li dl {
    height: auto;
  }
  .certificate-box3 li dl::before {
    bottom: -50px;
  }
}

.certificate-box4 {
  display: inline-block;
}

.certificate-box4 li {
  padding-bottom: 45px;
  margin-top: 20px;
  background: url(../../resources/images/cont/cerificate-img4.png) top left no-repeat;
}

.certificate-box4 li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.certificate-box4 li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 17px;
}

.certificate-box4 li dl dd {
  font-size: 15px;
}

.certificate-box4 li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.certificate-box4 li:last-child dl::before {
  display: none;
}

/*교습소 설립의 절차안내*/
.academy-guide {
  overflow: hidden;
  border: 1px solid #e7e7e7;
  text-align: center;
}

.academy-guide li {
  float: left;
  position: relative;
  width: 20%;
  text-align: center;
  height: 65px;
  padding-top: 140px;
  padding-bottom: 75px;
  font-size: 14px;
  color: #484848;
  background-position: center 30px;
  background-repeat: no-repeat;
}

.academy-guide li p {
  font-size: 16px;
  font-weight: 500;
  color: #275bb6;
}

.academy-guide li::before {
  content: '';
  width: 42px;
  position: absolute;
  display: inline-block;
  height: 36px;
  right: -20px;
  top: 50%;
  margin-top: -50px;
  background: url(../../resources/images/cont/cerificate-img11.png) center center no-repeat;
}

.academy-guide li:first-child {
  background-image: url(../../resources/images/cont/classroom-guide-img2.png);
}

.academy-guide li:nth-child(2) {
  background-image: url(../../resources/images/cont/classroom-guide-img3.png);
}

.academy-guide li:nth-child(3) {
  background-image: url(../../resources/images/cont/classroom-guide-img4.png);
}

.academy-guide li:nth-child(4) {
  background-image: url(../../resources/images/cont/classroom-guide-img5.png);
}

.academy-guide li:nth-child(5) {
  background-image: url(../../resources/images/cont/classroom-guide-img6.png);
}

.academy-guide li:nth-child(5)::before {
  display: none;
}

.academy-guide__bottom {
  border-radius: 5px;
  border: 5px solid #d5e6f8;
  display: inline-block;
  padding: 14px 20px;
  font-size: 16px;
  margin-bottom: 30px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .academy-guide {
    background: none !important;
  }
  .academy-guide li {
    padding-bottom: 0;
    width: 33.33%;
    margin-left: -15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .academy-guide li {
    background-size: 90px;
    background-position: center 40px;
    padding-top: 140px;
    padding-bottom: 0;
    width: 50%;
    height: 45px;
  }
  .academy-guide li::before {
    width: 32px;
    background-size: contain;
    right: -10px;
    margin-top: -27px;
  }
}

.academy-guide2 {
  text-align: left;
}

.academy-guide2 .academy-guide__bottom {
  margin-left: 20px;
}

@media screen and (min-width: 1px) and (max-width: 700px) {
  .academy-guide2 .academy-guide__bottom {
    display: block;
    margin: 0 10px;
    margin-bottom: 10px;
  }
}

/*유치원업무안내_설립안내*/
.kinder-box {
  display: block;
}

.kinder-box li {
  padding-bottom: 45px;
  margin-top: 20px;
}

.kinder-box li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.kinder-box li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.kinder-box li dl dd {
  font-size: 15px;
}

.kinder-box li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.kinder-box li:last-child dl::before {
  display: none;
}

.kinder-box li:first-child {
  background: url(../../resources/images/cont/kinder-img1.png) top left no-repeat;
}

.kinder-box li:nth-child(2) {
  background: url(../../resources/images/cont/kinder-img2.png) top left no-repeat;
}

.kinder-box li:nth-child(3) {
  background: url(../../resources/images/cont/kinder-img3.png) top left no-repeat;
}

.kinder-box li:nth-child(4) {
  background: url(../../resources/images/cont/kinder-img4.png) top left no-repeat;
}

.kinder-box li:nth-child(5) {
  background: url(../../resources/images/cont/kinder-img5.png) top left no-repeat;
}

.kinder-box li:nth-child(6) {
  background: url(../../resources/images/cont/kinder-img6.png) top left no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .kinder-box li {
    padding-bottom: 80px;
  }
}

/*학원평생교육시설안내 - 학원설립운영안내*/
.academy-oparition__map {
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.academy-oparition__map ul {
  display: inline-block;
}

.academy-oparition__map ul li {
  float: left;
  box-sizing: border-box;
}

.academy-oparition__map ul li img {
  width: 100%;
}

.academy-oparition__map ul li:first-child {
  padding-right: 60px;
}

@media screen and (min-width: 1px) and (max-width: 769px) {
  .academy-oparition__map {
    padding: 0;
  }
  .academy-oparition__map ul li {
    float: none;
    padding: 30px 10px 20px 10px !important;
  }
  .academy-oparition__map ul li:first-child {
    padding-right: 0;
    padding-bottom: 20px;
  }
}

/* epki 인증서 로그인 */
.epki-login {
  border: 2px solid #1c5a94;
  padding: 45px 20px;
  text-align: center;
  background-color: #fafbfc;
  color: #3d3d3d;
}

.epki-login__tit {
  font-size: 24px;
  font-weight: 600;
}

.epki-login__txt {
  font-size: 18px;
  padding-top: 150px;
  background: url(../../resources/images/cont/epki-img1.png) top center no-repeat;
  margin-bottom: 40px;
  margin-top: 40px;
}

.epki-login__btn {
  background-color: #1c5a94;
  color: #fff;
  padding: 20px 90px;
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .epki-login__tit {
    font-size: 20px;
  }
  .epki-login__txt {
    font-size: 16px;
  }
  .epki-login__btn {
    font-size: 16px;
    padding: 15px 40px;
  }
}

/*학생봉사활동*/
.volunteer__go {
  margin-top: 80px;
  display: inline-block;
}

.txtType-new {
  padding: 20px;
  background-color: #e8f0f8;
  border: 1px dashed #999;
  border-left: 0;
  border-right: 0;
  margin-bottom: 30px;
}

.txtType-new a:hover, .txtType-new a:focus {
  text-decoration: underline;
}

.blue-tit {
  padding-left: 20px;
  color: #21539b;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}

.h4Type5 {
  text-align: left;
  position: relative;
  padding-left: 12px;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.7;
  font-weight: 500;
  margin-left: 7px;
  margin-bottom: 10px;
}

.h4Type5:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -244px -682px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.txtType-new2 {
  padding: 25px;
  border: 1px solid #ddd;
  margin-bottom: 30px;
}

.sp-edu {
  font-size: 16px;
  color: #000;
}

.sp-edu .txtType1 {
  font-size: 16px;
  color: #000;
  margin-bottom: 30px;
}

.edu-produce {
  text-align: center;
  width: 82%;
  margin: 0 auto;
}

.edu-produce:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .edu-produce {
  display: inline-block;
}

.edu-produce .edu-num {
  border: 2px solid #c0daf5;
  border-radius: 15px;
  font-size: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 35px;
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: relative;
}

.edu-produce .edu-num strong {
  display: block;
  color: #21539b;
}

.edu-produce .edu-num em {
  color: #21539b;
  font-weight: bold;
}

.edu-produce .edu-num:after {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  content: '▼';
  color: #2e84dd;
}

.edu-produce .edu-num:last-child:after {
  display: none;
}

.edu-produce .edu-num1 {
  width: 35%;
  float: left;
}

.edu-produce .edu-num1:after {
  bottom: -70px;
}

.edu-produce .edu-num-sp1 {
  width: 35%;
  float: right;
}

.edu-produce .edu-num-sp2 {
  width: 35%;
  float: right;
}

.multi-edu {
  font-size: 16px;
  color: #000;
}

.multi-edu__num1 {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #b9c0d9;
  border-left: 3px solid #2d6fbf;
  border-right: 3px solid #2d6fbf;
  margin-top: 20px;
  margin-bottom: 30px;
}

.multi-edu__num1 strong {
  display: block;
  margin-bottom: 10px;
  color: #2d6fbf;
  font-size: 22px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num1 {
    width: 90%;
  }
}

.multi-edu__num2 {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #b9c0d9;
  border-left: 3px solid #43a7da;
  border-right: 3px solid #43a7da;
  margin-bottom: 30px;
}

.multi-edu__num2 strong {
  display: block;
  margin-bottom: 10px;
  color: #43a7da;
  font-size: 22px;
}

.multi-edu__num2 div {
  text-align: left;
  padding-left: 90px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num2 {
    width: 90%;
  }
  .multi-edu__num2 div {
    padding: 20px;
  }
}

.multi-edu__num3 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 40px;
  margin-left: -20px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3 {
    margin-left: 0;
  }
}

.multi-edu__num3__tit {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  left: 50%;
  margin-left: -37px;
  color: #fff;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
}

.multi-edu__num3__tit .hexagon {
  position: relative;
  height: 112px;
  padding-top: 30px;
  width: 90px;
  background-color: #50619f;
  margin: 40.99px 0;
}

.multi-edu__num3__tit .hexagon:before,
.multi-edu__num3__tit .hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  top: 0;
  border-top: 71px solid transparent;
  border-bottom: 71px solid transparent;
}

.multi-edu__num3__tit .hexagon:before {
  left: 100%;
  border-left: 40.99px solid #50619f;
}

.multi-edu__num3__tit .hexagon:after {
  right: 100%;
  width: 0;
  border-right: 40.99px solid #50619f;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3__tit {
    top: 0;
    left: 0;
    position: relative;
    transform: translateY(0);
    width: 100%;
    margin-left: 0;
  }
  .multi-edu__num3__tit .hexagon {
    width: 100%;
    height: auto;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .multi-edu__num3__tit .hexagon:after,
  .multi-edu__num3__tit .hexagon:before {
    display: none;
  }
}

.multi-edu__num3__item {
  flex: 1;
  min-width: 50%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3__item {
    min-width: 100%;
  }
}

.multi-edu__num3__item div {
  margin-left: 20px;
  padding: 30px 60px;
  border: 1px solid #b9c0d9;
  min-height: 135px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3__item div {
    margin-left: 0;
    padding: 20px;
  }
}

.multi-edu__num3__item strong {
  color: #50619f;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
}

.multi-edu__num3__item__list > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  font-weight: 400;
  color: #484848;
  font-size: 15px;
}

.multi-edu__num3__item__list > li:before {
  position: absolute;
  top: 11px;
  left: 3px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -395px -615px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 1px;
}

/*구직 구인 스승찾기 링크박스*/
.link-box {
  padding-top: 70px;
  padding-bottom: 60px;
  display: inline-block;
  padding-left: 35%;
  text-align: center;
}

.link-box__wrap {
  background-image: url(../../resources/images/cont/link-box-img.png);
  background-position: 10% center;
  background-repeat: no-repeat;
  background-color: #f4f7fa;
}

.link-box__txt {
  font-size: 20px;
  color: #585858;
  font-weight: 500;
}

.link-box__txt span {
  color: #399bd7;
}

.link-box a {
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
  margin-top: 20px;
  padding: 8px 60px 8px 30px;
  background: url(../../resources/images/cont/link-box-img2.png) 85% center no-repeat #399bd7;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .link-box {
    padding: 180px 15px 60px 15px;
    display: block;
  }
  .link-box__wrap {
    background-position: center 40px;
  }
}

/*정보공개제도안내*/
.text-box {
  padding: 20px;
  margin: 10px 0 10px -11px;
  border: 3px solid #e7e7e7;
}

.info_arrow {
  width: 100%;
  height: 80px;
  background-image: url("../../resources/images/cont/cerificate-img10.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.bbs_view__in {
  margin-bottom: 10px;
  position: relative;
  border-top: 2px solid #000;
}

.bbs_view__in table {
  width: 100%;
}

.bbs_view__in table tr {
  border-bottom: 1px solid #cdcdcd;
  box-sizing: border-box;
}

.bbs_view__in table th, .bbs_view__in table td {
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 15px;
}

.bbs_view__in table th {
  background-color: #f7f7f7;
  vertical-align: middle;
  padding-right: 20px;
  padding-left: 24px;
  text-align: left;
  color: #010101;
}

.bbs_view__in table td {
  padding-left: 10px;
  padding-right: 10px;
  color: #484848;
  vertical-align: middle;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .bbs_view__in table td, .bbs_view__in table th {
    padding: 10px 0;
  }
}

.bbs_view__data {
  text-align: right;
  margin-bottom: 15px;
  color: #484848;
  font-size: 15px;
}

.bbs_view__data span {
  margin-left: 20px;
}

/*프린트*/
@media print {
  header, footer, .sidebar, .location-box, .special-box {
    display: none;
  }
  .sub-contents {
    width: 100%;
  }
}

/*회원가입*/
.sign__main {
  margin-top: 90px;
}

.sign__main__txt1 {
  font-size: 19px;
  font-weight: 300;
  margin-bottom: 65px;
  margin-top: 15px;
}

.sign__main__txt2 {
  font-size: 24px;
  font-weight: 500;
}

.sign__main__txt3 {
  font-size: 17px;
  margin-top: 7px;
  margin-bottom: 13px;
  font-weight: 300;
}

.sign__main__btn {
  margin-left: 10px;
  font-size: 19px;
  font-weight: 500;
  float: left;
  background-color: #275bb6;
  color: #fff;
  display: inline-block;
  padding: 10px 25px;
}

.sign__main__btn2 {
  padding: 10px 20px;
  font-size: 15px;
  color: #fff;
  background-color: #505151;
}

.sign__main__input {
  display: inline-block;
}

.sign__main__input input {
  padding: 5px 10px;
  float: left;
  box-sizing: border-box;
  height: 43px;
  border: 1px solid #bcbcbc;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .sign__main__txt1 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 65px;
    margin-top: 15px;
  }
  .sign__main__txt2 {
    font-size: 18px;
    font-weight: 500;
  }
  .sign__main__txt3 {
    font-size: 15px;
    margin-top: 7px;
    margin-bottom: 13px;
    font-weight: 300;
  }
  .sign__main__btn {
    margin-top: 10px;
    font-size: 17px;
    float: none;
    font-weight: 600;
    background-color: #275bb6;
    color: #fff;
    display: inline-block;
    padding: 10px 25px;
  }
  .sign__main__input input {
    float: none;
    margin-top: -7px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .js-box {
    display: none !important;
  }
  .js-box.on {
    display: block !important;
  }
  .jojikdo__nav div .cost_list.type2 {
    top: auto;
  }
  .jojikdo__nav div .cost_list.type3 {
    top: auto;
  }
  .jojikdo__nav__ctg:before {
    display: none !important;
  }
}

/*인사말*/
.greetings {
  background: url(../../resources/images/cont/greetings-img1.jpg) left top no-repeat;
  padding-top: 30px;
}

.greetings img {
  padding-bottom: 25px;
  padding-left: 28%;
}

.greetings p {
  padding-left: 338px;
  padding-bottom: 30px;
  font-size: 16px;
}

@media screen and (max-width: 600px) and (min-width: 1px) {
  .greetings {
    text-align: center;
    background: none;
    padding-top: 0;
    background-size: 200px;
  }
  .greetings img {
    padding-left: 0;
  }
  .greetings p {
    padding-left: 0;
    text-align: left;
  }
}

/*역대교육장*/
.history-people {
  background: url(../../resources/images/cont/history-people-bg.png) left top no-repeat;
  display: inline-block;
  padding-left: 20px;
}

.history-people li {
  float: left;
  width: 33.33%;
  padding-bottom: 70px;
}

.history-people li dl {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  max-width: 214px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.history-people li dl dd {
  color: #333333;
  font-size: 14px;
  background-color: #f5f5f5;
  padding: 8px 0;
}

.history-people li dl dd p {
  font-weight: 600;
  font-size: 17px;
}

.history-people li img {
  height: 128px;
  padding: 20px 0;
}

@media screen and (max-width: 730px) and (min-width: 1px) {
  .history-people {
    padding-left: 0px;
  }
  .history-people li {
    width: 50%;
    padding-bottom: 10px;
  }
  .history-people li dl {
    margin: 0 auto;
    max-width: 95%;
  }
}

@media screen and (max-width: 390px) and (min-width: 1px) {
  .history-people li {
    width: 100%;
  }
}

/*교육중점*/
.focus-education {
  text-align: center;
}

.focus-education li {
  width: 25%;
  float: left;
}

.focus-education img {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 940px) and (min-width: 1px) {
  .focus-education li {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) and (min-width: 1px) {
  .focus-education li {
    width: 100%;
    margin-bottom: 10px;
  }
}

/*교육중점-시흥교육*/
.focus-education2 li {
  width: 25%;
  float: left;
  text-align: center;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 200px;
}

.focus-education2 li dt {
  font-size: 25px;
  color: #222222;
}

.focus-education2 li dd {
  font-size: 15px;
  color: #3a3a3a;
  padding-top: 15px;
}

.focus-education2__in1 {
  background-image: url(../../resources/images/cont/focus-education2-img1.jpg);
}

.focus-education2__in2 {
  background-image: url(../../resources/images/cont/focus-education2-img2.jpg);
}

.focus-education2__in3 {
  background-image: url(../../resources/images/cont/focus-education2-img3.jpg);
}

.focus-education2__in4 {
  background-image: url(../../resources/images/cont/focus-education2-img4.jpg);
}

@media screen and (max-width: 680px) and (min-width: 1px) {
  .focus-education2 li {
    width: 50%;
    margin-bottom: 30px;
    padding: 200px 20px 0 20px;
    box-sizing: border-box;
    height: 340px;
  }
}

@media screen and (max-width: 440px) and (min-width: 1px) {
  .focus-education2 li {
    width: 100%;
  }
}

/*특색교육*/
.feature-edu {
  text-align: center;
}

.feature-edu__tit {
  color: #414346;
  font-size: 23px;
  font-weight: 500;
  display: inline-block;
  padding: 0 25px;
  background-image: url(../../resources/images/cont/feature-edu-img1.png), url(../../resources/images/cont/feature-edu-img2.png);
  background-repeat: no-repeat;
  background-position: left center, right center;
  background-size: contain;
}

.feature-edu__in {
  margin-top: 30px;
  display: block;
}

.feature-edu__in li {
  float: left;
  width: 33.33%;
}

@media screen and (max-width: 800px) and (min-width: 1px) {
  .feature-edu__in li {
    padding: 0 5px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 600px) and (min-width: 1px) {
  .feature-edu__tit {
    font-size: 19px;
  }
  .feature-edu__in li {
    width: 50%;
  }
}

@media screen and (max-width: 400px) and (min-width: 1px) {
  .feature-edu__in li {
    width: 100%;
    padding: 0;
  }
}

/*내부시설 안내*/
.office-layout li {
  display: block;
  border: 1px solid #919191;
  background-color: #f6f7f8;
  padding-top: 24px;
  padding-bottom: 102px;
  margin-bottom: 50px;
}

.office-layout li dt {
  color: #5b5b5b;
  font-size: 40px;
  font-weight: bold;
  padding-left: 27px;
}

.office-layout li dd {
  text-align: center;
  margin-top: 25px;
  padding: 0 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) and (min-width: 1px) {
  .office-layout li {
    margin-bottom: 30px;
    padding-bottom: 50px;
  }
  .office-layout li dt {
    font-size: 30px;
    padding-left: 15px;
  }
  .office-layout li dd {
    padding: 0 15px;
  }
}

.sub-slide {
  position: relative;
  margin-bottom: 10px;
}

.sub-slide .slick-prev {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 50px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 0;
  text-indent: -9999px;
}

.sub-slide .slick-prev::before {
  content: "";
  display: block;
  height: 72px;
  background-image: url(../../resources/images/cont/sub-slide-prev.png);
}

.sub-slide .slick-next {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 50px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 0;
  text-indent: -9999px;
}

.sub-slide .slick-next::before {
  content: "";
  display: block;
  height: 72px;
  background-image: url(../../resources/images/cont/sub-slide-next.png);
}

.sub-slide__box {
  max-width: 100%;
  margin: 0 auto;
}

.sub-slide__box img {
  width: 100%;
  max-height: 600px;
}

.sub-slide__prev {
  left: 0;
}

.sub-slide__prev:after {
  content: '<';
}

.sub-slide__next {
  right: 0;
}

.sub-slide__next:after {
  content: '>';
}

/*찾아오시는 길*/
.way-to-come > ul {
  margin-top: 35px;
  background-color: #fbfbfb;
  border-top: 2px solid #abb6c2;
  border-bottom: 2px solid #abb6c2;
  display: flex;
}

.way-to-come > ul li {
  flex: 1;
}

.way-to-come > ul li dl {
  color: #353535;
  padding: 20px 10px 20px 55px;
  background-repeat: no-repeat;
  background-position: 18px 18px;
  margin-bottom: 30px;
}

.way-to-come > ul li dl dt {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.way-to-come > ul li dl dd {
  font-size: 16px;
}

.way-to-come > ul > li:first-child {
  border-right: 1px solid #e7e8e9;
}

.way-to-come > ul > li:last-child dl {
  margin-bottom: 0;
}

.way-to-come__dl1 {
  background-image: url(../../resources/images/cont/way-to-come-img1.png);
}

.way-to-come__dl2 {
  background-image: url(../../resources/images/cont/way-to-come-img2.png);
}

.way-to-come__dl3 {
  background-image: url(../../resources/images/cont/way-to-come-img3.png);
}

.way-to-come__dl4 {
  background-image: url(../../resources/images/cont/way-to-come-img4.png);
}

.way-to-come-list li {
  margin-left: -10px;
  background: url(../../resources/images/cont/way-to-come-list.png) left center no-repeat;
  padding-left: 10px;
}

@media screen and (max-width: 500px) and (min-width: 1px) {
  .way-to-come > ul {
    display: block;
  }
  .way-to-come > ul li dl {
    margin-bottom: 10px;
  }
  .way-to-come > ul > li:first-child {
    border-right: 0;
  }
  .way-to-come > ul > li:last-child dl {
    margin-bottom: 10px;
  }
}

.innovation-edu__img {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.innovation-edu__img li:first-child img {
  margin-bottom: 50px;
}

.innovation-edu__img li:nth-child(2) img {
  margin-bottom: 50px;
}

.jojik-tb {
  background-color: #fff;
}

.jojik-tb {
  margin-bottom: 10px;
  border-top: 1px solid #000;
}

.jojik-tb table {
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  min-width: 540px;
}

.jojik-tb thead th {
  border-bottom: 0px;
}

.jojik-tb th {
  border: 1px solid #dcdcdc;
  background-color: #f4f4f4;
  padding: 10px 10px;
  word-break: break-all;
}

.jojik-tb td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

.jojik-tb .bg {
  background-color: #eee;
}

.jojik-tb.th_center th {
  text-align: center;
}

.jojik-tb.td_left td {
  text-align: left;
}

.jojik-tb.td_left2 td {
  text-align: center;
}

.jojik-tb.td_left2 td:first-child {
  text-align: left;
}

.jojik-tb.td_left3 td {
  text-align: left;
}

.jojik-tb.td_left3 td:first-child {
  text-align: center;
}

@media (max-width: 768px) {
  .jojik-tb {
    overflow: auto;
  }
  .jojik-tb.res {
    padding-top: 10px;
  }
  .jojik-tb.res table {
    min-width: auto;
  }
  .jojik-tb.res colgroup {
    display: none;
  }
  .jojik-tb.res thead {
    display: none;
  }
  .jojik-tb.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .jojik-tb.res th {
    display: none;
  }
  .jojik-tb.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .jojik-tb.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
  .jojik-tb td, .jojik-tb th {
    padding: 5px 8px;
  }
}

.jojik-tb .left-td {
  text-align: left;
  padding-left: 20px;
}

.new-js-box {
  display: none;
}

.new-js-box.on {
  display: block;
}

.noafter:after {
  display: none !important;
}

.jojikdo {
  min-height: 1080px;
  background: url(../../resources/images/cont/jojikdo-bg.jpg) center 120px no-repeat;
}

.jojikdo__top {
  position: relative;
}

.jojikdo__top__jang {
  box-sizing: border-box;
  display: block;
  width: 120px;
  height: 120px;
  color: #2961c9;
  background-image: url("../../resources/images/cont/jojik-item1-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
  line-height: 120px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  position: relative;
  margin-bottom: 87px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__top__jang {
    margin-bottom: 0px;
    margin: 0 auto;
  }
}

.jojikdo__nav {
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav {
    margin-top: 40px;
  }
}

.jojikdo__nav__ctg {
  width: 900px;
  min-height: 800px;
  margin: 0 auto;
  position: relative;
}

.jojikdo__nav__ctg.open {
  padding-bottom: 0;
}

.jojikdo__nav__ctg:after {
  position: absolute;
  content: '';
  border-left: 1px solid #ccc;
  height: 658px;
  left: 4px;
  top: 0;
  display: block !important;
}

.jojikdo__nav__ctg:before {
  position: absolute;
  content: '';
  border-left: 1px dashed #ccc;
  height: 242px;
  left: 130px;
  top: 390px;
  display: block !important;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__ctg {
    width: 100%;
    min-height: 50px;
  }
  .jojikdo__nav__ctg:after {
    content: ' ';
    clear: both;
    display: block;
    visibility: hidden;
  }
  * + html .jojikdo__nav__ctg {
    display: inline-block;
  }
}

.jojikdo__nav__ctg__link {
  position: absolute;
  top: 0;
  display: block;
  width: 162px;
  text-align: center;
  background-color: #3361ae;
  background-size: cover;
  height: 48px;
  line-height: 48px;
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
}

.jojikdo__nav__ctg__link.num--1 {
  left: 50px;
}

.jojikdo__nav__ctg__link.num--2 {
  left: 50px;
  top: 342px;
}

.jojikdo__nav__ctg__link.num--3 {
  left: 50px;
  top: 632px;
}

.jojikdo__nav__ctg__link:before {
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -792px -120px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 9px;
  position: absolute;
  top: 20px;
  z-index: 1;
  left: -46px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.jojikdo__nav__ctg__link:after {
  content: ' ';
  position: absolute;
  top: 24px;
  left: -23px;
  transform: translateX(-50%);
  display: block;
  width: 46px;
  height: 1px;
  background-color: #ccc;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__ctg__link:after {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__ctg__link {
    position: relative;
    width: 100%;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    margin-left: auto !important;
    margin-bottom: 5px;
  }
}

.jojikdo__nav__box.js-box {
  display: block;
  position: static;
  min-height: 0;
}

.jojikdo__nav__box .cost_list {
  width: 162px;
  position: absolute;
  top: 0;
  left: 222px;
}

.jojikdo__nav__box .cost_list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .jojikdo__nav__box .cost_list {
  display: inline-block;
}

.jojikdo__nav__box .cost_list li {
  margin-bottom: 5px;
  position: relative;
}

.jojikdo__nav__box .cost_list li > a {
  display: block;
  width: 162px;
  margin: 0 auto;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background-color: #5d656f;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  word-break: normal;
  color: #fff;
  font-size: 15px;
}

.jojikdo__nav__box .cost_list li > a:hover {
  opacity: 0.8;
}

.jojikdo__nav__box .cost_list li > a:hover .new-js-list__in {
  background-image: url("../../resources/images/cont/jojik-item1-img6.jpg");
}

.jojikdo__nav__box .cost_list li > a .new-js-list__in {
  position: absolute;
  left: 159px;
  text-align: center;
  font-size: 12px;
  top: 6px;
  width: 116px;
  line-height: 40px;
  height: 40px;
  background-image: url("../../resources/images/cont/jojik-item1-img4.jpg");
}

.jojikdo__nav__box .cost_list li > a .new-js-list__in:before {
  content: '';
  background-color: #ccc;
  width: 12px;
  height: 1px;
  position: absolute;
  left: -12px;
  top: 20px;
}

.jojikdo__nav__box .cost_list li.on .new-js-list__in {
  background-image: url("../../resources/images/cont/jojik-item1-img6.jpg");
}

.jojikdo__nav__box .cost_list li.on a {
  opacity: 0.9;
}

.jojikdo__nav__box .cost_box {
  min-height: 500px;
  padding-top: 0;
}

.jojikdo__nav__box .cost_box__tit {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 18px;
  position: relative;
}

.jojikdo__nav__box .cost_box__tit:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -384px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

@media (max-width: 768px) {
  .jojikdo__nav__box .cost_box__tit {
    font-size: 18px;
  }
}

.jojikdo__nav div .cost_list.type2 {
  top: 343px;
}

.jojikdo__nav div .cost_list.type3 {
  top: 633px;
}

@media screen and (min-width: 0\0) {
  .jojikdo__nav__box .cost_list li a {
    letter-spacing: 0;
    writing-mode: lr-tb;
    text-align: center;
  }
}

.jojikdo__nav__ctg.special:after {
  display: none;
}

.jojikdo__nav__jang {
  display: none;
  position: absolute;
  top: 777px;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo__nav__jang {
    position: relative;
    top: 0;
  }
}

.jojikdo__nav__jang.on {
  display: block;
}

.cost-big {
  display: none;
}

.cost-big__tit {
  background-color: #fff;
}

.cost-big__tit {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 18px;
  position: relative;
}

.cost-big__tit:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -729px -384px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

@media (max-width: 768px) {
  .cost-big__tit {
    font-size: 18px;
  }
}

.cost-big.on {
  display: block;
  top: 200px;
  left: 0;
  width: 100%;
  margin-top: 50px;
}

/*조직도 모바일*/
@media screen and (min-width: 1px) and (max-width: 1200px) {
  .jojikdo {
    min-height: auto;
  }
  .jojikdo__nav__box .cost_list {
    position: relative;
    width: 100%;
    top: auto;
    right: auto;
    left: auto !important;
  }
  .jojikdo__nav__box .cost_list:before {
    display: none;
  }
  .jojikdo__nav div:last-child .cost_list:before {
    display: none;
  }
  .jojikdo__nav div:last-child .cost_list {
    right: auto;
  }
  .jojikdo__nav div .cost_list li {
    width: 100%;
    margin-left: 0;
  }
  .jojikdo__nav div .cost_list li a {
    width: 100%;
    background-image: none;
    background-color: #888;
    box-sizing: border-box;
    text-align: left;
  }
  .jojikdo__nav div .cost_list li a:focus {
    background-image: none;
  }
  .new-js-list__in {
    text-align: center;
    left: auto !important;
    right: 6px;
    background-size: 101%;
  }
  .new-js-list__in:before {
    display: none;
  }
  .jojikdo__nav__box .cost_list li.on a {
    background-size: 100%;
  }
  .jojikdo__nav div .cost_list li a:hover {
    background-size: 100%;
  }
  .jojikdo__nav div:last-child .cost_list li {
    margin-left: 0;
  }
  .cost-big {
    display: none;
  }
  .jojikdo__nav__ctg__link.num--3 {
    width: 100%;
  }
  .jojikdo__nav__box.js-box.on {
    padding-top: 10px;
  }
  .cost-big.on {
    margin-top: 0px;
  }
  .jojikdo__nav__ctg__link.on {
    color: gold;
  }
  .jojikdo__nav__ctg__link:before {
    display: none !important;
  }
  .new-js-list.ver2 a:before {
    margin-left: 5px;
  }
}

/*조직도 211231 추가*/
.jojikdo__nav__box .cost_list li:first-child a {
  background-color: #3e9f8b;
  position: relative;
}

.jojikdo__nav__box .cost_list li:first-child a:before {
  height: 1px;
  width: 10px;
  position: absolute;
  content: '';
  left: -10px;
  top: 50%;
  background-color: #ccc;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_1 .cost_list {
  left: 222px;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_2 .cost_list {
  left: 394px;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_3 .cost_list {
  left: 566px;
}

.jojikdo__nav__box .cost-wrap1.cost-wrap1_4 .cost_list {
  left: 740px;
}

.jojikdo__nav__box .cost_list li:before {
  content: '';
  width: 1px;
  height: 5px;
  background-color: #ccc;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: 5px;
}

.new-js-list.ver2 a {
  background-color: #3e9f8b !important;
  margin-top: 30px !important;
  position: relative;
}

.new-js-list.ver2 a:before {
  content: '';
  width: 1px;
  height: 30px;
  background-color: #ccc;
  position: absolute;
  top: -30px;
  left: 50%;
}

.jojikdo__nav__box .cost_list li:first-child:before {
  display: none;
}

/* 연혁 */
.years--info {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 90px;
}

.years--info strong {
  color: #275bb6;
}

.years {
  margin-left: 180px;
  margin-bottom: 30px;
}

.years__box {
  border-left: 2px solid #d8d8d8;
  padding-left: 38px;
  position: relative;
  padding-bottom: 60px;
}

.years__box:last-child {
  padding-bottom: 0px;
  border-left: 0px;
  padding-left: 40px;
}

.years__box__num {
  position: absolute;
  top: -21px;
  left: -180px;
  background-color: #275bb6;
  color: #fff;
  font-size: 24px;
  width: 130px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  border-radius: 30px;
}

.years__box__num:before {
  content: ' ';
  display: block;
  width: 46px;
  height: 2px;
  background-color: #d8d8d8;
  position: absolute;
  right: -46px;
  top: 21px;
}

.years__box__num:after {
  position: absolute;
  right: -56px;
  top: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -132px -615px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.years__box__ul {
  position: relative;
  top: -14px;
}

.years__box__ul__li {
  font-size: 17px;
  position: relative;
  padding-left: 130px;
  margin-bottom: 15px;
}

.years__box__ul__li em {
  position: absolute;
  top: 0;
  left: 20px;
  color: #275bb6;
}

@media screen and (min-width: 1px) and (max-width: 550px) {
  .years {
    margin-left: 0px;
  }
  .years__box__num {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 20px;
  }
  .years__box__num:before {
    display: none;
  }
  .years__box__num:after {
    display: none;
  }
  .years__box {
    border-left: 0px;
    padding-left: 0;
  }
  .years__box:last-child {
    padding-left: 0;
  }
  .years__box__ul {
    top: 0px;
  }
  .years__box__ul__li {
    font-size: 14px;
    padding-left: 100px;
  }
}

/*혁신교육지구 콜센터*/
.call-center p {
  font-size: 15px;
  color: #484848;
}

.call-center-call {
  text-align: left;
  padding-left: 30px;
}

.call-center-call li {
  padding-left: 30px;
}

.call-center-call li.call {
  background: url(../../resources/images/icons/call-center-img1.png) left center no-repeat;
}

.call-center-call li.mail {
  background: url(../../resources/images/icons/call-center-img2.png) left center no-repeat;
}

.call-center-operate {
  margin-top: 30px;
  padding-left: 50%;
  background: url(../../resources/images/cont/call-center-img1.png) left center no-repeat;
}

.call-center-operate .basicList > li {
  margin-bottom: 10px;
}

@media screen and (min-width: 1px) and (max-width: 620px) {
  .call-center-operate {
    background: none;
    margin-top: 0;
    padding-left: 0;
  }
}

/*민원 신청*/
.minwon__step {
  text-align: center;
}

.minwon__step li {
  display: inline-block;
  padding-left: 97px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #666666;
  font-size: 16px;
  text-align: left;
  background-position: left center;
  background-repeat: no-repeat;
}

.minwon__step li p {
  color: #484848;
  font-weight: 500;
  font-size: 18px;
}

.minwon__step li:first-child {
  background-image: url(../../resources/images/cont/minwon-img1.png);
}

.minwon__step li:last-child {
  margin-left: 70px;
  position: relative;
  background-image: url(../../resources/images/cont/minwon-img2.png);
}

.minwon__step li:last-child::before {
  content: '';
  position: absolute;
  background: url(../../resources/images/cont/minwon-img3.png);
  height: 20px;
  width: 12px;
  display: inline-block;
  left: -40px;
  top: 50%;
  margin-top: -12px;
}

.minwon .agr_pt {
  height: 240px;
  padding: 20px 30px;
  overflow-y: scroll;
  border: 1px solid #e7e7e7;
}

.minwon .agr_pt dt {
  color: #484848;
  font-weight: 500;
  font-size: 18px;
}

.minwon .agr_pt dd {
  color: #666666;
  font-size: 16px;
  padding: 20px 0;
}

.minwon .agr_pt li {
  padding: 10px 0;
}

.minwon .agree_sel {
  color: #484848;
  font-size: 16px;
  margin-top: 10px;
}

.minwon .agree_sel input {
  margin-right: 5px;
}

.minwon__login {
  display: inline-block;
  width: 100%;
}

.minwon__login li {
  float: left;
  width: 25%;
  text-align: center;
}

.minwon__login li a {
  padding-top: 140px;
  display: inline-block;
  background-position: top center;
  background-repeat: no-repeat;
}

.minwon__login li p {
  color: #484848;
  border: 1px solid #e7e7e7;
  padding: 10px 32px;
  display: inline-block;
}

.minwon__login__in1 {
  background-image: url(../../resources/images/cont/minwon-img4.png);
}

.minwon__login__in2 {
  background-image: url(../../resources/images/cont/minwon-img5.png);
}

.minwon__login__in3 {
  background-image: url(../../resources/images/cont/minwon-img6.png);
}

.minwon__login__in4 {
  background-image: url(../../resources/images/cont/minwon-img7.png);
}

@media screen and (min-width: 1px) and (max-width: 780px) {
  .minwon__login {
    display: flex;
  }
  .minwon__login li {
    margin-bottom: 20px;
    padding: 0 5px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .minwon__login {
    display: table;
  }
  .minwon__login li {
    width: 50%;
    font-size: 14px;
  }
  .minwon__login li p {
    height: 40px;
    display: table-cell;
    vertical-align: middle;
  }
  .minwon__step {
    text-align: center;
  }
  .minwon__step li {
    padding-left: 60px;
    background-size: 50px;
    font-size: 14px;
  }
  .minwon__step li p {
    font-size: 16px;
  }
  .minwon__step li:last-child {
    margin-left: 40px;
  }
  .minwon__step li:last-child::before {
    left: -25px;
  }
  .minwon__agr_box p {
    font-size: 16px;
    padding: 0 10px;
  }
}

/*각종증명서 신청_이용안내*/
.certificate-box1 {
  border: 1px solid #e7e7e7;
}

.certificate-box1 li {
  float: left;
  position: relative;
  width: 33.33%;
  text-align: center;
  height: 65px;
  padding-top: 140px;
  padding-bottom: 25px;
  font-size: 14px;
  color: #484848;
  background-position: center 30px;
  background-repeat: no-repeat;
}

.certificate-box1 li p {
  font-size: 16px;
  font-weight: 500;
  color: #275bb6;
}

.certificate-box1 li::before {
  content: '';
  width: 42px;
  position: absolute;
  display: inline-block;
  height: 36px;
  right: -20px;
  top: 50%;
  margin-top: -22px;
  background: url(../../resources/images/cont/cerificate-img11.png) center center no-repeat;
}

.certificate-box1 li:first-child {
  background-image: url(../../resources/images/cont/cerificate-img1.png);
}

.certificate-box1 li:nth-child(2) {
  background-image: url(../../resources/images/cont/cerificate-img2.png);
}

.certificate-box1 li:last-child {
  background-image: url(../../resources/images/cont/cerificate-img3.png);
}

.certificate-box1 li:last-child::before {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .certificate-box1 li {
    background-size: 60px;
    background-position: center 40px;
    padding-top: 110px;
    padding-bottom: 35px;
  }
  .certificate-box1 li::before {
    width: 32px;
    background-size: contain;
  }
}

.certificate-box2 {
  display: inline-block;
}

.certificate-box2 li {
  padding-bottom: 45px;
  margin-top: 20px;
}

.certificate-box2 li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.certificate-box2 li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.certificate-box2 li dl dd {
  font-size: 15px;
}

.certificate-box2 li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.certificate-box2 li:last-child dl::before {
  display: none;
}

.certificate-box2 li:first-child {
  background: url(../../resources/images/cont/cerificate-img4.png) top left no-repeat;
}

.certificate-box2 li:nth-child(2) {
  background: url(../../resources/images/cont/cerificate-img5.png) top left no-repeat;
}

.certificate-box2 li:nth-child(3) {
  background: url(../../resources/images/cont/cerificate-img6.png) top left no-repeat;
}

.certificate-box2 li:nth-child(4) {
  background: url(../../resources/images/cont/cerificate-img7.png) top left no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .certificate-box2 li dl {
    height: auto;
  }
  .certificate-box2 li dl::before {
    bottom: -20%;
  }
}

.certificate-box3 {
  display: inline-block;
}

.certificate-box3 li {
  padding-bottom: 45px;
  margin-top: 20px;
}

.certificate-box3 li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.certificate-box3 li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.certificate-box3 li dl dd {
  font-size: 15px;
}

.certificate-box3 li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.certificate-box3 li:last-child dl::before {
  display: none;
}

.certificate-box3 li:first-child {
  background: url(../../resources/images/cont/cerificate-img4.png) top left no-repeat;
}

.certificate-box3 li:nth-child(2) {
  background: url(../../resources/images/cont/cerificate-img5.png) top left no-repeat;
}

.certificate-box3 li:nth-child(3) {
  background: url(../../resources/images/cont/cerificate-img6.png) top left no-repeat;
}

.certificate-box3 li:nth-child(4) {
  background: url(../../resources/images/cont/cerificate-img12.png) top left no-repeat;
}

.certificate-box3 li:nth-child(5) {
  background: url(../../resources/images/cont/cerificate-img8.png) top left no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .certificate-box3 li dl {
    height: auto;
  }
  .certificate-box3 li dl::before {
    bottom: -50px;
  }
}

.certificate-box4 {
  display: inline-block;
}

.certificate-box4 li {
  padding-bottom: 45px;
  margin-top: 20px;
  background: url(../../resources/images/cont/cerificate-img4.png) top left no-repeat;
}

.certificate-box4 li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.certificate-box4 li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 17px;
}

.certificate-box4 li dl dd {
  font-size: 15px;
}

.certificate-box4 li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.certificate-box4 li:last-child dl::before {
  display: none;
}

/*교습소 설립의 절차안내*/
.academy-guide {
  overflow: hidden;
  border: 1px solid #e7e7e7;
  text-align: center;
}

.academy-guide li {
  float: left;
  position: relative;
  width: 20%;
  text-align: center;
  height: 65px;
  padding-top: 140px;
  padding-bottom: 75px;
  font-size: 14px;
  color: #484848;
  background-position: center 30px;
  background-repeat: no-repeat;
}

.academy-guide li p {
  font-size: 16px;
  font-weight: 500;
  color: #275bb6;
}

.academy-guide li::before {
  content: '';
  width: 42px;
  position: absolute;
  display: inline-block;
  height: 36px;
  right: -20px;
  top: 50%;
  margin-top: -50px;
  background: url(../../resources/images/cont/cerificate-img11.png) center center no-repeat;
}

.academy-guide li:first-child {
  background-image: url(../../resources/images/cont/classroom-guide-img2.png);
}

.academy-guide li:nth-child(2) {
  background-image: url(../../resources/images/cont/classroom-guide-img3.png);
}

.academy-guide li:nth-child(3) {
  background-image: url(../../resources/images/cont/classroom-guide-img4.png);
}

.academy-guide li:nth-child(4) {
  background-image: url(../../resources/images/cont/classroom-guide-img5.png);
}

.academy-guide li:nth-child(5) {
  background-image: url(../../resources/images/cont/classroom-guide-img6.png);
}

.academy-guide li:nth-child(5)::before {
  display: none;
}

.academy-guide__bottom {
  border-radius: 5px;
  border: 5px solid #d5e6f8;
  display: inline-block;
  padding: 14px 20px;
  font-size: 16px;
  margin-bottom: 30px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .academy-guide {
    background: none !important;
  }
  .academy-guide li {
    padding-bottom: 0;
    width: 33.33%;
    margin-left: -15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .academy-guide li {
    background-size: 90px;
    background-position: center 40px;
    padding-top: 140px;
    padding-bottom: 0;
    width: 50%;
    height: 45px;
  }
  .academy-guide li::before {
    width: 32px;
    background-size: contain;
    right: -10px;
    margin-top: -27px;
  }
}

.academy-guide2 {
  text-align: left;
}

.academy-guide2 .academy-guide__bottom {
  margin-left: 20px;
}

@media screen and (min-width: 1px) and (max-width: 700px) {
  .academy-guide2 .academy-guide__bottom {
    display: block;
    margin: 0 10px;
    margin-bottom: 10px;
  }
}

/*유치원업무안내_설립안내*/
.kinder-box {
  display: block;
}

.kinder-box li {
  padding-bottom: 45px;
  margin-top: 20px;
}

.kinder-box li dl {
  padding-left: 115px;
  position: relative;
  height: 98px;
  box-sizing: border-box;
}

.kinder-box li dl dt {
  padding-top: 5px;
  color: #275bb6;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.kinder-box li dl dd {
  font-size: 15px;
}

.kinder-box li dl::before {
  content: '';
  width: 36px;
  position: absolute;
  display: inline-block;
  height: 42px;
  left: 30px;
  bottom: -55px;
  margin-top: -13px;
  background: url(../../resources/images/cont/cerificate-img10.png) center center no-repeat;
}

.kinder-box li:last-child dl::before {
  display: none;
}

.kinder-box li:first-child {
  background: url(../../resources/images/cont/kinder-img1.png) top left no-repeat;
}

.kinder-box li:nth-child(2) {
  background: url(../../resources/images/cont/kinder-img2.png) top left no-repeat;
}

.kinder-box li:nth-child(3) {
  background: url(../../resources/images/cont/kinder-img3.png) top left no-repeat;
}

.kinder-box li:nth-child(4) {
  background: url(../../resources/images/cont/kinder-img4.png) top left no-repeat;
}

.kinder-box li:nth-child(5) {
  background: url(../../resources/images/cont/kinder-img5.png) top left no-repeat;
}

.kinder-box li:nth-child(6) {
  background: url(../../resources/images/cont/kinder-img6.png) top left no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .kinder-box li {
    padding-bottom: 80px;
  }
}

/*학원평생교육시설안내 - 학원설립운영안내*/
.academy-oparition__map {
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
}

.academy-oparition__map ul {
  display: inline-block;
}

.academy-oparition__map ul li {
  float: left;
  box-sizing: border-box;
}

.academy-oparition__map ul li img {
  width: 100%;
}

.academy-oparition__map ul li:first-child {
  padding-right: 60px;
}

@media screen and (min-width: 1px) and (max-width: 769px) {
  .academy-oparition__map {
    padding: 0;
  }
  .academy-oparition__map ul li {
    float: none;
    padding: 30px 10px 20px 10px !important;
  }
  .academy-oparition__map ul li:first-child {
    padding-right: 0;
    padding-bottom: 20px;
  }
}

/* epki 인증서 로그인 */
.epki-login {
  border: 2px solid #1c5a94;
  padding: 45px 20px;
  text-align: center;
  background-color: #fafbfc;
  color: #3d3d3d;
}

.epki-login__tit {
  font-size: 24px;
  font-weight: 600;
}

.epki-login__txt {
  font-size: 18px;
  padding-top: 150px;
  background: url(../../resources/images/cont/epki-img1.png) top center no-repeat;
  margin-bottom: 40px;
  margin-top: 40px;
}

.epki-login__btn {
  background-color: #1c5a94;
  color: #fff;
  padding: 20px 90px;
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .epki-login__tit {
    font-size: 20px;
  }
  .epki-login__txt {
    font-size: 16px;
  }
  .epki-login__btn {
    font-size: 16px;
    padding: 15px 40px;
  }
}

/*학생봉사활동*/
.volunteer__go {
  margin-top: 80px;
  display: inline-block;
}

.txtType-new {
  padding: 20px;
  background-color: #e8f0f8;
  border: 1px dashed #999;
  border-left: 0;
  border-right: 0;
  margin-bottom: 30px;
}

.txtType-new a:hover, .txtType-new a:focus {
  text-decoration: underline;
}

.blue-tit {
  padding-left: 20px;
  color: #21539b;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}

.h4Type5 {
  text-align: left;
  position: relative;
  padding-left: 12px;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.7;
  font-weight: 500;
  margin-left: 7px;
  margin-bottom: 10px;
}

.h4Type5:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -244px -682px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 6px;
}

.txtType-new2 {
  padding: 25px;
  border: 1px solid #ddd;
  margin-bottom: 30px;
}

.sp-edu {
  font-size: 16px;
  color: #000;
}

.sp-edu .txtType1 {
  font-size: 16px;
  color: #000;
  margin-bottom: 30px;
}

.edu-produce {
  text-align: center;
  width: 82%;
  margin: 0 auto;
}

.edu-produce:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .edu-produce {
  display: inline-block;
}

.edu-produce .edu-num {
  border: 2px solid #c0daf5;
  border-radius: 15px;
  font-size: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 35px;
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: relative;
}

.edu-produce .edu-num strong {
  display: block;
  color: #21539b;
}

.edu-produce .edu-num em {
  color: #21539b;
  font-weight: bold;
}

.edu-produce .edu-num:after {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  content: '▼';
  color: #2e84dd;
}

.edu-produce .edu-num:last-child:after {
  display: none;
}

.edu-produce .edu-num1 {
  width: 35%;
  float: left;
}

.edu-produce .edu-num1:after {
  bottom: -70px;
}

.edu-produce .edu-num-sp1 {
  width: 35%;
  float: right;
}

.edu-produce .edu-num-sp2 {
  width: 35%;
  float: right;
}

.multi-edu {
  font-size: 16px;
  color: #000;
}

.multi-edu__num1 {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #b9c0d9;
  border-left: 3px solid #2d6fbf;
  border-right: 3px solid #2d6fbf;
  margin-top: 20px;
  margin-bottom: 30px;
}

.multi-edu__num1 strong {
  display: block;
  margin-bottom: 10px;
  color: #2d6fbf;
  font-size: 22px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num1 {
    width: 90%;
  }
}

.multi-edu__num2 {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #b9c0d9;
  border-left: 3px solid #43a7da;
  border-right: 3px solid #43a7da;
  margin-bottom: 30px;
}

.multi-edu__num2 strong {
  display: block;
  margin-bottom: 10px;
  color: #43a7da;
  font-size: 22px;
}

.multi-edu__num2 div {
  text-align: left;
  padding-left: 90px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num2 {
    width: 90%;
  }
  .multi-edu__num2 div {
    padding: 20px;
  }
}

.multi-edu__num3 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 40px;
  margin-left: -20px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3 {
    margin-left: 0;
  }
}

.multi-edu__num3__tit {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  left: 50%;
  margin-left: -37px;
  color: #fff;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
}

.multi-edu__num3__tit .hexagon {
  position: relative;
  height: 112px;
  padding-top: 30px;
  width: 90px;
  background-color: #50619f;
  margin: 40.99px 0;
}

.multi-edu__num3__tit .hexagon:before,
.multi-edu__num3__tit .hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  top: 0;
  border-top: 71px solid transparent;
  border-bottom: 71px solid transparent;
}

.multi-edu__num3__tit .hexagon:before {
  left: 100%;
  border-left: 40.99px solid #50619f;
}

.multi-edu__num3__tit .hexagon:after {
  right: 100%;
  width: 0;
  border-right: 40.99px solid #50619f;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3__tit {
    top: 0;
    left: 0;
    position: relative;
    transform: translateY(0);
    width: 100%;
    margin-left: 0;
  }
  .multi-edu__num3__tit .hexagon {
    width: 100%;
    height: auto;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .multi-edu__num3__tit .hexagon:after,
  .multi-edu__num3__tit .hexagon:before {
    display: none;
  }
}

.multi-edu__num3__item {
  flex: 1;
  min-width: 50%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3__item {
    min-width: 100%;
  }
}

.multi-edu__num3__item div {
  margin-left: 20px;
  padding: 30px 60px;
  border: 1px solid #b9c0d9;
  min-height: 135px;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .multi-edu__num3__item div {
    margin-left: 0;
    padding: 20px;
  }
}

.multi-edu__num3__item strong {
  color: #50619f;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
}

.multi-edu__num3__item__list > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  font-weight: 400;
  color: #484848;
  font-size: 15px;
}

.multi-edu__num3__item__list > li:before {
  position: absolute;
  top: 11px;
  left: 3px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -395px -615px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 1px;
}

/*구직 구인 스승찾기 링크박스*/
.link-box {
  padding-top: 70px;
  padding-bottom: 60px;
  display: inline-block;
  padding-left: 35%;
  text-align: center;
}

.link-box__wrap {
  background-image: url(../../resources/images/cont/link-box-img.png);
  background-position: 10% center;
  background-repeat: no-repeat;
  background-color: #f4f7fa;
}

.link-box__txt {
  font-size: 20px;
  color: #585858;
  font-weight: 500;
}

.link-box__txt span {
  color: #399bd7;
}

.link-box a {
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
  margin-top: 20px;
  padding: 8px 60px 8px 30px;
  background: url(../../resources/images/cont/link-box-img2.png) 85% center no-repeat #399bd7;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .link-box {
    padding: 180px 15px 60px 15px;
    display: block;
  }
  .link-box__wrap {
    background-position: center 40px;
  }
}

/*정보공개제도안내*/
.text-box {
  padding: 20px;
  margin: 10px 0 10px -11px;
  border: 3px solid #e7e7e7;
}

.info_arrow {
  width: 100%;
  height: 80px;
  background-image: url("../../resources/images/cont/cerificate-img10.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.bbs_view__in {
  margin-bottom: 10px;
  position: relative;
  border-top: 2px solid #000;
}

.bbs_view__in table {
  width: 100%;
}

.bbs_view__in table tr {
  border-bottom: 1px solid #cdcdcd;
  box-sizing: border-box;
}

.bbs_view__in table th, .bbs_view__in table td {
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 15px;
}

.bbs_view__in table th {
  background-color: #f7f7f7;
  vertical-align: middle;
  padding-right: 20px;
  padding-left: 24px;
  text-align: left;
  color: #010101;
}

.bbs_view__in table td {
  padding-left: 10px;
  padding-right: 10px;
  color: #484848;
  vertical-align: middle;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .bbs_view__in table td, .bbs_view__in table th {
    padding: 10px 0;
  }
}

.bbs_view__data {
  text-align: right;
  margin-bottom: 15px;
  color: #484848;
  font-size: 15px;
}

.bbs_view__data span {
  margin-left: 20px;
}

/*프린트*/
@media print {
  header, footer, .sidebar, .location-box, .special-box {
    display: none;
  }
  .sub-contents {
    width: 100%;
  }
}

/*회원가입*/
.sign__main {
  margin-top: 90px;
}

.sign__main__txt1 {
  font-size: 19px;
  font-weight: 300;
  margin-bottom: 65px;
  margin-top: 15px;
}

.sign__main__txt2 {
  font-size: 24px;
  font-weight: 500;
}

.sign__main__txt3 {
  font-size: 17px;
  margin-top: 7px;
  margin-bottom: 13px;
  font-weight: 300;
}

.sign__main__btn {
  margin-left: 10px;
  font-size: 19px;
  font-weight: 500;
  float: left;
  background-color: #275bb6;
  color: #fff;
  display: inline-block;
  padding: 10px 25px;
}

.sign__main__btn2 {
  padding: 10px 20px;
  font-size: 15px;
  color: #fff;
  background-color: #505151;
}

.sign__main__input {
  display: inline-block;
}

.sign__main__input input {
  padding: 5px 10px;
  float: left;
  box-sizing: border-box;
  height: 43px;
  border: 1px solid #bcbcbc;
}

@media screen and (max-width: 768px) and (min-width: 1px) {
  .sign__main__txt1 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 65px;
    margin-top: 15px;
  }
  .sign__main__txt2 {
    font-size: 18px;
    font-weight: 500;
  }
  .sign__main__txt3 {
    font-size: 15px;
    margin-top: 7px;
    margin-bottom: 13px;
    font-weight: 300;
  }
  .sign__main__btn {
    margin-top: 10px;
    font-size: 17px;
    float: none;
    font-weight: 600;
    background-color: #275bb6;
    color: #fff;
    display: inline-block;
    padding: 10px 25px;
  }
  .sign__main__input input {
    float: none;
    margin-top: -7px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .js-box {
    display: none !important;
  }
  .js-box.on {
    display: block !important;
  }
  .jojikdo__nav div .cost_list.type2 {
    top: auto;
  }
  .jojikdo__nav div .cost_list.type3 {
    top: auto;
  }
  .jojikdo__nav__ctg:before {
    display: none !important;
  }
}

.icon-test {
  padding: 0px;
  background-color: #000;
  height: 28px;
  display: inline-block;
}

.icon-test:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -156px -358px;
  background-image: url("../images/sprite.png");
  background-size: 568px auto;
  width: 18.66667px;
  min-width: 18.66667px;
  height: 18.66667px;
}

.flex {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 500px;
  height: 200px;
  border: 1px solid red;
}

.flex-item {
  flex: 1;
  order: 0;
  width: 100px;
  height: 100px;
  border: 1px solid blue;
  background-color: #ccc;
}

.flex-item:nth-child(3) {
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  height: auto;
  min-height: 40px;
  min-width: 40px;
  transform: translateY(-50%);
  flex: 1;
  order: 1;
  background-color: red;
}

.sample {
  font-size: 20px;
}

/*pages 가이드*/
.pages a {
  display: block;
  margin-bottom: 15px;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pages a span {
  position: absolute;
  left: 195px;
  top: 0px;
  border: 1px solid #666;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
  width: 400px;
  padding-left: 10px;
}

.pages a em {
  color: #d6046d;
  font-weight: bold;
}

.pages a:hover span,
.pages a:focus span {
  background-color: #eee;
}

.pages a:hover, .pages a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .pages a span {
    width: auto;
    position: relative;
    left: 0;
    display: block;
  }
}

/*	footer
==========*/
.footer {
  color: #666;
  background-color: #f2f3f5;
  position: relative;
  border-top: 1px solid #e5e5e5;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  /*background-image:url("../../commons/images/global/footer-bg.png");background-position:0 45px;background-repeat:repeat-x;*/
}

.footer a:hover,
.footer a:focus {
  text-decoration: underline;
}

.footer__in {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  min-height: 180px;
}

.footer__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .footer__in {
  display: inline-block;
}

.foot_img {
  position: absolute;
  top: 35px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -212px;
  background-image: url("../images/sprite.png");
  width: 246px;
  min-width: 246px;
  height: 52px;
  text-indent: -9999px;
  font-size: 0;
}

.copy_box {
  position: absolute;
  top: 30px;
  left: 280px;
}

.copy_box ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .copy_box ul {
  display: inline-block;
}

.copy_box li {
  float: left;
  position: relative;
  padding-left: 30px;
}

.copy_box li a {
  vertical-align: top;
}

.copy_box li:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -748px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.copy_box li:first-child:before {
  display: none;
}

.copy_box li:first-child {
  background-image: none;
  padding-left: 0;
}

.copy_box li:first-child a {
  background-image: none;
}

.copy_box li.persnal-rule a {
  color: #d6046d;
}

.copy_box li.sns-li {
  background-image: none;
}

.copy_box a,
.copy_box2 a {
  font-size: 14px;
  font-size: 1.4rem;
  color: #666;
}

.copy_box a:hover,
.copy_box2 a:hover {
  text-decoration: underline;
}

.copy_box2 {
  position: absolute;
  top: 60px;
  left: 280px;
  width: auto;
  font-size: 12px;
}

.copy_box2 .item1 {
  margin-bottom: 5px;
  font-style: normal;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #666;
}

.copy_box2 .item1 ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .copy_box2 .item1 ul {
  display: inline-block;
}

.copy_box2 .item1 li {
  float: none;
}

.copy_box2 .item1 li strong {
  color: #d6046d;
}

.copy_box2 .item1 li:first-child {
  background-image: none;
}

.copy_box2 .item2 {
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 4px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

.copy_box3 {
  position: absolute;
  right: 0;
  top: 90px;
}

.copy_box4 {
  position: absolute;
  right: 0;
  top: 135px;
}

.kwa_logo {
  position: absolute;
  right: 0;
  /* bottom: 0; */
  top: 10px;
}

.kwa_logo img {
  max-width: 100px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .kwa_logo {
    right: 0;
    bottom: 0;
    top: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .kwa_logo {
    left: 0;
    right: auto;
  }
  .kwa_logo img {
    max-width: 90px;
  }
}

/*통계*/
.total-count {
  background-color: #399e90;
  padding: 10px 30px 10px 10px;
  border-radius: 30px;
}

.total-count:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .total-count {
  display: inline-block;
}

.total-count__dt {
  display: inline-block;
  color: #fff;
  margin-right: 5px;
  padding-left: 20px;
}

.total-count__dt:before {
  content: '';
  padding-right: 10px;
  padding-left: 14px;
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -851px -60px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.total-count__dt:first-child:before {
  display: none;
}

.total-count__dd {
  display: inline-block;
  color: #fff;
}

/*푸더 셀렉트*/
.footer-select {
  border: 1px solid #e0dfe0;
  background-color: #fff;
}

.footer-select__item {
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 0px solid #717171;
}

.footer-select__item:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -387px -249px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 8px;
}

.footer-select__item select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 190px;
  vertical-align: top;
  color: #000;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.footer-select__item select::-ms-expand {
  display: none;
}

.lte-ie9 .footer-select__item select {
  width: 125%;
  vertical-align: top;
}

.footer-select__item select option {
  color: #000;
  font-family: "Nanum Gothic";
}

.footer-select__item select:focus {
  border: 1px solid gold;
  border-radius: 4px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .footer-select__item select {
    min-width: 160px;
  }
}

.sel_go_btn {
  display: inline-block;
  height: 38px;
  width: 43px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background-color: #272727;
  border-left: 1px solid #717171;
}

* + html .sel_go_btn {
  display: inline;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  /*footer*/
  .footer {
    height: auto;
    min-height: 100px;
    padding: 10px;
    background-image: none;
    position: relative;
    z-index: 3;
    padding-top: 24px;
  }
  .footer__in {
    width: 100%;
  }
  .foot_img {
    display: none;
  }
  .copy_box {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 5px;
    background-color: #eee;
    padding: 5px;
  }
  .copy_box2 {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 10px;
  }
  .copy_box li {
    background-image: none;
    padding: 4px 10px;
  }
  .copy_box li::before {
    display: none;
  }
  .select_bottom {
    position: relative;
    right: 0;
    top: 0;
  }
  .select_bottom p {
    float: left;
  }
  .copy_box2 .item1 li {
    padding-left: 0;
    background-image: none;
    float: none;
  }
  .copy_box3 {
    position: relative;
    top: 0;
    margin-bottom: 5px;
  }
  .copy_box4 {
    position: relative;
    top: 0;
  }
  .total-count {
    padding: 0;
    border-radius: 0;
    padding: 5px;
  }
  .total-count__dt:first-child {
    padding-left: 0;
  }
  .footer-select {
    display: inline-block;
  }
  .sel_go_btn {
    border-left: 0px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .copy_box2 .item1 li strong {
    display: block;
    margin-top: 5px;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .copy_box li {
    padding: 0;
    float: left;
    width: 50%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .copy_box2 .item1 li {
    padding-left: 0 !important;
    background-image: none;
    width: 100%;
  }
  .copy_box3 {
    text-align: right;
  }
  .copy_box4 {
    text-align: right;
  }
}
